import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import theme from '../../theme';

const LogisticOperationHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            fontWeight: '600',
            color: `${theme.palette.tableTitle.main}`,
          }}
        >
          ID
        </TableCell>
        <TableCell
          sx={{
            fontWeight: '600',
            color: `${theme.palette.tableTitle.main}`,
          }}
        >
          Direccion
        </TableCell>
        <TableCell
          sx={{
            fontWeight: '600',
            color: `${theme.palette.tableTitle.main}`,
          }}
        >
          Entrada
        </TableCell>
        <TableCell
          sx={{
            fontWeight: '600',
            color: `${theme.palette.tableTitle.main}`,
          }}
        >
          Salida
        </TableCell>
        <TableCell
          sx={{
            fontWeight: '600',
            color: `${theme.palette.tableTitle.main}`,
          }}
        >
          Delivery asignado
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default LogisticOperationHead;
