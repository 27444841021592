import { useJsApiLoader } from '@react-google-maps/api';
import React, { useState } from 'react';

const useConfig = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCIGbCDAVTjyQaBuRTJurlYGc0mwr9pRpI',
  });

  const [map, setMap] = React.useState(null);

  const [center, setCenter] = useState({
    lat: -34.5912,
    lng: -58.3762,
  });

  const onLoad = React.useCallback(function callback(map) {
    //const bounds = new window.google.maps.LatLngBounds(center);
    //map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return [isLoaded, map, setMap, center, setCenter, onLoad, onUnmount];
};

export default useConfig;
