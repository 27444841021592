import React from 'react';
import { BtnWidth } from './buttonStyles';

const ButtonWidth = ({ title, onClick, disabled }) => {
  return (
    <BtnWidth
      disabled={disabled}
      onClick={onClick}
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
    >
      {title}
    </BtnWidth>
  );
};

export default ButtonWidth;
