import { Autocomplete, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';

const ClientAutocomplete = ({ filters, setFilters, clients }) => {
  const [value, setValue] = useState(filters.clientName);
  const [inputValue, setInputValue] = React.useState('');

  const options = clients.map((c) => c.name);

  useEffect(() => {
    setValue(filters.clientName);
  }, [filters.clientName]);

  return (
    <Autocomplete
      options={options}
      onChange={(event, value) => {
        setFilters({ ...filters, clientName: value });
        setValue(value);
      }}
      value={value}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      sx={{ width: 200 }}
      renderInput={(params) => (
        <TextField {...params} label="Clientes" variant="standard" />
      )}
    />
  );
};

export default ClientAutocomplete;
