import { createTheme } from '@mui/material';
import RobotoRegular from './fonts/Roboto-Regular.ttf';
import RobotoMedium from './fonts/Roboto-Thin.ttf';

const theme = createTheme({
  palette: {
    primary: {
      main: '#F2F2F2',
    },
    secondary: {
      main: '#F5F2F5',
    },
    title: {
      main: '#3f5071',
    },
    tableTitle: {
      main: '#A4A6B7',
    },
    tableText: {
      main: '#7C8098',
    },
    icons: {
      main: '#7C8098',
    },
    btnOutline: {
      main: '#384764',
    },
    boxTitle: {
      main: '#8364EB',
    },
    inputText: {
      main: '#80849B',
    },
    btn: {
      main: '#cb2d3e',
    },
    inputOutline: {
      main: '#D3D6DA',
    },
  },
  typography: {
    fontWeightRegular: RobotoRegular,
    fontWeightMedium: RobotoMedium,
  },
});

export default theme;
