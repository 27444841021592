import { TableCell, TableHead, TableRow } from '@mui/material';
import theme from '../../theme';
import React from 'react';

const AnomaliesHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            fontWeight: '600',
            color: `${theme.palette.tableTitle.main}`,
          }}
        >
          CLIENTE
        </TableCell>
        <TableCell
          sx={{
            fontWeight: '600',
            color: `${theme.palette.tableTitle.main}`,
          }}
          align="left"
        >
          DIVISA
        </TableCell>
        <TableCell
          sx={{
            fontWeight: '600',
            color: `${theme.palette.tableTitle.main}`,
          }}
          align="left"
        >
          MONTO
        </TableCell>
        <TableCell
          sx={{
            fontWeight: '600',
            color: `${theme.palette.tableTitle.main}`,
          }}
          align="left"
        >
          ENTRADA O SALIDA
        </TableCell>
        <TableCell
          sx={{
            fontWeight: '600',
            color: `${theme.palette.tableTitle.main}`,
          }}
          align="left"
        >
          ID OPERACIÓN
        </TableCell>
        <TableCell
          sx={{
            fontWeight: '600',
            color: `${theme.palette.tableTitle.main}`,
          }}
          align="left"
        ></TableCell>
        <TableCell
          sx={{
            fontWeight: '600',
            color: `${theme.palette.tableTitle.main}`,
          }}
          align="left"
        ></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default AnomaliesHead;
