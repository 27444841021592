import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Icon from '../../img/notification.png';
import close from '../../img/close.png';
import { BtnCancelDialog, BtnSaveDialog, Title } from './alertDialogStyles.js';

export default function AlertDialog({
  handlerAction,
  page,
  objectId,
  open,
  context,
  onClose,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
        sx={{}}
      >
        <div style={{ margin: '4rem 10rem' }}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 30,
              top: 25,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={close} alt="" />
          </IconButton>

          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={Icon} alt="" style={{ width: '47px', height: '56px' }} />
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Title>
              {context === 'anomalies'
                ? 'Desactivar anomalía'
                : context === 'clients'
                ? 'Eliminar cliente'
                : context === 'operation'
                ? 'Eliminar operacion'
                : context === 'panic'
                ? 'Activar botón de pánico'
                : context === 'disabled'
                ? 'Desactivar botón de pánico'
                : context === 'stock'
                ? 'Desactivar divisa'
                : 'Eliminar usuario'}
            </Title>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <body style={{ fontSize: '16px', color: 'rgb(56,71,100,0.55)' }}>
              {context === 'anomalies'
                ? ' ¿Está seguro que desea desactivar esta anomalía?'
                : context === 'clients'
                ? '¿Está seguro que desea eliminar este cliente?'
                : context === 'operation'
                ? '¿Está seguro que desea eliminar esta operación?'
                : context === 'panic'
                ? '¿Está seguro que desea activar el botón de pánico?'
                : context === 'disabled'
                ? '¿Está seguro que desea desactivar el botón de pánico?'
                : context === 'stock'
                ? '¿Está seguro que desea desactivar esta divisa?'
                : '¿Está seguro que desea eliminar este usuario?'}
            </body>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '3.7rem',
              marginBottom: '2.2rem',
            }}
          >
            <BtnCancelDialog onClick={() => onClose()}>
              CANCELAR
            </BtnCancelDialog>
            <BtnSaveDialog onClick={() => handlerAction(objectId)}>
              {context === 'anomalies'
                ? ' DESACTIVAR'
                : context === 'clients'
                ? 'ELIMINAR'
                : context === 'panic'
                ? 'ACTIVAR'
                : context === 'disabled'
                ? 'DESACTIVAR'
                : context === 'stock'
                ? 'DESACTIVAR'
                : 'ELIMINAR'}
            </BtnSaveDialog>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
