import { TableCell, TableHead, TableRow } from '@mui/material';
import theme from '../../theme';
import React from 'react';

const StockHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            fontWeight: '500',
            color: `${theme.palette.tableTitle.main}`,
            paddingLeft: '25px',
          }}
        >
          DIVISA
        </TableCell>
        <TableCell
          sx={{
            fontWeight: '500',
            color: `${theme.palette.tableTitle.main}`,
          }}
          align="left"
        >
          TIPO DE CAMBIO (USD)
        </TableCell>
        <TableCell
          sx={{
            fontWeight: '500',
            color: `${theme.palette.tableTitle.main}`,
          }}
          align="left"
        >
          BALANCE INICIAL
        </TableCell>
        <TableCell
          sx={{
            fontWeight: '500',
            color: `${theme.palette.tableTitle.main}`,
          }}
          align="left"
        >
          BALANCE ACTUAL
        </TableCell>
        <TableCell
          sx={{
            fontWeight: '500',
            color: `${theme.palette.tableTitle.main}`,
          }}
          align="left"
        >
          ANOMALÍA
        </TableCell>
        <TableCell
          sx={{
            fontWeight: '500',
            color: `${theme.palette.tableTitle.main}`,
          }}
          align="left"
        >
          BALANCE TOTAL
        </TableCell>
        <TableCell
          sx={{
            fontWeight: '500',
            color: `${theme.palette.tableTitle.main}`,
          }}
          align="left"
        ></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default StockHead;
