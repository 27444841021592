import { MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useStock from '../../Stock/useStock';

const CurrenciesAutocomplete = ({ context, filters, setFilters, stock }) => {
  const [inputValue, setInputValue] = useState(filters?.inputCurrency);
  const [outputValue, setOutputValue] = useState(filters.outputCurrency);

  useEffect(() => {
    setInputValue(filters.inputCurrency);
    setOutputValue(filters.outputCurrency);
  }, [filters.inputCurrency, filters.outputCurrency]);

  return context === 'input' ? (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Select
        onChange={(e) => {
          setFilters({ ...filters, inputCurrency: e.target.value });
          setInputValue(e.target.value);
        }}
        variant="standard"
        value={inputValue}
      >
        <MenuItem value="Todas">Todas</MenuItem>;
        {stock?.map((s) => {
          return (
            <MenuItem value={s._id} key={s._id}>
              {s.currency}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Select
        onChange={(e) => {
          setFilters({ ...filters, outputCurrency: e.target.value });
          setOutputValue(e.target.value);
        }}
        variant="standard"
        value={outputValue}
      >
        <MenuItem value="Todas">Todas</MenuItem>
        {stock?.map((s) => {
          return (
            <MenuItem value={s._id} key={s._id}>
              {s.currency}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default CurrenciesAutocomplete;
