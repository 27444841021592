import React from 'react';
import fetchFromApi from '../../utils/fetchFromApi';

const useHistory = () => {
  const [history, setHistory] = React.useState([]);
  const [opening, setOpening] = React.useState([]);
  const [closing, setClosing] = React.useState([]);

  const getHistoryByDate = async (date) => {
    try {
      const response = await fetchFromApi('GET', `history/?date=${date}`);

      if (response) {
        setHistory(response[0].stock);
        console.log(history);

        const filterOpening = response?.filter((h) => h.flag === 'opening');
        const filterClosing = response?.filter((h) => h.flag === 'closing');

        setOpening(filterOpening);
        setClosing(filterClosing);
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    getHistoryByDate,
    history,
    setHistory,
    opening,
    closing,
  };
};

export default useHistory;
