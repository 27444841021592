import React from 'react';

const Icon = ({ path }) => {
  return (
    <img
      style={{
        width: '20px',
        marginRight: '5px',
      }}
      src={path}
    />
  );
};

export default Icon;
