import {
  Autocomplete,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React from 'react';
import theme from '../../../theme';
import { operationTypes } from '../../../utils/constants';

const DeliveryPositionForm = ({
  handlePositionChange,
  usersDelivery,
  selectedUser,
  position,
  setPosition,
  stock,
  opType,
  handleInputChange,
  operation,
  errors,
}) => {
  return (
    <>
      {/* TIPO DE OPERACION */}
      <FormControl variant="standard" fullWidth>
        <InputLabel
          sx={{ color: `${theme.palette.inputText.main}` }}
          id="input"
        >
          Tipo de Operacion
        </InputLabel>
        <Select
          labelId="input"
          name="operation_type"
          onChange={(event) => handleInputChange(event)}
          value={operation.operation_type}
        >
          <ListSubheader>OPERACIONES FINANCIERAS</ListSubheader>
          {operationTypes().map((o, i) => {
            if (i > 7) return null;
            return <MenuItem value={o}>{o}</MenuItem>;
          })}
          <ListSubheader>OPERACIONES INSTITUCIONALES</ListSubheader>
          {operationTypes().map((o, i) => {
            if (i < 8) return null;
            return <MenuItem value={o}>{o}</MenuItem>;
          })}
        </Select>
      </FormControl>
      {opType.startsWith('RETIRO') ? (
        <FormControl variant="standard" error={errors.currency}>
          <InputLabel
            sx={{ color: `${theme.palette.inputText.main}` }}
            id="output"
          >
            Divisa de Salida
          </InputLabel>
          <Select
            labelId="output"
            onChange={(event) => handlePositionChange(event)}
            name="currency"
          >
            {stock?.map((s) => {
              return (
                <MenuItem value={s._id} key={s._id}>
                  {s.currency}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <FormControl variant="standard" error={errors.currency}>
          <InputLabel
            sx={{ color: `${theme.palette.inputText.main}` }}
            id="input"
          >
            Divisa de Entrada
          </InputLabel>
          <Select
            labelId="input"
            onChange={(event) => handlePositionChange(event)}
            name="currency"
          >
            {stock?.map((s) => {
              return (
                <MenuItem value={s._id} key={s._id}>
                  {s.currency}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      <TextField
        error={errors.balance}
        name="balance"
        label="Balance"
        variant="standard"
        type="number"
        onChange={(event) => handlePositionChange(event)}
      />
      <Autocomplete
        options={usersDelivery}
        getOptionLabel={(usersDelivery) => usersDelivery.name}
        value={selectedUser}
        sx={{ width: '100%' }}
        renderInput={(params) => (
          <TextField
            error={errors.userId}
            {...params}
            label="Deliverys"
            variant="standard"
            fullWidth={true}
            InputLabelProps={{
              style: {
                color: `${theme.palette.inputText.main}`,
              },
            }}
            sx={{
              input: {
                color: `${theme.palette.inputText.main}`,
              },
              '& .MuiInputLabel-root': { color: '#D3D6DA' },
            }}
          />
        )}
        onChange={(event, value) => {
          setPosition({
            ...position,
            userId: value._id,
          });
        }}
      />
    </>
  );
};

export default DeliveryPositionForm;
