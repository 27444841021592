import React, { useState } from 'react';
import { TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { UserTableCellBody } from '../../containers/Team/userStyles';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '..//Dialog/AlertDialog';

const UserIndividual = ({ deleteUser, user }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const navigate = useNavigate();
  const handlerDeleteUser = (id) => {
    deleteUser(id);
    setOpenAlert(false);
  };
  const date = user.createdAt.split('T')[0].split('-');
  return (
    <TableRow key={user._id}>
      <UserTableCellBody align="left" width={'300px'}>
        {user.name}
      </UserTableCellBody>

      <UserTableCellBody align="left" width={'300px'}>
        {user.email}
      </UserTableCellBody>

      <UserTableCellBody align="left" width={'300px'}>
        {user.cellphone}
      </UserTableCellBody>

      <UserTableCellBody align="left" width={'300px'}>
        {user.role}
      </UserTableCellBody>
      <UserTableCellBody align="left" width={'300px'}>
        {`${date[2]}/${date[1]}/${date[0]}`}
      </UserTableCellBody>
      <UserTableCellBody align="left" width={'300px'}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '34px',
          }}
        >
          <EditIcon
            onClick={() => navigate(`/profile/${user._id}`)}
            style={{ cursor: 'pointer' }}
          >
            Editar
          </EditIcon>

          <AlertDialog
            handlerAction={handlerDeleteUser}
            page={1}
            objectId={user._id}
            open={openAlert}
            onClose={() => setOpenAlert(false)}
            context={'users'}
          />
          <DeleteIcon
            onClick={() => setOpenAlert(true)}
            style={{ cursor: 'pointer' }}
          >
            Eliminar
          </DeleteIcon>
        </div>
      </UserTableCellBody>
    </TableRow>
  );
};

export default UserIndividual;
