import styled from 'styled-components';
import theme from '../../theme';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { TableCell } from '@material-ui/core';
import { Table, TableContainer } from '@mui/material';

export const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${theme.palette.title.main};
  margin: 0;
  padding: 0 30px;
  margin-bottom: 20px;
  position: relative;
  bottom: 20px;
  margin-left: 1rem;
`;

export const Btn = styled.button`
  background: #d43436; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  border: none;
  border-radius: 10px;
  padding: 15px 18px;
  margin-right: 10px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif !important;
  box-shadow: 0px 3px 3px #b3abab;
  transition: 0.3s;
  &:hover {
    box-shadow: none;
  }
  margin-left: 0.25rem;
  width: 9rem;
`;

export const ClientTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  border-radius: 10px;

  margin: 0 auto;
  width: 96.5%;
  margin-top: 20px;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
`;

export const ClientDetailsTableContainer = styled(TableContainer)`
  margin: 0 auto;
  width: 100% !important;
`;

export const ClientTableCell = styled(TableCell)`
  color: ${theme.palette.tableTitle.main};
  padding-left: 25px !important;
  font-weight: 500 !important;
`;

export const TotalClientTableCell = styled.div`
  color: white;
  font-weight: 500 !important;
  font-size: 20px;
  border-bottom: 1px solid;
  border-color: #a2a2a2;

  padding-bottom: 11px;
  width: 82.97%;
`;

export const ClientTableCellBody = styled(TableCell)`
  color: ${theme.palette.tableText.main};
  padding-left: 25px !important;
  font-weight: 500 !important;
  cursor: pointer;
`;

export const ClientTableRow = styled(TableCell)`
  width: 100% !important;
  display: grid !important;
  grid-template-columns: 16.66% 16.66% 16.66% 16.66% 16.66% 16.66%;
  border: 0 !important;
`;

export const ClientTable = styled(Table)`
  width: 100% !important;
`;

export const LeftButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 30px;
`;

export const RightButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 30px;
`;

export const ContainerClient = styled.div`
  width: 300px;
  margin: 0 auto;
`;

export const ContainerClientInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  margin: 10px 0px;
`;

export const ContainerAddClient = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const ContainerClientList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 150px;
`;

export const ClientsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: 260px;
  margin-top: 100px;
`;

export const BoxMui = styled(Box)`
  background-color: #fff;
  border-radius: 10px;
  padding: 70px;
  width: 100%;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
`;

export const BoxTitle = styled.div`
  background: #cb2d3e; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #ef473a,
    #cb2d3e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #ef473a,
    #cb2d3e
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 96.5%;
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  position: relative;
  top: 50px;
`;

export const BreadcumsContainer = styled.div`
  margin-right: 77%;
  margin-bottom: 40px;
`;

export const BoxTitleAddClient = styled.div`
  background: #d43436; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 77%;
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  position: relative;
  top: 50px;
`;

export const TextFieldMui = styled(TextField)`
  color: red;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  top: 90px;
  right: 700px;
`;

export const AddClientContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 48px 0px 0px 200px;
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  gap: 35px;
  align-items: center;
`;

export const SubFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 25px;
  height: 180px;
`;

// Edit clients

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-content: center;
`;

export const StockContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: 260px;
  margin-top: 100px;
`;

export const StockTitle = styled.h1`
  font-size: 1rem;
  font-weight: 600;
  color: ${theme.palette.title.main};
  margin: 0;
  padding: 0 30px;
  margin-bottom: 20px;
  position: relative;
  bottom: 20px;
`;

export const StockTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0px;
  padding-top: 20px;
  border-radius: 10px;
  background-color: #fff;
  margin: 0 auto;
  width: 96.5%;
  margin-top: 20px;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
`;

export const TotalBalance = styled.h2`
  font-size: 1rem;
  color: white;
`;

export const TotalContainer = styled.div`
  background: #d63638; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f25456,
    #d63638
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f25456,
    #d63638
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 300px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
  margin-top: 20px;
`;

export const InputDate = styled.input`
  border: 1px solid ${theme.palette.title.main};
  color: ${theme.palette.title.main};
  padding: 0px 20px;
  border-radius: 10px;
  background: none;
`;

export const Select = styled.select`
  border: 1.5px solid ${theme.palette.title.main};
  color: ${theme.palette.title.main};
  border-radius: 10px;
  padding: 10px 20px;
  margin-left: 10px;
  background: none;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
`;

export const Option = styled.option`
  font-family: 'Roboto', sans-serif !important;
  background-color: white;
  color: #384764;
  font-weight: 500;

  &:hover {
    background-color: red;
  }
  &:active {
    background-color: red;
  }
  &:selected {
    background-color: red;
  }
`;

export const BoxMuiEdit = styled(Box)`
  background-color: #fff;
  border-radius: 10px;
  padding: 70px;
  width: 80%;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
`;

export const BoxTitleEdit = styled.div`
  background: #cb2d3e; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #ef473a,
    #cb2d3e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #ef473a,
    #cb2d3e
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 77%;
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  position: relative;
  top: 50px;
`;

export const BtnContainerEdit = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

export const AddStockContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 48px 0px 0px 200px;
`;

export const BreadcumsContainerClient = styled.div`
  position: relative;
  left: 20px;
  bottom: 35px;
`;

export const ContainerClientDetail = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 260px;
  margin-top: 100px;
  width: 95%;
`;

export const ContainerClientDetailInfo = styled.div`
  display: grid;
  flex-direction: row;
  width: 99%;
  grid-template-columns: 70% 30%;
  position: relative;
  left: 4%;
`;

export const ClientInfo = styled.div`
  display: flex;

  align-items: center;
`;
export const ClientCC = styled.div`
  justify-content: center;
`;

export const TotalInfo = styled.div`
  background-color: #7c8098;
  border-radius: 10px;
  padding: 16px 0;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  min-height: 137px;
  color: white;
  font-size: 10px;
  flex-direction: column;
`;

export const TotalInfoValuesContainer = styled.div`
  width: 82.96%;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-weight: 500;
  justify-content: start;
`;

export const TotalInfoValues = styled.div`
  margin: 15px;
`;
