/* eslint-disable*/
import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '../../components/Button/Button';
import {
  BoxMui,
  BoxTitle,
  TextFieldMui,
  BtnContainer,
  AddUserContainer,
  FormContainer,
  SubFormContainer,
} from './userStyles';
import { roles } from '../../utils/constants';
import useNotistack from '../Notistack/useNotistack';
import { useNavigate } from 'react-router-dom';
import themeMui from '../../theme';
import CancelButton from '../../components/Button/CancelButton';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';
import { BreadcumsContainer } from '../Stock/stockStyles';
import useUsers from './useUsers';
import { validateUserForm } from './usersValidation';

const AddUser = () => {
  const { AddNewUser, loadingButton } = useUsers();
  const theme = createTheme();
  const { showNotification } = useNotistack();
  const navigate = useNavigate();

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const [errors, setErrors] = React.useState([]);

  const [userInfo, setUserInfo] = React.useState({
    email: '',
    name: '',
    cellphone: '',
    password: '',
    userRole: '',
    repeatPassword: '',
  });

  const handleRedirect = () => {
    navigate('/equipo');
  };

  const handleShowErrors = (errors) => {
    errors[0]?.forEach((error) => {
      showNotification(error, 'error');
    });
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = validateUserForm(userInfo);
    if (response.valid) {
      AddNewUser(userInfo, setErrors);
    } else {
      setErrors(response);
    }
  };

  useEffect(() => {
    handleShowErrors(errors);
  }, [errors]);

  return (
    <>
      <ThemeProvider theme={themeMui}>
        <AddUserContainer>
          <BreadcumsContainer>
            <BreadcrumbsMui
              title="Nuevo usuario"
              prev="Equipo"
              path={'/equipo'}
            />
          </BreadcumsContainer>
          <BoxTitle>
            <h1>Nuevo usuario</h1>
          </BoxTitle>
          <BoxMui
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              width: '80%',
              height: '450px',
            }}
          >
            <FormContainer>
              <SubFormContainer>
                <TextFieldMui
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                    '& .MuiInputLabel-root': { color: '#D3D6DA' },
                    borderBottom: '1px solid #D3D6DA',
                  }}
                  InputProps={{ disableUnderline: true }}
                  autoComplete="given-name"
                  required
                  id="firstName"
                  label="Nombre"
                  autoFocus
                  name="name"
                  variant="standard"
                  onChange={handleChange}
                  error={errors[1]?.name}
                  value={userInfo.name}
                />
                <TextField
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                    '& .MuiInputLabel-root': { color: '#D3D6DA' },
                    borderBottom: '1px solid #D3D6DA',
                  }}
                  InputProps={{ disableUnderline: true }}
                  variant="standard"
                  required
                  fullWidth
                  id="phone"
                  label="Teléfono"
                  autoComplete="telefono"
                  name="cellphone"
                  onChange={handleChange}
                  error={errors[1]?.cellphone}
                  value={userInfo.cellphone}
                />

                <TextField
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                    '& .MuiInputLabel-root': { color: '#D3D6DA' },
                    borderBottom: '1px solid #D3D6DA',
                  }}
                  InputProps={{ disableUnderline: true }}
                  required
                  fullWidth
                  label="Contraseña"
                  variant="standard"
                  id="password"
                  autoComplete="new-password"
                  name="password"
                  onChange={handleChange}
                  error={errors[1]?.password}
                  value={userInfo.password}
                  type="password"
                />
              </SubFormContainer>

              <SubFormContainer>
                <TextField
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                    '& .MuiInputLabel-root': { color: '#D3D6DA' },
                    borderBottom: '1px solid #D3D6DA',
                  }}
                  InputProps={{ disableUnderline: true }}
                  required
                  fullWidth
                  id="email"
                  variant="standard"
                  label="Email"
                  autoComplete="email"
                  name="email"
                  onChange={handleChange}
                  error={errors[1]?.email}
                  value={userInfo.email}
                />

                <FormControl
                  sx={{
                    width: '100%',
                  }}
                >
                  <InputLabel id="rol-label">Rol</InputLabel>
                  <Select
                    sx={{
                      input: {
                        color: `${themeMui.palette.inputText.main}`,
                      },
                      width: '497px',
                    }}
                    SelectDisplayProps={{
                      style: { color: `${themeMui.palette.inputText.main}` },
                    }}
                    labelId="rol-label"
                    onChange={handleChange}
                    name="userRole"
                    error={errors[1]?.role}
                    variant="standard"
                  >
                    {roles.map((r) => (
                      <MenuItem
                        key={r.value}
                        value={r.value}
                        style={getStyles(r.name, r.name, theme)}
                      >
                        {r.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                    '& .MuiInputLabel-root': { color: '#D3D6DA' },
                    borderBottom: '1px solid #D3D6DA',
                  }}
                  InputProps={{ disableUnderline: true }}
                  required
                  fullWidth
                  id="repeatPassword"
                  variant="standard"
                  label="Repetir contraseña"
                  autoComplete="password"
                  name="repeatPassword"
                  onChange={handleChange}
                  error={errors[1]?.repeatPassword}
                  value={userInfo.repeatPassword}
                  type="password"
                />
              </SubFormContainer>
            </FormContainer>

            <BtnContainer>
              <CancelButton
                title="CANCELAR"
                onClick={() => navigate('/equipo')}
                disable={loadingButton}
              />
              <Button type="submit" title="GUARDAR" disable={loadingButton} />
            </BtnContainer>
          </BoxMui>
        </AddUserContainer>
      </ThemeProvider>
    </>
  );
};

export default AddUser;
