import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useNotistack from '../Notistack/useNotistack';
import useUsers from '../Team/useUsers';

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props) => {
  let location = useLocation();
  const { showNotification } = useNotistack();
  const navigate = useNavigate();
  const { getProfile, profile } = useUsers();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user) {
      getProfile(user.id);
      const decodedJwt = parseJwt(user.token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        showNotification('Sesión expirada', 'error');
        props.logOut();
        navigate('/login');
      }
    }
  }, [location, props]);

  useEffect(() => {
    if (profile.panic === true) {
      if (profile.role !== 'superadmin') {
        props.logOut();
      }
    }
  }, [location, props]);

  return;
};

export default AuthVerify;
