export default function operationFinacialOrinstitutional(operation_type) {
  return (
    operation_type === 'PAGO SERV/PROV' ||
    operation_type === 'PAGO SUELDOS' ||
    operation_type === 'LIQ.COMISIONES' ||
    operation_type === 'INGRESO CTA.CORRIENTE' ||
    operation_type === 'RETIRO POS.DELIVERY' ||
    operation_type === 'INGRESO POS.DELIVERY' ||
    operation_type === 'EGRESO CTA.CORRIENTE'
  );
}
