/* eslint-disable no-unused-vars*/
import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import io from 'socket.io-client';
import env from '../../../env';

import useConfig from './useConfig';
import icon from './map_icon.png';
import { MapContainer, LogisticSubTitle } from '../logisticStyles';
import ActiveDeliveryTable from '../../../components/Logistic/ActiveDeliveryTable';

const containerStyle = {
  width: '100%',
  height: '370px',
  borderRadius: '16px',
  margin: 'auto',
};

const socket = io(env.SOCKET_URL || 'http://localhost:5000');

function Map() {
  const [onlineDeliverys, setOnlineDeliverys] = useState([]);
  const [zoom, setZoom] = useState(11);
  const [selected, setSelected] = useState(false);

  const [isLoaded, map, setMap, center, setCenter, onLoad, onUnmount] =
    useConfig();

  useEffect(() => {
    socket.on('location', (location) => {
      const pos = JSON.parse(location);
      console.log(pos);
      setOnlineDeliverys(pos);
    });

    return () => {
      socket.off('location');
    };
  }, []);

  const handleCenter = (o) => {
    setCenter({
      lat: o.location.coords.latitude,
      lng: o.location.coords.longitude,
    });
    setZoom(15);
  };

  const handleSelect = (o) => {
    o.selected = o.selected ? false : true;
    setSelected(o.selected ? false : true);
  };

  return isLoaded ? (
    <MapContainer>
      <LogisticSubTitle>Mapa</LogisticSubTitle>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {onlineDeliverys?.map((o) => (
          <Marker
            onClick={() => handleSelect(o)}
            key={o.user.id}
            position={{
              lat: o?.location?.coords?.latitude,
              lng: o?.location?.coords?.longitude,
            }}
            icon={icon}
          />
        ))}
      </GoogleMap>
      {onlineDeliverys.length === 0 ? (
        <h2
          style={{
            textAlign: 'center',
            margin: '60px auto',
            padding: '10px 0px',
            backgroundColor: 'rgb(212, 52, 54)',
            color: 'white',
            width: '50%',
            borderRadius: '10px',
          }}
        >
          No hay deliverys en linea
        </h2>
      ) : (
        <ActiveDeliveryTable
          onlineDeliverys={onlineDeliverys}
          handleCenter={handleCenter}
        />
      )}
    </MapContainer>
  ) : (
    <></>
  );
}

export default React.memo(Map);
