import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';

import LogisticOperationHead from './LogisticOperationHead';
import { LogisticOperation } from './LogisticOperation';

const LogisticOperationTable = ({ operations, deliverys }) => {
  return (
    <TableContainer
      sx={{
        padding: '10px 20px',
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '16px',
      }}
    >
      <Table
        sx={{
          width: '100%',
        }}
      >
        <LogisticOperationHead />
        <TableBody>
          {operations?.map((o) => (
            <LogisticOperation
              key={o._id}
              id={o._id}
              index={o.index}
              address={o.address}
              input={`${o.input_currency?.currency} ${o.input_amount}`}
              output={`${o.output_currency?.currency} ${o.output_amount}`}
              assignedDelivery={o.assignedDelivery}
              deliverys={deliverys}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LogisticOperationTable;
