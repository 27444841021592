import {
  Autocomplete,
  createFilterOptions,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React from 'react';
import theme from '../../../theme';

import ClearIcon from '@mui/icons-material/Clear';
import { operationTypes } from '../../../utils/constants';

const filter = createFilterOptions();

const PayServProvForm = ({
  handleInputChange,
  operation,
  stock,
  serviceProviders,
  createNewServiceProvider,
  getServiceProivders,
  setOperation,
  deleteServiceProvider,
  errors,
}) => {
  const [value, setValue] = React.useState(null);

  const handleAddServiceProvider = async (value) => {
    await createNewServiceProvider({ name: value });
    getServiceProivders();
    setOperation({ ...operation, service_provider: value });
  };

  const handleDeleteServiceProvider = async (name) => {
    setValue(null);
    await deleteServiceProvider(name);
    getServiceProivders();
  };

  return (
    <>
      {/* TIPO DE OPERACION */}
      <FormControl variant="standard" fullWidth>
        <InputLabel
          sx={{ color: `${theme.palette.inputText.main}` }}
          id="input"
        >
          Tipo de Operacion
        </InputLabel>
        <Select
          labelId="input"
          name="operation_type"
          onChange={(event) => handleInputChange(event)}
          value={operation.operation_type}
        >
          <ListSubheader>OPERACIONES FINANCIERAS</ListSubheader>
          {operationTypes().map((o, i) => {
            if (i > 7) return null;
            return <MenuItem value={o}>{o}</MenuItem>;
          })}
          <ListSubheader>OPERACIONES INSTITUCIONALES</ListSubheader>
          {operationTypes().map((o, i) => {
            if (i < 8) return null;
            return <MenuItem value={o}>{o}</MenuItem>;
          })}
        </Select>
      </FormControl>
      {/* SERVICIO/PROVEEDOR */}
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setValue({
              name: newValue,
            });
            setOperation({ ...operation, service_provider: newValue });
          } else if (newValue && newValue.inputValue) {
            setValue({
              name: newValue.inputValue,
            });
            handleAddServiceProvider(newValue.inputValue);
          } else {
            setValue(newValue);
            setOperation({ ...operation, service_provider: newValue.name });
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;

          const isExisting = options.some(
            (option) => inputValue === option.name,
          );
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              name: `Agregar "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={serviceProviders}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }

          if (option.inputValue) {
            return option.inputValue;
          }

          return option.name;
        }}
        renderOption={(props, option) => (
          <li {...props}>
            {option.name}
            {option.name.startsWith('Agregar') ? null : (
              <ClearIcon
                onClick={() => handleDeleteServiceProvider(option.name)}
                sx={{
                  marginLeft: '10px',
                  color: `${theme.palette.icons.main}`,
                }}
              />
            )}
          </li>
        )}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label="Servicio / Proveedor"
            variant="standard"
            error={errors.serviceProvider}
          />
        )}
      />

      {/* {DIVISA DE SALIDA} */}
      <FormControl variant="standard" error={errors.output_currency}>
        <InputLabel
          sx={{ color: `${theme.palette.inputText.main}` }}
          id="output"
        >
          Divisa de Salida
        </InputLabel>
        <Select
          labelId="output"
          onChange={(event) => handleInputChange(event)}
          name="output_currency"
        >
          {stock?.map((s) => {
            return (
              <MenuItem value={s._id} key={s._id}>
                {s.currency}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      {/* MONTO DE SALIDA */}
      <TextField
        error={errors.output_amount}
        label="Monto de salida"
        variant="standard"
        type="number"
        name="output_amount"
        onChange={(event) => handleInputChange(event)}
        InputLabelProps={{
          style: { color: `${theme.palette.inputText.main}` },
        }}
        sx={{
          input: {
            color: `${theme.palette.inputText.main}`,
          },
          '& .MuiInputLabel-root': { color: '#D3D6DA' },
          borderBottom: '1px solid #D3D6DA',
        }}
        InputProps={{ disableUnderline: !errors.output_amount }}
      />

      {/* COMENTARIOS */}
      <TextField
        InputLabelProps={{
          style: { color: `${theme.palette.inputText.main}` },
        }}
        sx={{
          input: {
            color: `${theme.palette.inputText.main}`,
          },
          '& .MuiInputLabel-root': { color: '#D3D6DA' },
          borderBottom: '1px solid #D3D6DA',
        }}
        InputProps={{ disableUnderline: true }}
        variant="standard"
        type="text"
        name="comments"
        label="Comentarios"
        onChange={(event) => handleInputChange(event)}
        fullWidth={true}
      />
    </>
  );
};

export default PayServProvForm;
