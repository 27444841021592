import React, { useState } from 'react';
import {
  Checkbox,
  Collapse,
  IconButton,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import theme from '../../theme';
import CopyToClipboardButton from '../Button/CopyToClipboard';
import CollapseTable from './CollapseTable';
import { operationStatus } from '../../utils/constants';
import useOperations from '../../containers/Operations/useOperations';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../Dialog/AlertDialog';
import formatNumber from '../../utils/formatNumber';
import formatDate from '../../utils/formatDate';
import operationFinacialOrinstitutional from '../../utils/operationFinancialOrInstitutional';

const Operation = ({
  operation,
  deleteOperation,
  handleSelectOperation,
  selected,
}) => {
  const [openAlert, setOpenAlert] = useState(false);

  const {
    _id,
    input_currency,
    output_currency,
    input_amount,
    output_amount,
    operation_type,
    liquidator_type,
    commission,
    anomalie,
    comments,
    status,
    client,
    assignedDelivery,
    createdAt,
    serviceProvider,
    paidTeamMember,
    broker,
    deliveryPositionOperation,
    index,
    exchange_rate,
    dateCreated,
    liquidator,
  } = operation;

  const { changeOperationStatus } = useOperations();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [statusValue, setStatusValue] = useState(status);
  const [liquidatorValue, setLiquidatorValue] = useState(
    liquidator?.name ? liquidator : undefined,
  );

  let inputAnomalie = anomalie.filter((a) => a.type === 'input');
  let outputAnomalie = anomalie.filter((a) => a.type === 'output');

  const userLocal = JSON.parse(localStorage.getItem('user'));

  const handleChangeStatus = async (e) => {
    setStatusValue(e.target.value);
    const user =
      e.target.value === 'Liquidado' || e.target.value === 'Finalizado'
        ? {
            name: userLocal.name,
            email: userLocal.email,
          }
        : undefined;
    await changeOperationStatus(
      _id,
      { statusValue: e.target.value },
      undefined,
      user,
    );
    setLiquidatorValue(user);
  };

  const isOptionDisabled = (userRole, statusValue, option) => {
    if (userRole === 'tesoreria') {
      return [
        'Confirmado Cliente',
        'Confirmado LYM',
        'Pendiente',
        'Confirmado Delivery',
      ].includes(option);
    }
    if (userRole === 'liquidador_caja') {
      return ['Confirmado Delivery', 'Finalizado'].includes(option);
    }
    return (
      ((statusValue === 'Confirmado LYM' ||
        statusValue === 'Confirmado Cliente') &&
        ['Confirmado Cliente', 'Confirmado LYM'].includes(option)) ||
      option === 'Confirmado Delivery'
    );
  };

  const getOptions = (userRole, statusValue) =>
    operationStatus.map((option) => ({
      value: option,
      label: option.toUpperCase(),
      disabled: isOptionDisabled(userRole, statusValue, option),
    }));

  return (
    <TableBody>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ paddingLeft: '12px', paddingRight: '5px' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04);', padding: '2px' }}
          >
            {open ? (
              <KeyboardArrowUpIcon sx={{ color: '#384764' }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: '#384764' }} />
            )}
          </IconButton>
        </TableCell>
        <TableCell sx={{ paddingLeft: '0px', paddingRight: '4px' }}>
          <Checkbox
            sx={{
              color: 'gray',
              '&.Mui-checked': {
                color: '#384764',
              },
              ':hover': { cursor: 'pointer' },
            }}
            onChange={handleSelectOperation}
            value={JSON.stringify({
              id: operation._id,
              type: operation.operation_type,
            })}
            checked={selected?.some((o) => o.id === operation._id)}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: `${theme.palette.tableText.main}`,
            fontWeight: '500',
            fontSize: '14px',
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          {formatDate(dateCreated)}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: `${theme.palette.tableText.main}`,
            fontWeight: '500',
            fontSize: '14px',
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          <CopyToClipboardButton id={index} />
        </TableCell>
        <TableCell
          sx={{ paddingLeft: '8px', paddingRight: '8px', fontSize: '14px' }}
        >
          {input_currency ? input_currency.currency.toUpperCase() : '---'}{' '}
          {anomalie?.map((a) => {
            if (a.type === 'input') {
              return (
                <span style={{ color: 'green' }} key={a._id}>
                  ◉
                </span>
              );
            }
          })}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: `${theme.palette.tableText.main}`,
            fontWeight: '500',
            fontSize: '14px',
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          {input_amount ? '$' + formatNumber(input_amount) : '---'}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: `${theme.palette.tableText.main}`,
            fontWeight: '500',
            fontSize: '14px',
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          {output_currency ? output_currency.currency.toUpperCase() : '---'}
          {anomalie?.map((a) => {
            if (a.type === 'output') {
              return (
                <span style={{ color: 'red' }} key={a._id}>
                  ◉
                </span>
              );
            }
          })}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: `${theme.palette.tableText.main}`,
            fontWeight: '500',
            fontSize: '14px',
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          {output_amount ? '$' + formatNumber(output_amount) : '---'}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: `${theme.palette.tableText.main}`,
            fontWeight: '500',
            fontSize: '14px',
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          {operation_type.toUpperCase()}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color: `${theme.palette.tableText.main}`,
            fontWeight: '500',
            fontSize: '14px',
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          {liquidator_type ? liquidator_type?.toUpperCase() : '---'}
        </TableCell>
        {operationFinacialOrinstitutional(operation_type) ? (
          <TableCell
            align="left"
            sx={{
              color: `${theme.palette.tableText.main}`,
              fontWeight: '500',
              fontSize: '14px',
              paddingLeft: '8px',
              paddingRight: '8px',
            }}
          >
            <Select
              variant="standard"
              disableUnderline
              value={statusValue}
              onChange={handleChangeStatus}
              sx={{
                color: `${theme.palette.tableText.main}`,
                fontSize: '14px',
              }}
            >
              {operationStatus.map((o, i) => {
                if (i < 4) return;
                return <MenuItem value={o}>{o.toUpperCase()}</MenuItem>;
              })}
            </Select>
          </TableCell>
        ) : (
          <TableCell
            align="left"
            sx={{
              color: `${theme.palette.tableText.main}`,
              fontWeight: '500',
              paddingLeft: '8px',
              paddingRight: '8px',
            }}
          >
            <Select
              variant="standard"
              disableUnderline
              value={statusValue}
              onChange={handleChangeStatus}
              sx={{
                color: `${theme.palette.tableText.main}`,
                fontSize: '14px',
              }}
            >
              {getOptions(userLocal.role, statusValue).map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
        )}
        <TableCell
          align="left"
          sx={{
            color: `${theme.palette.tableText.main}`,
            fontWeight: '500',
            fontSize: '14px',
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
        >
          {client
            ? client?.name
            : serviceProvider
            ? serviceProvider.name
            : paidTeamMember
            ? paidTeamMember.name
            : broker
            ? broker
            : deliveryPositionOperation
            ? deliveryPositionOperation.name
            : '---'}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            paddingLeft: '0px',
            paddingRight: '0px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '5px',
              paddingLeft: '0',
              paddingRight: '0',
            }}
          >
            <EditIcon
              sx={{
                color: `${theme.palette.icons.main}`,
                ':hover': { cursor: 'pointer' },
                visibility: `${
                  operation_type === 'PAGO SERV/PROV' ||
                  operation_type === 'PAGO SUELDOS' ||
                  operation_type === 'LIQ.COMISIONES' ||
                  operation_type === 'INGRESO CTA.CORRIENTE' ||
                  operation_type === 'RETIRO POS.DELIVERY' ||
                  operation_type === 'INGRESO POS.DELIVERY'
                    ? 'hidden'
                    : 'visible'
                }`,
              }}
              onClick={() => {
                navigate(`/editar-operacion/${_id}`);
              }}
            >
              Editar
            </EditIcon>

            <AlertDialog
              handlerAction={deleteOperation}
              objectId={operation._id}
              open={openAlert}
              onClose={() => setOpenAlert(false)}
              context={'operation'}
            />

            <DeleteIcon
              sx={{
                color: `${theme.palette.icons.main}`,
                ':hover': { cursor: 'pointer' },
              }}
              onClick={() => setOpenAlert(true)}
            >
              Eliminar
            </DeleteIcon>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <CollapseTable
              key={createdAt}
              commission={commission}
              inputAnomalie={inputAnomalie}
              outputAnomalie={outputAnomalie}
              assignedDelivery={assignedDelivery}
              comments={comments}
              created={createdAt}
              input_currency={input_currency?.currency.toUpperCase()}
              output_currency={output_currency?.currency.toUpperCase()}
              exchange_rate={exchange_rate}
              liquidatorValue={liquidatorValue}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default Operation;
