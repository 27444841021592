import React from 'react';
import { LoaderSpinner, LoaderContainer } from './loaderStyles';

const Loader = ({ style }) => {
  return (
    <LoaderContainer style={style}>
      <LoaderSpinner />
    </LoaderContainer>
  );
};

export default Loader;
