import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';

const OperationType = ({ filters, setFilters, checkboxStyle }) => {
  const [checked, setChecked] = useState({
    'COMP.VTA.BILLETE': filters.operationType.includes('COMP.VTA.BILLETE'),
    'COMP.VTA.USDT': filters.operationType.includes('COMP.VTA.USDT'),
    'ENV.RECP.TRANSF.PES/EFV': filters.operationType.includes(
      'ENV.RECP.TRANSF.PES/EFV',
    ),
    'ENV.RECP.TRANSF.PES': filters.operationType.includes(
      'ENV.RECP.TRANSF.PES',
    ),
    'ENV.RECP.TRANSF.USD': filters.operationType.includes(
      'ENV.RECP.TRANSF.USD',
    ),
    'ENV.RECP.TRANSF.USD/EFV': filters.operationType.includes(
      'ENV.RECP.TRANSF.USD/EFV',
    ),
    'RECP.CHEQU.PES/EFV': filters.operationType.includes('RECP.CHEQU.PES/EFV'),
    'INT.OP.TER/EFV': filters.operationType.includes('INT.OP.TER/EFV'),
    'PAGO SERVICIOS': filters.operationType.includes('PAGO SERVICIOS'),
    'PAGO PROVEEDOR': filters.operationType.includes('PAGO PROVEEDOR'),
    'PAGO SUELDOS': filters.operationType.includes('PAGO SUELDOS'),
    'RETIRO POS.DELIVERY': filters.operationType.includes(
      'RETIRO POS.DELIVERY',
    ),
  });

  useEffect(() => {
    setChecked({
      'COMP.VTA.BILLETE': filters.operationType.includes('COMP.VTA.BILLETE'),
      'COMP.VTA.USDT': filters.operationType.includes('COMP.VTA.USDT'),
      'ENV.RECP.TRANSF.PES/EFV': filters.operationType.includes(
        'ENV.RECP.TRANSF.PES/EFV',
      ),
      'ENV.RECP.TRANSF.PES': filters.operationType.includes(
        'ENV.RECP.TRANSF.PES',
      ),
      'ENV.RECP.TRANSF.USD': filters.operationType.includes(
        'ENV.RECP.TRANSF.USD',
      ),
      'ENV.RECP.TRANSF.USD/EFV': filters.operationType.includes(
        'ENV.RECP.TRANSF.USD/EFV',
      ),
      'RECP.CHEQU.PES/EFV':
        filters.operationType.includes('RECP.CHEQU.PES/EFV'),
      'INT.OP.TER/EFV': filters.operationType.includes('INT.OP.TER/EFV'),
      'PAGO SERVICIOS': filters.operationType.includes('PAGO SERVICIOS'),
      'PAGO PROVEEDOR': filters.operationType.includes('PAGO PROVEEDOR'),
      'PAGO SUELDOS': filters.operationType.includes('PAGO SUELDOS'),
      'RETIRO POS.DELIVERY': filters.operationType.includes(
        'RETIRO POS.DELIVERY',
      ),
    });
  }, [filters.operationType.length]);

  const handleChange = (e) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      setFilters({
        ...filters,
        operationType: [...filters.operationType, e.target.value],
      });
      setChecked({ ...checked, [e.target.value]: true });
    } else {
      setChecked({ ...checked, [e.target.value]: false });

      const newFilter = filters;
      const removeFilter = filters.operationType.filter(
        (s) => s !== e.target.value,
      );

      newFilter.operationType = removeFilter;
      setFilters(newFilter);
    }
  };

  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="COMP.VTA.BILLETE"
          value="COMP.VTA.BILLETE"
          onChange={handleChange}
          checked={checked['COMP.VTA.BILLETE']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="COMP.VTA.USDT"
          value="COMP.VTA.USDT"
          onChange={handleChange}
          checked={checked['COMP.VTA.USDT']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="ENV.RECP.TRANSF.PES/EFV"
          value="ENV.RECP.TRANSF.PES/EFV"
          onChange={handleChange}
          checked={checked['ENV.RECP.TRANSF.PES/EFV']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="ENV.RECP.TRANSF.PES"
          value="ENV.RECP.TRANSF.PES"
          onChange={handleChange}
          checked={checked['ENV.RECP.TRANSF.PES']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="ENV.RECP.TRANSF.USD"
          value="ENV.RECP.TRANSF.USD"
          onChange={handleChange}
          checked={checked['ENV.RECP.TRANSF.USD']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="ENV.RECP.TRANSF.USD/EFV"
          value="ENV.RECP.TRANSF.USD/EFV"
          onChange={handleChange}
          checked={checked['ENV.RECP.TRANSF.USD/EFV']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="RECP.CHEQU.PES/EFV"
          value="RECP.CHEQU.PES/EFV"
          onChange={handleChange}
          checked={checked['RECP.CHEQU.PES/EFV']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="INT.OP.TER/EFV"
          value="INT.OP.TER/EFV"
          onChange={handleChange}
          checked={checked['INT.OP.TER/EFV']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="PAGO SERVICIOS"
          value="PAGO SERVICIOS"
          onChange={handleChange}
          checked={checked['PAGO SERVICIOS']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="PAGO PROVEEDOR"
          value="PAGO PROVEEDOR"
          onChange={handleChange}
          checked={checked['PAGO PROVEEDOR']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="PAGO SUELDOS"
          value="PAGO SUELDOS"
          onChange={handleChange}
          checked={checked['PAGO SUELDOS']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="RETIRO POS.DELIVERY"
          value="RETIRO POS.DELIVERY"
          onChange={handleChange}
          checked={checked['RETIRO POS.DELIVERY']}
        />
      </FormGroup>
    </div>
  );
};

export default OperationType;
