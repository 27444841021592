import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import ActiveDeliveryHead from './ActiveDeliveryHead';
import ActiveDelivery from './ActiveDelivery';

const ActiveDeliveryTable = ({ onlineDeliverys, handleCenter, background }) => {
  console.log(onlineDeliverys);
  return (
    <TableContainer
      sx={{
        padding: '10px 30px',
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '16px',
        marginTop: '15px',
      }}
    >
      <Table
        sx={{
          width: '100%',
        }}
      >
        <TableBody>
          {onlineDeliverys.map((d) => (
            <ActiveDelivery
              object={d}
              name={d.user.name}
              position={d.position}
              handleCenter={handleCenter}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ActiveDeliveryTable;
