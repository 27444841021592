import styled from 'styled-components';
import theme from '../../theme';

export const AnomaliesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: 260px;
  margin-top: 100px;
`;

export const LeftButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 30px;
  height: 40px;
  align-items: center;
`;
export const RightButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 30px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-content: center;
`;

export const BtnPayOff = styled.button`
  background: #d43436; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif !important;
  box-shadow: 0px 3px 3px #b3abab;
  transition: 0.3s;
  &:hover {
    box-shadow: none;
  }
  margin-left: 0.25rem;
  width: 8.5rem;
  height: 2.35rem;
  align-text: center;
`;

export const BtnSave = styled.button`
  background-color: #97a0af;
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  font-family: 'Roboto', sans-serif !important;
  box-shadow: 0px 3px 3px #b3abab;
  transition: 0.3s;
  &:hover {
    box-shadow: none;
  }
  margin-left: 0.25rem;
  width: 8.5rem;
  height: 2.35rem;
  align-text: center;
`;

export const BtnSaveActive = styled.button`
  background: #364769; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #364769,
    #364769
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #364769,
    #364769
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif !important;
  box-shadow: 0px 3px 3px #b3abab;
  transition: 0.3s;
  &:hover {
    box-shadow: none;
  }
  margin-left: 0.25rem;
  width: 8.5rem;
  height: 2.35rem;
  align-text: center;
`;

export const BtnDatePickerActive = styled.button`
  background: #364769; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #364769,
    #364769
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #364769,
    #364769
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif !important;
  box-shadow: 0px 3px 3px #b3abab;
  transition: 0.3s;
  &:hover {
    box-shadow: none;
  }
  margin-left: 0.25rem;
  width: 10rem;
  height: 2.35rem;
  align-text: center;
`;

export const AnomaliesTitle = styled.h1`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${theme.palette.title.main};
  margin: 0;
  padding: 0 30px;
  margin-bottom: 20px;
  position: relative;
  bottom: 20px;
  margin-left: 1rem;
`;

export const AnomaliesTableContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 20px;
  border-radius: 10px;
  background-color: #fff;
  margin: 0 auto;
  width: 96.5%;
  margin-top: 20px;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
`;

export const InputAmount = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #d2d6d9;
  color: #d2d6d9;
  width: 200px;
  &:focus {
    outline-color: #ccc;
  }
  &::placeholder {
    color: #d2d6d9;
  }
`;

export const InputAmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  margin-top: 8px;
  margin-bottom: 35px;
  padding-right: 30px;
`;
