import React, { useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import theme from '../../theme';

const ActiveDelivery = ({ name, handleCenter, object, position }) => {
  return (
    <TableRow
      sx={
        object.selected
          ? {
              backgroundColor: '#F25456',
            }
          : { backgroundColor: 'null' }
      }
    >
      <TableCell
        component="th"
        scope="row"
        sx={{
          color: object.selected ? 'white' : `${theme.palette.tableText.main}`,
          ':hover': { cursor: 'pointer' },
          borderBottom: 'none',
        }}
        onClick={() => handleCenter(object)}
      >
        {name}
      </TableCell>
      {position.length > 0
        ? position.map((p) => (
            <TableCell
              component="th"
              scope="row"
              sx={{
                color: object.selected
                  ? 'white'
                  : `${theme.palette.tableText.main}`,
                ':hover': { cursor: 'pointer' },
                borderBottom: 'none',
              }}
            >
              {p.currency.name}: {p.balance}
            </TableCell>
          ))
        : null}
    </TableRow>
  );
};

export default ActiveDelivery;
