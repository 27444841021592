import React, { useState } from 'react';
import { InputSearch, SearchContainer } from './searcherStyles';
import RefreshIcon from '../../img/refresh.png';
import SearchIcon from '@mui/icons-material/Search';

const Searcher = ({
  context,
  stockForSearch,
  setStock,
  getStock,
  date,
  userForSearch,
  setUsers,
  getUsers,
  setFilters,
  getOperations,
  setPage,
  page,
  getAnomalies,
  setSearch,
  search,
  getClientsPaginate,
}) => {
  const [value, setValue] = useState('');

  const handleSearch = (e) => {
    if (context === 'stock') {
      const search = e.target.value.toLowerCase();

      if (search.length === 0) return setStock(stockForSearch);

      const filter = stockForSearch.filter((s) =>
        s.currency.toLowerCase().startsWith(search),
      );
      setStock(filter);
    } else if (context === 'users') {
      const search = e.target.value.toLowerCase();

      if (search.length === 0) {
        return setUsers(userForSearch);
      }

      const filter = userForSearch.filter((s) =>
        s.name.toLowerCase().startsWith(search),
      );

      setUsers(filter);
    }
  };

  const handleSearchIcon = (e) => {
    e.preventDefault();
    if (context === 'operations') {
      if (value.length) {
        setFilters({
          status: [],
          operationType: [],
          liquidatorType: [],
          clientName: null,
          inputCurrency: 'Todas',
          outputCurrency: 'Todas',
          dateRange: 'null',
        });
        setPage(1);
        getOperations({ searchById: value }, page);
      }
    }
    if (context === 'anomalies') {
      if (search) {
        getAnomalies(search, 1, date);
        setPage(1);
      }
    }
    if (context === 'clients') {
      if (search) {
        getClientsPaginate(search, 1);
        setPage(1);
      }
    }
  };

  const handleRefresh = () => {
    if (context === 'stock') {
      getStock();
    } else if (context === 'users') {
      setValue('');
      getUsers();
    } else if (context === 'operations') {
      setFilters({
        status: [],
        operationType: [],
        liquidatorType: [],
        clientName: null,
        inputCurrency: 'Todas',
        outputCurrency: 'Todas',
        dateRange: null,
        changed: false,
      });
      setPage(1);
      getOperations(page);
    } else if (context === 'anomalies') {
      getAnomalies(undefined, 1, date);
      setSearch(undefined);
      setPage(1);
    } else if (context === 'clients') {
      getClientsPaginate(undefined, 1);
      setSearch(undefined);
      setPage(1);
    }
  };

  const handlerChange = (e) => {
    if (context === 'operations') {
      if (/^[0-9]*$/.test(e.target.value)) {
        handleSearch(e);
        setValue(e.target.value);
      }
    } else if (context === 'anomalies') {
      setSearch(e.target.value);
    } else if (context === 'clients') {
      setSearch(e.target.value);
    } else {
      handleSearch(e);
      setValue(e.target.value);
    }
  };

  return (
    <form
      onSubmit={(e) => handleSearchIcon(e)}
      onChange={(e) => handlerChange(e)}
    >
      <SearchContainer style={context === 'operations' ? { margin: '0' } : {}}>
        {context === 'operations' ||
        context === 'users' ||
        context === 'anomalies' ||
        context === 'clients' ? (
          <img
            src={RefreshIcon}
            alt="refresh-icon"
            onClick={handleRefresh}
            style={{ width: '25px', cursor: 'pointer' }}
          />
        ) : null}

        <InputSearch
          value={search ? search : value}
          onChange={(e) => {
            handlerChange(e);
          }}
          placeholder="Buscador..."
        />
        {context !== 'stock' && context !== 'users' ? (
          <SearchIcon
            onClick={(e) => {
              handleSearchIcon(e);
            }}
            sx={{
              color: 'white',
              backgroundColor: '#D43436',
              borderRadius: '50px',
              width: '35px',
              height: '35px',
              padding: '8px',
              cursor: 'pointer',
            }}
          />
        ) : null}
      </SearchContainer>
    </form>
  );
};

export default Searcher;
