import React, { useState, useEffect } from 'react';
import { CssBaseline, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ThemeProvider } from 'styled-components';
import Logo from '../../components/Logo/Logo';
import theme from '../../theme';
import {
  BackgroundImage,
  ContainerLogoAndRights,
  LoginSubtitleText,
  LoginTitle,
  LoginTitleText,
  RightsReserved,
  StyledContainer,
} from '../Login/loginStyles';
import getCurrentYear from '../../utils/currentYear';
import ButtonWidth from '../../components/Button/ButtonWidth';
import useRecovery from './useRecovery';
import Success from '../../components/Success/Success';

const SendEmail = () => {
  const { recoveryPassword, showModal, setShowModal, errorRecovery } =
    useRecovery();

  const [disabled, setDisabled] = useState(true);

  const [email, setEmail] = useState('');

  const handleChangeEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      recoveryPassword(email);
    } else {
      console.log('Ingrese un email');
    }
  };

  const handleCloseModal = () => {
    setShowModal(!showModal);
  };

  const handleDisabled = () => {
    if (email) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  useEffect(() => {
    handleDisabled();
  }, [email]);

  return (
    <BackgroundImage>
      <ThemeProvider theme={theme}>
        <ContainerLogoAndRights>
          <Logo />
          <RightsReserved>
            © {getCurrentYear()} LYM, made by{' '}
            <a
              style={{
                color: '#fff',
                textDecoration: 'none',
              }}
              href="https://www.jrc.dev/"
              target={'_blank'}
            >
              JRC Studio
            </a>
          </RightsReserved>
        </ContainerLogoAndRights>
        {showModal ? (
          <Success
            title="¡Revisa tu buzón de correo!"
            subtitle="Recordá revisar también la sección de spam. Ya podés cerrar esta pestaña."
            handleCloseModal={handleCloseModal}
          />
        ) : null}
        <LoginTitle>
          <LoginTitleText>Recuperar contraseña</LoginTitleText>
          <div
            style={{
              width: '63%',
            }}
          >
            <LoginSubtitleText
              style={{
                fontFamily: `${theme.typography.fontWeightRegular}`,
              }}
            >
              Ingrese su email para recibir un enlace de recuperación
            </LoginSubtitleText>
          </div>
        </LoginTitle>
        <StyledContainer component="main" maxWidth="xs" height="45vh">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 20, width: '100%' }}
            >
              <TextField
                onChange={(e) => handleChangeEmail(e)}
                margin="normal"
                required
                fullWidth={true}
                name="email"
                label="Email"
                type="email"
                id="email"
                sx={{
                  '& label.Mui-focused': {
                    color: '#D43436',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#D2D6D9',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#D3D6DA',
                    },
                    '&:hover fieldset': {
                      borderColor: '#D43436',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#D43436',
                    },
                  },
                }}
              />
              {errorRecovery?.length > 0 ? (
                <Typography sx={{ color: 'red', fontWeight: '600' }}>
                  {errorRecovery}
                </Typography>
              ) : null}
              <div
                style={{
                  width: '100%',
                  marginTop: '30px',
                }}
              >
                <ButtonWidth disabled={disabled} type="submit" title="ENVIAR" />
              </div>
            </Box>
          </Box>
        </StyledContainer>
      </ThemeProvider>
    </BackgroundImage>
  );
};

export default SendEmail;
