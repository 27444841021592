import React from 'react';
import fetchFromApi from '../../utils/fetchFromApi';

const useNotification = () => {
  const createNotification = async (notification) => {
    try {
      const response = fetchFromApi('POST', '/notification', {
        ...notification,
        read: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return { createNotification };
};

export default useNotification;
