import React from 'react';
import SuccessIcon from '../../img/accept.png';
import {
  StyledContainer,
  LoginTitleText,
  LoginSubtitleText,
} from '../../containers/Login/loginStyles';

const Success = ({ title, subtitle, handleCloseModal }) => {
  return (
    <StyledContainer
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '450px',
        zIndex: '99',
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
      }}
    >
      <img
        src={SuccessIcon}
        alt="Success"
        style={{
          width: '150px',
          height: '150px',
          marginBottom: '30px',
        }}
      />
      <LoginTitleText
        style={{
          color: '#384764',
        }}
      >
        {title}
      </LoginTitleText>
      <LoginSubtitleText
        style={{
          color: '#384764',
          opacity: '0.5',
        }}
      >
        {subtitle}
      </LoginSubtitleText>
    </StyledContainer>
  );
};

export default Success;
