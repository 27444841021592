import fetchFromApi from '../../utils/fetchFromApi';
import { useState } from 'react';
import useNotistack from '../Notistack/useNotistack';
import useNotification from '../../services/Notification/useNotification';
import useUsers from '../Team/useUsers';
import { useNavigate } from 'react-router-dom';
import validateOperation from './operationsValidations';

const useOperations = () => {
  const [operations, setOperations] = useState([]);
  const [operation, setOperation] = useState({});
  const { getProfile, profile: profileToken } = useUsers();
  const { showNotification } = useNotistack();
  const [errors, setErrors] = useState({
    client: false,
    operation_type: false,
    input_currency: false,
    input_amount: false,
    output_currency: false,
    output_amount: false,
    liquidator_type: false,
    input_anomaly: false,
    output_anomaly: false,
    assignedDelivery: false,
    address: false,
    exchange_rate: false,
    paidTeamMember: false,
    broker: false,
    balance: false,
    currency: false,
    userId: false,
  });
  const { createNotification } = useNotification();
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [operationsCSV, setOperationsCSV] = useState([]);
  const [allOperations, setAllOperations] = useState([]);
  const [cotizations, setCotizations] = useState([]);

  const navigate = useNavigate();

  const createOperation = async (operation, profile) => {
    if (validateOperation(operation)) {
      const [errors, message] = validateOperation(operation);
      setErrors(errors);

      return showNotification(message, 'error');
    }

    try {
      const response = await fetchFromApi('POST', 'operation', operation);
      getProfile(profile).then((profile) => {
        createNotification({
          title: 'Se te asignó una nueva operación.',
          tokens: profile.fcmToken,
          body: `Bla bla bla.`,
          navigateTo: 'Operations',
          profile: profile._id,
        });
      });

      showNotification(response[1], 'success');
      getOperations();
      navigate('/operaciones');
    } catch (error) {
      console.log(error);
      error.response.data[0].map((e) => showNotification(e, 'error'));
    }
  };

  const getOperations = async (filters, page) => {
    setLoading(true);
    try {
      const operations = await fetchFromApi(
        'GET',
        `operation/?page=${page}&operation_type=${filters?.operationType}&status=${filters?.status}&liquidator_type=${filters?.liquidatorType}&operationId=${filters?.searchById}&clientName=${filters?.clientName}&inputCurrency=${filters?.inputCurrency}&outputCurrency=${filters?.outputCurrency}&dateRange=${filters?.dateRange}`,
      );

      setOperations(operations.docs);
      setNumberOfPages(operations.totalPages);
    } catch (error) {
      console.log(error);
      if (error.response.data[0][0] === 'Invalid Data, _id')
        showNotification('El ID introducido es invalido', 'error');
    }
    setLoading(false);
  };

  const deleteOperation = async (id, context) => {
    try {
      const result = await fetchFromApi('DELETE', 'operation', id);
      if (result) {
        const newOperations = operations.filter(
          (operation) => operation._id !== id,
        );
        getOperations({}, 1);
      }
      if (context !== 'multi') showNotification(result[1], 'success');
    } catch (error) {
      console.log(error);
      showNotification(
        'Ha ocurrido un error al eliminar la operación.',
        'error',
      );
    }
  };

  const updateOperation = async (id, operation) => {
    if (validateOperation(operation)) {
      const [errors, message] = validateOperation(operation);
      setErrors(errors);

      return showNotification(message, 'error');
    }
    try {
      const response = await fetchFromApi('PUT', `operation/${id}`, {
        operation,
      });
      showNotification(response[1], 'success');
      getOperations();
      navigate('/operaciones');
    } catch (error) {
      console.log(error);
    }
  };

  const changeDeliveryOperation = async (opId, delId) => {
    try {
      const response = await fetchFromApi(
        'PUT',
        `operation/change-delivery/?opId=${opId}&delId=${delId}`,
      );

      if (delId !== 'null')
        getProfile(delId).then((profile) => {
          createNotification({
            title: 'Se te asignó una nueva operación.',
            tokens: profile.fcmToken,
            body: `Bla bla bla.`,
            navigateTo: 'Operations',
            profile: profile._id,
          });
        });
      showNotification(response[1], 'success');
    } catch (error) {
      showNotification(
        'Ha ocurrido un error. No se ha podidio cambiar el delivery.',
        'error',
      );
    }
  };

  const changeOperationStatus = async (id, status, context, user) => {
    console.log(user);
    try {
      const response = await fetchFromApi(
        'PUT',
        `operation/status/${id}/?name=${user?.name}&email=${user?.email}`,
        status,
      );

      if (context !== 'multi') showNotification(response[0], 'success');
    } catch (error) {
      showNotification(
        'Ha ocurrido un error al cambiar el estado de la operación.',
        'error',
      );
    }
  };
  const registerPosition = async (position, type) => {
    position.type = type;
    position.operation_type = type;
    if (validateOperation(position)) {
      const [errors, message] = validateOperation(position);
      setErrors(errors);
      return showNotification(message, 'error');
    }
    try {
      if (position) {
        const response = await fetchFromApi('POST', `position`, {
          position,
        });

        showNotification(response[1], 'success');
        getOperations();
        navigate('/operaciones');
      } else {
        showNotification('No se pudo registrar la posición.', 'error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOperationById = async (id) => {
    setLoading(true);
    try {
      const response = await fetchFromApi('GET', `operation/${id}`);
      setOperation({
        ...response,
        input_currency: response.input_currency._id,
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getServiceProivders = async () => {
    try {
      const response = await fetchFromApi('GET', `service-provider`);

      setServiceProviders(response);
    } catch (error) {
      console.log(error);
    }
  };

  const createNewServiceProvider = async (name) => {
    try {
      const response = await fetchFromApi('POST', `service-provider`, name);

      showNotification(response[0], 'success');
    } catch (error) {
      console.log(error);
      showNotification(
        error.response.data[0] ===
          'Ya hay un servicio/proveedor con este nombre.'
          ? error.response.data[0]
          : 'Ha ocurrido un error al registrar este servicio/proveedor.',
        'error',
      );
    }
  };

  const deleteServiceProvider = async (name) => {
    try {
      const response = await fetchFromApi('DELETE', 'service-provider', name);
      showNotification(response[0], 'success');
    } catch (error) {
      console.log(error);
    }
  };

  const getOperationsForCSV = async () => {
    try {
      const response = await fetchFromApi('GET', 'operation/all/csv');
      setOperationsCSV(response);
      return response;
    } catch (error) {
      showNotification('Ha ocurrido un error al descargar el CSV.', 'error');
    }
  };

  const getAllOperationsWithoutPaginate = async () => {
    try {
      const response = await fetchFromApi('GET', 'operation/all/nopaginate');
      setAllOperations(response);
      return response;
    } catch (error) {
      showNotification('Ha ocurrido un error.', 'error');
    }
  };

  const getCotizations = async () => {
    try {
      const response = await fetchFromApi('GET', 'cotization');
      setCotizations(response);
    } catch (error) {
      console.error(error);
      showNotification(
        'Ha ocurrido un error al cargar las cotizaciones.',
        'error',
      );
    }
  };

  const chargeCotization = async (id, value) => {
    try {
      const response = await fetchFromApi(
        'PUT',
        `cotization/value/${id}`,
        value,
      );
      showNotification(
        'Cotizacion cargada, se a enviado un mensaje al cliente.',
        'success',
      );
    } catch (error) {
      console.error(error);
      showNotification(error.response.data[0], 'error');
    }
  };

  return {
    createOperation,
    getOperations,
    deleteOperation,
    updateOperation,
    changeOperationStatus,
    changeDeliveryOperation,
    getOperationById,
    setOperation,
    operations,
    operation,
    errors,
    setErrors,
    numberOfPages,
    loading,
    registerPosition,
    getServiceProivders,
    serviceProviders,
    createNewServiceProvider,
    deleteServiceProvider,
    getOperationsForCSV,
    operationsCSV,
    getAllOperationsWithoutPaginate,
    allOperations,
    getCotizations,
    cotizations,
    chargeCotization,
  };
};

export default useOperations;
