/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import LogisticOperationTable from '../../components/Logistic/LogisticOperationTable';
import useOperations from '../Operations/useOperations';
import { OperationContainer, LogisticSubTitle } from './logisticStyles';
import useUsers from '../Team/useUsers';

const LogisticOperations = ({ filter }) => {
  const { getOperations, operations } = useOperations();
  const { getDeliverys, deliverys } = useUsers();

  useEffect(() => {
    getDeliverys();
  }, []);

  useEffect(() => {
    getOperations(filter);
  }, [filter]);

  return (
    <OperationContainer>
      <LogisticSubTitle>Operaciones</LogisticSubTitle>
      <LogisticOperationTable operations={operations} deliverys={deliverys} />
    </OperationContainer>
  );
};

export default LogisticOperations;
