import { Button, Snackbar } from '@mui/material';
import { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyToClipboardButton = ({ msg, id }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(id.toString());
  };

  return (
    <>
      <Button sx={{ color: '#7C8098' }} onClick={handleClick}>
        <ContentCopyIcon
          fontSize="small"
          sx={{ marginRight: '2px', marginBottom: '5px' }}
        />
        {id?.length > 6 ? `...${id.slice(id?.length - 4)}` : `#${id}`}
      </Button>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="ID copiado al portapapeles"
      />
    </>
  );
};

export default CopyToClipboardButton;
