import React from 'react';
import { BtnOutline } from './buttonStyles';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';

const ButtonOutline = ({ onClick, title, icon }) => {
  return (
    <div>
      <BtnOutline onClick={onClick}>
        {icon === 'csv' ? <MoveToInboxIcon sx={{ color: '#7C8098' }} /> : null}
        <span style={{ textDecoration: 'none' }}>{title}</span>
      </BtnOutline>
    </div>
  );
};

export default ButtonOutline;
