import React, { useEffect, useState } from 'react';
import { TableHead, TableRow } from '@mui/material';

import { UserTableCell } from '../../containers/Team/userStyles';

const UsersHead = () => {
  return (
    <TableHead>
      <TableRow>
        <UserTableCell align="left">NOMBRE</UserTableCell>
        <UserTableCell align="left">EMAIL</UserTableCell>
        <UserTableCell align="left">TELÉFONO</UserTableCell>
        <UserTableCell align="left">ROL</UserTableCell>
        <UserTableCell align="left">FECHA DE CREACIÓN</UserTableCell>
        <UserTableCell align="left"></UserTableCell>
      </TableRow>
    </TableHead>
  );
};

export default UsersHead;
