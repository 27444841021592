import React from 'react';
import { Btn } from './buttonStyles';

const Button = ({ title, onClick, width, disable }) => {
  const style = disable ? { background: '#d46a6b', boxShadow: 'none' } : null;
  return (
    <div style={{ marginTop: '0px' }}>
      <Btn style={style} disabled={disable} onClick={onClick}>
        {title}
      </Btn>
    </div>
  );
};

export default Button;
