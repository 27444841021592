import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import useAuth from '../../Login/useAuth';
import theme from '../../../theme';
import useUsers from '../../Team/useUsers';

import {
  CurrenciesContainer,
  CurrenciesInputContainer,
  CurrencyContainer,
  ExchangeRateContainer,
} from '../operationsStyles';

export const CurrenciesInput = ({
  handleInputChange,
  stock,
  operation,
  setOperation,
  setCurrencyChecked,
  errors,
}) => {
  const [exchange, setExchange] = useState({
    input_currency: operation.input_currency?._id,
    input_amount: operation.input_amount,
    output_currency: operation.output_currency?._id,
    output_amount: operation.output_amount,
    exchange_rate: operation?.exchange_rate,
  });

  const user = useAuth();
  const { getProfile, profile } = useUsers();

  const handleCurrenciesChange = async (e) => {
    let currentChange = e.target.name;

    if (currentChange === 'input_amount') {
      if (exchange.exchange_rate && !exchange.output_amount) {
        const calc =
          parseInt(e.target.value) * parseInt(exchange.exchange_rate);

        setOperation({
          ...operation,
          output_amount: calc,
          input_amount: e.target.value,
        });
        return setExchange({
          ...exchange,
          input_amount: e.target.value,
          output_amount: calc,
        });
      }
      if (exchange.output_amount && !exchange.exchange_rate) {
        const calc =
          parseInt(exchange.output_amount) / parseInt(e.target.value);

        setOperation({
          ...operation,
          exchange_rate: calc,
          input_amount: e.target.value,
        });
        return setExchange({
          ...exchange,
          input_amount: e.target.value,
          exchange_rate: calc,
        });
      }
      if (exchange.output_amount && exchange.exchange_rate) {
        const calc =
          parseInt(e.target.value) * parseInt(exchange.exchange_rate);

        setOperation({
          ...operation,
          output_amount: calc,
          input_amount: e.target.value,
        });
        return setExchange({
          ...exchange,
          input_amount: e.target.value,
          output_amount: calc,
        });
      }

      setOperation({ ...operation, input_amount: e.target.value });

      return setExchange({
        ...exchange,
        input_amount: e.target.value,
      });
    }

    if (currentChange === 'output_amount') {
      if (!exchange.exchange_rate && exchange.input_amount) {
        const calc = parseInt(exchange.input_amount) / parseInt(e.target.value);

        setOperation({
          ...operation,
          exchange_rate: calc,
          output_amount: e.target.value,
        });
        return setExchange({
          ...exchange,
          output_amount: e.target.value,
          exchange_rate: calc,
        });
      }

      if (exchange.exchange_rate && !exchange.input_amount) {
        const calc =
          parseInt(e.target.value) / parseInt(exchange.exchange_rate);

        setOperation({
          ...operation,
          input_amount: calc,
          output_amount: e.target.value,
        });

        return setExchange({
          ...exchange,
          output_amount: e.target.value,
          input_amount: calc,
        });
      }

      if (exchange.exchange_rate && exchange.input_amount) {
        const calc = parseInt(e.target.value) / parseInt(exchange.input_amount);

        setOperation({
          ...operation,
          exchange_rate: calc,
          output_amount: e.target.value,
        });

        return setExchange({
          ...exchange,
          output_amount: e.target.value,
          exchange_rate: calc,
        });
      }

      setOperation({ ...operation, output_amount: e.target.value });

      return setExchange({
        ...exchange,
        output_amount: e.target.value,
      });
    }

    if (currentChange === 'exchange_rate') {
      if (exchange.input_amount && exchange.output_amount) {
        const calc = parseInt(e.target.value) * parseInt(exchange.input_amount);

        setOperation({
          ...operation,
          output_amount: calc,
          exchange_rate: e.target.value,
        });

        return setExchange({
          ...exchange,
          exchange_rate: e.target.value,
          output_amount: calc,
        });
      }

      if (exchange.input_amount && !exchange.output_amount) {
        const calc = parseInt(e.target.value) * parseInt(exchange.input_amount);

        setOperation({
          ...operation,
          output_amount: calc,
          exchange_rate: e.target.value,
        });

        return setExchange({
          ...exchange,
          exchange_rate: e.target.value,
          output_amount: calc,
        });
      }

      if (exchange.output_amount && !exchange.input_amount) {
        const calc =
          parseInt(exchange.output_amount) / parseInt(e.target.value);

        setOperation({
          ...operation,
          input_amount: calc,
          exchange_rate: e.target.value,
        });

        return setExchange({
          ...exchange,
          exchange_rate: e.target.value,
          input_amount: calc,
        });
      }

      setOperation({ ...operation, exchange_rate: e.target.value });

      return setExchange({
        ...exchange,
        exchange_rate: e.target.value,
      });
    }
  };

  useEffect(() => {
    if (user) {
      getProfile(user?.id);
    }
  }, []);

  return (
    <CurrenciesInputContainer>
      <CurrenciesContainer>
        <CurrencyContainer>
          {/* {DIVISA DE ENTRADA} */}
          <FormControl sx={{ width: '30%' }} error={errors.input_currency}>
            <InputLabel
              sx={{
                color: `${theme.palette.inputText.main}`,
                marginLeft: '20px',
                display: `${operation.input_currency ? 'none' : ''}`,
              }}
              id="input"
              shrink={false}
            >
              Divisa
            </InputLabel>
            <Select
              disabled={profile.role === 'liquidador_caja' ? true : false}
              labelId="input"
              onChange={(event) => {
                handleInputChange(event);
                handleCurrenciesChange(event);
                setCurrencyChecked(null);
              }}
              name="input_currency"
              value={operation.input_currency}
              sx={{
                borderRadius: '14px',
                backgroundColor: '#F0F2F5',
                height: '49.5px',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              {stock?.map((s) => {
                return (
                  <MenuItem value={s._id} key={s._id}>
                    {s.currency}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* MONTO DE ENTRADA */}
          <TextField
            error={errors.input_amount}
            disabled={profile.role === 'liquidador_caja' ? true : false}
            placeholder="Monto de entrada"
            name="input_amount"
            value={exchange.input_amount}
            onChange={(event) => {
              handleCurrenciesChange(event);
            }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              style: {
                color: `black`,
                height: '46px',
                borderRadius: '10px',
                backgroundColor: 'white',
                paddingBottom: '12px',
              },
            }}
            variant="filled"
            fullWidth
            type="number"
          />
        </CurrencyContainer>
        <CurrencyContainer>
          {/* {DIVISA DE SALIDA} */}
          <FormControl sx={{ width: '30%' }} error={errors.output_currency}>
            <InputLabel
              sx={{
                color: `${theme.palette.inputText.main}`,
                marginLeft: '20px',
                display: `${operation.output_currency ? 'none' : ''}`,
              }}
              id="input"
              shrink={false}
            >
              Divisa
            </InputLabel>

            <Select
              disabled={profile.role === 'liquidador_caja' ? true : false}
              labelId="output"
              onChange={(event) => {
                handleCurrenciesChange(event);
                handleInputChange(event);
              }}
              name="output_currency"
              defaultValue={exchange.output_currency}
              sx={{
                borderRadius: '14px',
                backgroundColor: '#F0F2F5',
                height: '49.5px',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              {stock?.map((s) => {
                return (
                  <MenuItem value={s._id} key={s._id}>
                    {s.currency}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* MONTO DE SALIDA */}
          <TextField
            error={errors.output_amount}
            disabled={profile.role === 'liquidador_caja' ? true : false}
            placeholder="Monto de salida"
            name="output_amount"
            value={exchange.output_amount}
            onChange={(event) => {
              handleCurrenciesChange(event);
            }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              style: {
                color: `black`,
                height: '46px',
                borderRadius: '10px',
                backgroundColor: 'white',
                paddingBottom: '12px',
              },
            }}
            variant="filled"
            fullWidth
            type="number"
          />
        </CurrencyContainer>
      </CurrenciesContainer>
      <ExchangeRateContainer>
        {/* TIPO DE CAMBIO */}
        <TextField
          error={errors.exchange_rate}
          disabled={profile.role === 'liquidador_caja' ? true : false}
          fullWidth
          placeholder="Tipo de cambio"
          name="exchange_rate"
          value={exchange.exchange_rate}
          onChange={(event) => {
            handleCurrenciesChange(event);
          }}
          InputLabelProps={{
            style: {
              color: `${theme.palette.inputText.main}`,
            },
          }}
          InputProps={{
            style: { height: '50px', borderRadius: '10px' },
          }}
          type="number"
        />
      </ExchangeRateContainer>
    </CurrenciesInputContainer>
  );
};
