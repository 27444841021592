/* eslint-disable no-unused-vars */
import operationFinacialOrinstitutional from '../../utils/operationFinancialOrInstitutional';

export default function validateOperation(operation) {
  const {
    input_currency,
    output_currency,
    input_amount,
    output_amount,
    liquidator_type,
    client,
    address,
    assignedDelivery,
    exchange_rate,
    service_provider,
    paidTeamMember,
    broker,
    currency,
    balance,
    userId,
  } = operation;
  const { operation_type } = operation;

  let areThereErrors = false;
  let errors = {
    client: false,
    operation_type: false,
    input_currency: false,
    input_amount: false,
    output_currency: false,
    output_amount: false,
    liquidator_type: false,
    input_anomaly: false,
    output_anomaly: false,
    assignedDelivery: false,
    address: false,
    exchange_rate: false,
    paidTeamMember: false,
    broker: false,
    balance: false,
    currency: false,
    userId: false,
  };
  let message = [];

  if (operationFinacialOrinstitutional(operation_type)) {
    if (
      operation_type === 'PAGO SERV/PROV' ||
      operation_type === 'PAGO SUELDOS' ||
      operation_type === 'LIQ.COMISIONES'
    ) {
      if (!output_currency) {
        areThereErrors = true;
        errors.output_currency = true;
        message.push('Divisa de salida');
      }
      if (!output_amount) {
        areThereErrors = true;
        errors.output_amount = true;
        message.push('Monto de salida');
      }

      if (operation_type === 'PAGO SERV/PROV') {
        if (!service_provider) {
          areThereErrors = true;
          errors.serviceProvider = true;
          message.push('Servicio / Proveedor');
        }
      }

      if (operation_type === 'PAGO SUELDOS') {
        if (!paidTeamMember) {
          areThereErrors = true;
          errors.paidTeamMember = true;
          message.push('Equipo');
        }
      }

      if (operation_type === 'LIQ.COMISIONES') {
        if (!broker) {
          areThereErrors = true;
          errors.broker = true;
          message.push('Corredor');
        }
      }
    }
    if (operation_type === 'INGRESO CTA.CORRIENTE') {
      if (!input_currency) {
        areThereErrors = true;
        errors.input_currency = true;
        message.push('Divisa de entrada');
      }
      if (!input_amount) {
        areThereErrors = true;
        errors.input_amount = true;
        message.push('Monto de entrada');
      }
      if (!client) {
        areThereErrors = true;
        errors.client = true;
        message.push('Cliente');
      }
    }
    if (
      operation_type === 'RETIRO POS.DELIVERY' ||
      operation_type === 'INGRESO POS.DELIVERY'
    ) {
      if (!currency) {
        areThereErrors = true;
        errors.currency = true;
        message.push('Divisa');
      }
      if (!balance) {
        areThereErrors = true;
        errors.balance = true;
        message.push('Balance');
      }
      if (!userId) {
        areThereErrors = true;
        errors.userId = true;
        message.push('Delivery');
      }
    }
  } else {
    if (!input_currency) {
      areThereErrors = true;
      errors.input_currency = true;
      message.push('Divisa de entrada');
    }
    if (!input_amount) {
      areThereErrors = true;
      errors.input_amount = true;
      message.push('Monto de entrada');
    }
    if (!output_currency) {
      areThereErrors = true;
      errors.output_currency = true;
      message.push('Divisa de salida');
    }
    if (!output_amount) {
      areThereErrors = true;
      errors.output_amount = true;
      message.push('Monto de salida');
    }
    if (!client) {
      areThereErrors = true;
      errors.client = true;
      message.push('Cliente');
    }
    if (!liquidator_type) {
      areThereErrors = true;
      errors.liquidator_type = true;
      message.push('Tipo de liquidador');
    }
    if (!exchange_rate) {
      areThereErrors = true;
      errors.exchange_rate = true;
      message.push('Tipo de cambio');
    }
    if (liquidator_type === 'DELIVERY' && !assignedDelivery) {
      areThereErrors = true;
      errors.assignedDelivery = true;
      message.push('Delivery');
    }
    if (liquidator_type === 'DELIVERY' && !address) {
      areThereErrors = true;
      errors.address = true;
      message.push('Direccion');
    }
  }
  if (areThereErrors) {
    message = `El campo ${message.join(', ')} no puede ser un campo vacio`;
    return [errors, message];
  } else {
    return false;
  }
}
