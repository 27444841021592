import {
  Autocomplete,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React from 'react';
import theme from '../../../theme';
import { operationTypes } from '../../../utils/constants';

const PaySalariesForm = ({
  operation,
  handleInputChange,
  stock,
  users,
  setOperation,
  errors,
}) => {
  const [value, setValue] = React.useState(null);

  return (
    <>
      {/* TIPO DE OPERACION */}
      <FormControl variant="standard" fullWidth>
        <InputLabel
          sx={{ color: `${theme.palette.inputText.main}` }}
          id="input"
        >
          Tipo de Operacion
        </InputLabel>
        <Select
          labelId="input"
          name="operation_type"
          onChange={(event) => handleInputChange(event)}
          value={operation.operation_type}
        >
          <ListSubheader>OPERACIONES FINANCIERAS</ListSubheader>
          {operationTypes().map((o, i) => {
            if (i > 7) return null;
            return <MenuItem value={o}>{o}</MenuItem>;
          })}
          <ListSubheader>OPERACIONES INSTITUCIONALES</ListSubheader>
          {operationTypes().map((o, i) => {
            if (i < 8) return null;
            return <MenuItem value={o}>{o}</MenuItem>;
          })}
        </Select>
      </FormControl>
      <Autocomplete
        options={users}
        getOptionLabel={(users) => users?.name}
        renderInput={(params) => {
          return (
            <TextField
              error={errors.paidTeamMember}
              {...params}
              label="Equipo"
              variant="standard"
              InputLabelProps={{
                style: {
                  color: `${theme.palette.inputText.main}`,
                },
              }}
              sx={{
                input: {
                  color: `${theme.palette.inputText.main}`,
                },
                '& .MuiInputLabel-root': { color: '#D3D6DA' },
              }}
            />
          );
        }}
        onChange={(event, value) => {
          setOperation({ ...operation, paidTeamMember: value._id });
          setValue(value);
        }}
        value={value}
      />

      {/* {DIVISA DE SALIDA} */}
      <FormControl variant="standard" error={errors.output_currency}>
        <InputLabel
          sx={{ color: `${theme.palette.inputText.main}` }}
          id="output"
        >
          Divisa de Salida
        </InputLabel>
        <Select
          labelId="output"
          onChange={(event) => handleInputChange(event)}
          name="output_currency"
        >
          {stock?.map((s) => {
            return (
              <MenuItem value={s._id} key={s._id}>
                {s.currency}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {/* MONTO DE SALIDA */}
      <TextField
        error={errors.output_amount}
        label="Monto de salida"
        variant="standard"
        type="number"
        name="output_amount"
        onChange={(event) => handleInputChange(event)}
        InputLabelProps={{
          style: { color: `${theme.palette.inputText.main}` },
        }}
        sx={{
          input: {
            color: `${theme.palette.inputText.main}`,
          },
          '& .MuiInputLabel-root': { color: '#D3D6DA' },
          borderBottom: '1px solid #D3D6DA',
        }}
        InputProps={{ disableUnderline: !errors.output_amount }}
      />
      {/* COMENTARIOS */}
      <TextField
        InputLabelProps={{
          style: { color: `${theme.palette.inputText.main}` },
        }}
        sx={{
          input: {
            color: `${theme.palette.inputText.main}`,
          },
          '& .MuiInputLabel-root': { color: '#D3D6DA' },
          borderBottom: '1px solid #D3D6DA',
        }}
        InputProps={{ disableUnderline: true }}
        variant="standard"
        type="text"
        name="comments"
        label="Comentarios"
        onChange={(event) => handleInputChange(event)}
        fullWidth={true}
      />
    </>
  );
};

export default PaySalariesForm;
