import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { useState, useEffect } from 'react';

const LiquidatorType = ({ filters, setFilters, checkboxStyle }) => {
  const [checked, setChecked] = useState({
    'CAJA A': filters.liquidatorType.includes('CAJA A'),
    'CAJA C': filters.liquidatorType.includes('CAJA C'),
    DELIVERY: filters.liquidatorType.includes('DELIVERY'),
  });

  useEffect(() => {
    setChecked({
      'CAJA A': filters.liquidatorType.includes('CAJA A'),
      'CAJA C': filters.liquidatorType.includes('CAJA C'),
      DELIVERY: filters.liquidatorType.includes('DELIVERY'),
    });
  }, [filters.liquidatorType.length]);

  const handleChange = (e) => {
    if (e.target.checked) {
      setFilters({ ...filters }, filters.liquidatorType.push(e.target.value));
      setChecked({ ...checked, [e.target.value]: true });
    } else {
      setChecked({ ...checked, [e.target.value]: false });

      const newFilter = filters;
      const removeFilter = filters.liquidatorType.filter(
        (s) => s !== e.target.value,
      );

      newFilter.liquidatorType = removeFilter;
      setFilters(newFilter);
    }
  };

  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="CAJA A"
          value="CAJA A"
          onChange={handleChange}
          checked={checked['CAJA A']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="CAJA C"
          value="CAJA C"
          onChange={handleChange}
          checked={checked['CAJA C']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="DELIVERY"
          value="DELIVERY"
          onChange={handleChange}
          checked={checked['DELIVERY']}
        />
      </FormGroup>
    </div>
  );
};

export default LiquidatorType;
