import EditUser from './EditUser';

export const validateUserForm = (userData, context) => {
  const validateEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
  const validPassword = /(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,}$/;
  const validateNum = /^[0-9]+$/;

  if (userData.name === '') {
    return [['el campo "Nombre" no puede estar vacío.'], { name: true }];
  }
  if (userData.email === '') {
    return [
      ['El "Email" no puede estar vacío o tiene que tener formato correcto.'],
      { email: true },
    ];
  }
  if (!validateEmail.test(userData.email)) {
    return [['Correo electronico inválido.'], { email: true }];
  }

  if (userData.cellphone === '') {
    return [
      [
        'El "Teléfono" no puede estar vacío o tiene que tener formato correcto.',
      ],
      { cellphone: true },
    ];
  }
  if (!validateNum.test(userData.cellphone)) {
    return [
      ['El campo "Teléfono" solo debe tener números.'],
      { cellphone: true },
    ];
  }
  if (userData.userRole === '') {
    return [['Debe seleccionar un rol.'], { userRole: true }];
  }

  if (context === 'EditUser') {
    if (userData.password.length) {
      if (userData.repeatPassword === '') {
        return [
          ['el campo "Repetir Contraseña" no puede estar vacío.'],
          { repeatPassword: true },
        ];
      }
      if (!validPassword.test(userData.password)) {
        return [
          [
            'La contraseña debe tener al menos 8 dígitos, una mayúscula y un número.',
          ],
          { password: true },
        ];
      }

      if (userData.password !== userData.repeatPassword) {
        return [['Las contraseñas no coinciden'], {}];
      }

      return { valid: true };
    }

    if (userData.repeatPassword.length) {
      if (userData.password === '') {
        return [
          ['el campo "Contraseña" no puede estar vacío.'],
          { repeatPassword: true },
        ];
      }
      if (!validPassword.test(userData.password)) {
        return [
          [
            'La contraseña debe tener al menos 8 dígitos, una mayúscula y un número.',
          ],
          { password: true },
        ];
      }

      if (userData.password !== userData.repeatPassword) {
        return [['Las contraseñas no coinciden'], {}];
      }

      return { valid: true };
    }

    return { valid: true };
  }

  if (userData.password === '') {
    return [
      ['el campo "Contraseña" no puede estar vacío.'],
      { password: true },
    ];
  }

  if (userData.repeatPassword === '') {
    return [
      ['el campo "Repetir Contraseña" no puede estar vacío.'],
      { repeatPassword: true },
    ];
  }
  if (!validPassword.test(userData.password)) {
    return [
      [
        'La contraseña debe tener al menos 8 dígitos, una mayúscula y un número.',
      ],
      { password: true },
    ];
  }

  if (userData.password !== userData.repeatPassword) {
    return [['Las contraseñas no coinciden'], {}];
  }

  return { valid: true };
};
