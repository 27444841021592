export const validateClientForm = (clientData) => {
  const validateEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
  const validateNum = /^[0-9]+$/;

  if (clientData.name === '') {
    return [['El campo "Nombre" no puede estar vacío.'], { name: true }];
  }
  if (clientData.email === '') {
    return [
      ['El "Email" no puede estar vacío o tiene que tener formato correcto.'],
      { email: true },
    ];
  }
  if (clientData.cellphone === '') {
    return [
      [
        'El "Teléfono" no puede estar vacío o tiene que tener formato correcto.',
      ],
      { cellphone: true },
    ];
  }

  if (clientData.address === '') {
    return [['El campo "Dirección" no puede estar vacío'], { address: true }];
  }

  if (!validateNum.test(clientData.cellphone)) {
    return [
      ['El campo "Teléfono" solo debe tener números.'],
      { cellphone: true },
    ];
  }

  if (!validateEmail.test(clientData.email)) {
    return [['Correo electronico invalido.'], { email: true }];
  }

  return { valid: true };
};
