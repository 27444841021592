import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import theme from '../../theme';
import formatNumber from '../../utils/formatNumber';

const CollapseTable = ({
  commission,
  outputAnomalie,
  inputAnomalie,
  assignedDelivery,
  comments,
  output_currency,
  input_currency,
  exchange_rate,
  liquidatorValue,
}) => {
  return (
    <div style={{ padding: '20px 0px 20px 140px', width: '0px' }}>
      <h3
        style={{
          margin: 0,
          paddingLeft: '16px',
          fontSize: '16px',
          color: '#384764',
        }}
      >
        Detalles
      </h3>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontWeight: '500',
                color: `${theme.palette.tableTitle.main}`,
                paddingTop: '20px',
                paddingRight: '50px',
                whiteSpace: 'nowrap',
              }}
              align="left"
            >
              TIPO DE CAMBIO
            </TableCell>
            <TableCell
              sx={{
                fontWeight: '500',
                color: `${theme.palette.tableTitle.main}`,
                paddingTop: '20px',
                paddingRight: '50px',
              }}
              align="left"
            >
              COMISION
            </TableCell>
            <TableCell
              sx={{
                fontWeight: '500',
                color: `${theme.palette.tableTitle.main}`,
                paddingTop: '20px',
                paddingRight: '50px',
                whiteSpace: 'nowrap',
              }}
              align="left"
            >
              ANOMALÍA ENTRADA
            </TableCell>
            <TableCell
              sx={{
                fontWeight: '500',
                color: `${theme.palette.tableTitle.main}`,
                paddingTop: '20px',
                paddingRight: '50px',
                whiteSpace: 'nowrap',
              }}
              align="left"
            >
              ANOMALÍA SALIDA
            </TableCell>
            <TableCell
              sx={{
                fontWeight: '500',
                color: `${theme.palette.tableTitle.main}`,
                paddingTop: '20px',
                paddingRight: '50px',
              }}
            >
              DELIVERY
            </TableCell>
            <TableCell
              sx={{
                fontWeight: '500',
                color: `${theme.palette.tableTitle.main}`,
                paddingTop: '20px',
                paddingRight: '50px',
              }}
            >
              COMENTARIOS
            </TableCell>

            <TableCell
              sx={{
                fontWeight: '500',
                color: `${theme.palette.tableTitle.main}`,
                paddingTop: '20px',
                paddingRight: '50px',
              }}
            >
              LIQUIDADOR
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              align="left"
              sx={{
                color: `${theme.palette.tableText.main}`,
                fontWeight: '500',
                borderBottom: 'none',
              }}
            >
              {exchange_rate ? formatNumber(exchange_rate) : '---'}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                color: `${theme.palette.tableText.main}`,
                fontWeight: '500',
                borderBottom: 'none',
              }}
            >
              {commission ? formatNumber(commission) : '---'}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                color: `${theme.palette.tableText.main}`,
                fontWeight: '500',
                borderBottom: 'none',
              }}
            >
              {inputAnomalie.length !== 0
                ? ` ${formatNumber(inputAnomalie[0].amount)} ${input_currency}`
                : ' ---'}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                color: `${theme.palette.tableText.main}`,
                fontWeight: '500',
                borderBottom: 'none',
              }}
            >
              {outputAnomalie.length !== 0
                ? ` ${formatNumber(
                    outputAnomalie[0].amount,
                  )} ${output_currency}`
                : ' ---'}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                color: `${theme.palette.tableText.main}`,
                fontWeight: '500',
                borderBottom: 'none',
              }}
            >
              {assignedDelivery?.name
                ? assignedDelivery.name.toUpperCase()
                : '---'}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                color: `${theme.palette.tableText.main}`,
                fontWeight: '500',
                borderBottom: 'none',
              }}
            >
              {comments ? comments : '---'}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                color: `${theme.palette.tableText.main}`,
                fontWeight: '500',
                borderBottom: 'none',
              }}
            >
              {liquidatorValue?.name && liquidatorValue?.name !== 'undefined'
                ? `${liquidatorValue?.name} ${liquidatorValue?.email}`
                : '---'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default CollapseTable;
