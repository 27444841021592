export const roles = [
  {
    name: 'Superadmin',
    value: 'superadmin',
  },
  {
    name: 'Operador USDT',
    value: 'operador_usdt',
  },
  {
    name: 'Operador Logistica',
    value: 'operador_logistica',
  },
  {
    name: 'Liquidador Caja',
    value: 'liquidador_caja',
  },
  {
    name: 'Liquidador Delivery',
    value: 'liquidador_delivery',
  },
  {
    name: 'Tesoreria',
    value: 'tesoreria',
  },
  {
    name: 'Operador PESOS',
    value: 'operador_pesos',
  },
  {
    name: 'Operador Cable',
    value: 'operador_cable',
  },
  {
    name: 'Operador Cambio',
    value: 'operador_cambio',
  },
];

export const rolesForRoutes = {
  superadmin: 'superadmin',
  operador_usdt: 'operador_usdt',
  operador_logistica: 'operador_logistica',
  liquidador_caja: 'liquidador_caja',
  liquidador_delivery: 'liquidador_delivery',
  tesoreria: 'tesoreria',
  operador_pesos: 'operador_pesos',
  operador_cable: 'operador_cable',
  operador_cambio: 'operador_cambio',
};

export const operationTypes = () => {
  const { role } = JSON.parse(localStorage.getItem('user'));

  const operationTypes = [
    'COMP.VTA.BILLETE', //Compra y Venta de billetes
    'COMP.VTA.USDT', //Compra y Venta de USDT
    'ENV.RECP.TRANSF.PES/EFV', //Envio y recepción de transferencia de pesos contra efectivo
    'ENV.RECP.TRANSF.PES', //Envio y recepción de transferencias de pesos
    'ENV.RECP.TRANSF.USD', //Envio y recepción de transferencias de dólares
    'ENV.RECP.TRANSF.USD/EFV', //Envio y recepción de transferencia de dólares contra efectivo
    'RECP.CHEQU.PES/EFV', //Recepción de cheques en pesos contra efectivo
    'INT.OP.TER/EFV', //Intermediación en operaciones de terceros contra efectivo
    'PAGO SERV/PROV', //Pago de servicios / Pago a proveedores
    'PAGO SUELDOS', //Pago de sueldos
    'LIQ.COMISIONES', //Liquidacion de comisiones
    'INGRESO CTA.CORRIENTE', //Ingreso cuenta corriente Cliente
    'EGRESO CTA.CORRIENTE',
    'RETIRO POS.DELIVERY', //Retiro posicion Liq. delivery
    'INGRESO POS.DELIVERY',
  ];

  if (role === 'superadmin') return operationTypes;
  else if (role === 'operador_usdt') {
    return operationTypes.filter(
      (o) => o === 'COMP.VTA.BILLETE' || o === 'COMP.VTA.USDT',
    );
  } else if (role === 'operador_pesos') {
    return operationTypes.filter(
      (o) =>
        o === 'ENV.RECP.TRANSF.PES/EFV' ||
        o === 'ENV.RECP.TRANSF.PES' ||
        o === 'RECP.CHEQU.PES/EFV',
    );
  } else if (role === 'operador_cable') {
    return operationTypes.filter(
      (o) =>
        o === 'COMP.VTA.BILLETE' ||
        o === 'ENV.RECP.TRANSF.USD' ||
        o === 'ENV.RECP.TRANSF.USD/EFV',
    );
  } else if (role === 'operador_cambio') {
    return operationTypes.filter((o) => o === 'COMP.VTA.BILLETE');
  } else if (role === 'operador_logistica') {
    return operationTypes.filter(
      (o) =>
        o === 'COMP.VTA.BILLETE' ||
        o === 'RETIRO POS.DELIVERY' ||
        o === 'INGRESO POS.DELIVERY',
    );
  } else if (role === 'tesoreria') {
    return operationTypes.filter(
      (o) =>
        o === 'LIQ.COMISIONES' ||
        o === 'INGRESO CTA.CORRIENTE' ||
        o === 'EGRESO CTA.CORRIENTE',
    );
  }

  return operationTypes;
};

export const operationStatus = [
  'Pendiente',
  'Confirmado LYM',
  'Confirmado Cliente',
  'Confirmado Delivery',
  'Liquidado',
  'Finalizado',
];

export const selectOperationsOptions = [
  { name: 'ESTADO', value: 'Estado' },
  { name: 'Pendiente', value: 'Pendiente' },
  { name: 'Confirmado LYM', value: 'Confirmado LYM' },
  { name: 'Confirmado Cliente', value: 'Confirmado Cliente' },
  { name: 'Liquidado', value: 'Liquidado' },
  { name: 'Finalizado', value: 'Finalizado' },
];

export const operationTableTitles = [
  { name: '', width: '0%', paddingLeft: '4px', paddingRight: '0px' },
  { name: 'checkbox', width: '40px', paddingLeft: '0px', paddingRight: '4px' },
  { name: 'FECHA', width: '102px', paddingLeft: '8px', paddingRight: '8px' },
  { name: 'ID', width: '96px', paddingLeft: '8px', paddingRight: '8px' },
  {
    name: 'DIVISA DE ENTRADA',
    width: '168px',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  {
    name: 'MONTO DE ENTRADA',
    width: '168px',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  {
    name: 'DIVISA DE SALIDA',
    width: '154px',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  {
    name: 'MONTO DE SALIDA',
    width: '154px',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  {
    name: 'TIPO DE OP.',
    width: '180px',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  {
    name: 'LIQUIDADOR',
    width: '136px',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  { name: 'ESTADO', width: '186px', paddingLeft: '8px', paddingRight: '8px' },
  { name: 'DESTINO', width: '114px', paddingLeft: '8px', paddingRight: '8px' },
  { name: '  ', width: '80px', paddingLeft: '0px', paddingRight: '0px' },
];
