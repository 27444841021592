import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { ClientTableCell } from '../../containers/Clients/clientStyles';
import useClients from '../../containers/Clients/useClients';
import { useNavigate } from 'react-router-dom';
import {
  InputSearch,
  SearchContainer,
} from '../../components/Searcher/searcherStyles';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '../../img/refresh.png';
import ClientsIndividual from '../../components/Clients/ClientsIndividual';
import ClientsHead from './ClientsHead';
import Paginate from '../../components/Paginate/Paginate';
import Searcher from '../Searcher/Searcher';

const ClientsTable = ({
  clients,
  page,
  search,
  setSearch,
  setPage,
  loading,
  getClientsPaginate,
  deleteClient,
  totalPages,
}) => {
  const navigate = useNavigate();

  return (
    <div>
      <Searcher
        setSearch={setSearch}
        search={search}
        context="clients"
        getClientsPaginate={getClientsPaginate}
        setPage={setPage}
      ></Searcher>

      {!clients.length && !loading ? (
        <h2 style={{ textAlign: 'center', marginBottom: '7rem' }}>
          No hay registros de clientes
        </h2>
      ) : (
        <TableContainer>
          <Table
            sx={{
              width: '100%',
              margin: 'auto',
            }}
          >
            <ClientsHead></ClientsHead>

            <TableBody>
              {clients.map((client) => (
                <ClientsIndividual
                  client={client}
                  deleteClient={deleteClient}
                  search={search}
                  page={page}
                />
              ))}
            </TableBody>
          </Table>
          <Paginate
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            search={search}
            getFunction={getClientsPaginate}
          />
        </TableContainer>
      )}
    </div>
  );
};

export default ClientsTable;
