import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import useUsers from '../../containers/Team/useUsers';
import Searcher from '../../components/Searcher/Searcher';
import UserIndividual from '../../components/Team/UserIndividual';
import UsersHead from './UsersHead';

const Users = ({
  getUsers,
  users,
  deleteUser,
  userForSearch,
  setUsers,
  loading,
}) => {
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <TableContainer>
      <Searcher
        context="users"
        userForSearch={userForSearch}
        setUsers={setUsers}
        getUsers={getUsers}
      />
      {!users?.length && !loading ? (
        <h2 style={{ textAlign: 'center' }}>No hay registros de anomalias</h2>
      ) : (
        <Table
          sx={{
            width: '100%',
            margin: 'auto',
          }}
        >
          <UsersHead />
          <TableBody>
            {users?.map((user) => (
              <UserIndividual user={user} deleteUser={deleteUser} />
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default Users;
