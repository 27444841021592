import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import { BrowserView, MobileView } from 'react-device-detect';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import Logo from '../../../components/Logo/Logo';
import Icon from '../../../components/Icon/Icon';
import ConfigIcon from '../../../img/configuraciones.png';
import CustomerIcon from '../../../img/customer.png';
import MotoIcon from '../../../img/motorcycle.png';
import GraphIcon from '../../../img/graph.png';
import WalletIcon from '../../../img/wallet.png';
import AnomalyIcon from '../../../img/anomaly.png';

const Sidebar = ({ window }) => {
  const { pathname } = useLocation();

  const { id } = useParams();

  const canAccess = {
    superadmin: [
      '/stock',
      '/operaciones',
      '/logistica',
      '/anomalias',
      '/clientes',
      `/clientes/${id}`,
      '/equipo',
      '/agregar-usuario',
      `/editar-usuario/`,
      '/agregar-divisa',
      '/editar-cliente/',
      '/agregar-clientes',
      '/agregar-operacion',
    ],
    operador_usdt: ['/stock', '/clientes', '/operaciones'],
    operador_pesos: ['/clientes', '/operaciones', '/stock'],
    operador_cable: ['/clientes', '/stock', '/operaciones'],
    operador_cambio: ['/clientes', '/stock', '/operaciones'],
    operador_logistica: ['/logistica', '/operaciones'],
    liquidador_caja: ['/operaciones'],
    tesoreria: ['/operaciones', '/anomalias', '/stock', '/clientes'],
    liquidador_delivery: [],
  };

  const getUserFromLocalStorage = () => {
    const user = localStorage.getItem('user');
    return JSON.parse(user);
  };

  const user = getUserFromLocalStorage();

  const userRole = user?.role;

  const setSelectedToLocalStorage = (index) => {
    localStorage.setItem('selected', index);
  };

  const navigate = useNavigate();

  const routes = useMemo(
    () => [
      {
        title: 'Stock',
        path: '/stock',
        icon: <Icon path={GraphIcon} />,
      },
      {
        title: 'Operaciones',
        path: '/operaciones',
        icon: <Icon path={ConfigIcon} />,
      },
      {
        title: 'Logística',
        path: '/logistica',
        icon: <Icon path={MotoIcon} />,
      },
      {
        title: 'Anomalías',
        path: '/anomalias',
        icon: <Icon path={AnomalyIcon} />,
      },
      {
        title: 'Clientes',
        path: '/clientes',
        icon: <Icon path={WalletIcon} />,
      },
      {
        title: 'Equipo',
        path: '/equipo',
        icon: <Icon path={CustomerIcon} />,
      },
    ],
    [],
  );

  const allowedPaths = canAccess[userRole];
  const filteredRoutes = routes?.filter((r) => allowedPaths?.includes(r.path));

  const [selected, setSelected] = useState(
    filteredRoutes?.filter((r, i) => {
      if (r.path === pathname) return i;
    }),
  );

  useEffect(() => {
    filteredRoutes?.filter((r, i) => {
      if (r.path === pathname) setSelected(i);
    });
  }, []);

  const handleSelect = (index) => {
    setSelected(index);
    setSelectedToLocalStorage(index);
    const routesFiltered = filteredRoutes?.filter((x) => !x.hidden);
    navigate(routesFiltered[index].path);
  };

  const drawer = (
    <>
      <BrowserView>
        <Box
          component="div"
          style={{
            paddingLeft: '16px',
            paddingRight: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 5,
            }}
          >
            <Logo />
          </Box>
          <Divider
            sx={{
              backgroundColor: '#80808060',
              width: '90%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
          <List>
            {filteredRoutes
              .filter((x) => !x.hidden)
              .map(({ title, icon }, i) => (
                <ListItemButton
                  key={title}
                  selected={selected === i}
                  onClick={() => {
                    handleSelect(i);
                  }}
                  sx={{
                    marginTop: '10px',
                    '&.Mui-selected': {
                      backgroundColor: '#D43436',
                      borderRadius: '10px',
                    },
                    ':hover': {
                      backgroundColor: '#80808060',
                      borderRadius: '10px',
                    },
                  }}
                >
                  {icon}
                  <ListItemText
                    primary={title}
                    sx={{
                      color: 'white',
                    }}
                  />
                </ListItemButton>
              ))}
          </List>
        </Box>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          {/* <MenuList /> */}
          {/* <MenuCard /> */}
        </Box>
      </MobileView>
    </>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: 'auto' }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant={'permanent'}
        anchor="left"
        sx={{
          '& .MuiDrawer-paper': {
            width: '254px',
            height: '90%',
            top: '50px',
            borderRadius: '10px',
            background: 'black',
            borderRight: 'none',
            marginLeft: '10px',
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
