import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Loader from '../../../components/Loader/Loader';
import theme from '../../../theme';
import { operationTypes } from '../../../utils/constants';
import formatNumber from '../../../utils/formatNumber';
import useUsers from '../../Team/useUsers';
import {
  BottomOperationFormContainer,
  ClientBalanceContainer,
  SubOperationFormContainer,
} from '../operationsStyles';
import { CurrenciesInput } from './CurrenciesInput';
import useAuth from '../../Login/useAuth';

const MainForm = ({
  handleInputChange,
  operation,
  stock,
  usersDelivery,
  selectedUser,
  setSelectedUser,
  clients,
  selectedClient,
  setSelectedClient,
  inputAnomalie,
  outputAnomalie,
  context,
  setOperation,
  setCurrencyChecked,
  currencyChecked,
  errors,
}) => {
  const [loading, setLoading] = useState(true);
  const user = useAuth();
  const { getProfile, profile } = useUsers();

  useEffect(() => {
    getProfile(user?.id);
  }, []);

  useEffect(() => {
    if (context !== 'edit') return setLoading(false);
    if (selectedClient === undefined || selectedClient === null) return;
    if (operation.liquidator_type === 'DELIVERY') {
      if (selectedUser === undefined || selectedUser === null) return;
    }
    setLoading(false);
  }, [selectedClient, selectedUser]);

  const handleSelectCurrencyAccount = (e) => {
    if (e.target.checked) {
      setCurrencyChecked(JSON.parse(e.target.value));
    } else {
      setCurrencyChecked(null);
    }
    setOperation({
      ...operation,
      input_currency: JSON.parse(e.target.value).currency.id,
      useCurrentAccount: true,
    });
  };

  return (
    <>
      {loading ? (
        <Loader
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            marginTop: '50px',
          }}
        />
      ) : (
        <>
          <SubOperationFormContainer>
            {/* TIPO DE OPERACION */}
            <FormControl
              variant="standard"
              fullWidth
              disabled={profile?.role === 'liquidador_caja' ? true : false}
            >
              <InputLabel
                sx={{ color: `${theme.palette.inputText.main}` }}
                id="input"
              >
                Tipo de Operacion
              </InputLabel>
              <Select
                labelId="input"
                name="operation_type"
                onChange={(event) => handleInputChange(event)}
                value={operation.operation_type}
              >
                <ListSubheader>OPERACIONES FINANCIERAS</ListSubheader>
                {operationTypes().map((o, i) => {
                  if (i > 7) return null;
                  return <MenuItem value={o}>{o}</MenuItem>;
                })}
                {context === 'edit' ? null : (
                  <ListSubheader>OPERACIONES INSTITUCIONALES</ListSubheader>
                )}
                {operationTypes().map((o, i) => {
                  if (context === 'edit') return;
                  if (i < 8) return null;
                  return <MenuItem value={o}>{o}</MenuItem>;
                })}
              </Select>
            </FormControl>

            {/* {CLIENTES} */}
            {clients.length > 0 ? (
              <Autocomplete
                fullWidth
                disabled={profile?.role === 'liquidador_caja' ? true : false}
                options={clients}
                getOptionLabel={(client) => client.name}
                value={selectedClient}
                renderInput={(params) => (
                  <TextField
                    error={errors.client}
                    {...params}
                    label="Clientes"
                    variant="standard"
                    InputLabelProps={{
                      style: {
                        color: `${theme.palette.inputText.main}`,
                      },
                    }}
                    sx={{
                      input: {
                        color: `${theme.palette.inputText.main}`,
                      },
                      '& .MuiInputLabel-root': { color: '#D3D6DA' },
                    }}
                  />
                )}
                onChange={(event, value) => {
                  setSelectedClient(value);
                  setOperation({
                    ...operation,
                    client: value,
                    address: value.address,
                  });
                }}
              />
            ) : null}
          </SubOperationFormContainer>
          {selectedClient && context !== 'edit' ? (
            <ClientBalanceContainer>
              <h3 style={{ margin: '0' }}>
                Cuenta corriente de {selectedClient.name}
              </h3>
              <div style={{ display: 'flex' }}>
                {selectedClient.currentAccount.map((c) => (
                  <div>
                    <span>
                      {c.currency.name} {formatNumber(c.amount)}
                    </span>
                    <Checkbox
                      value={JSON.stringify(c)}
                      onChange={handleSelectCurrencyAccount}
                      sx={{
                        color: 'gray',
                        '&.Mui-checked': {
                          color: 'black',
                        },
                      }}
                      checked={currencyChecked?.currency?.id === c.currency.id}
                    />
                  </div>
                ))}
              </div>
              {selectedClient.currentAccount.length === 0 ? (
                <span style={{ fontSize: '20px' }}>
                  El cliente seleccionado no posee ningun saldo
                </span>
              ) : (
                <span>
                  Se creara automaticamente una anomalia a favor si el saldo es
                  inferior al monto de entrada
                </span>
              )}
            </ClientBalanceContainer>
          ) : null}
          <CurrenciesInput
            handleInputChange={handleInputChange}
            stock={stock}
            operation={operation}
            setOperation={setOperation}
            setCurrencyChecked={setCurrencyChecked}
            errors={errors}
          />
          <SubOperationFormContainer>
            {/* COMISION */}
            <TextField
              label="Comisión"
              variant="standard"
              type="number"
              name="commission"
              disabled={profile?.role === 'liquidador_caja' ? true : false}
              onChange={(event) => handleInputChange(event)}
              InputLabelProps={{
                style: { color: `${theme.palette.inputText.main}` },
              }}
              sx={{
                input: {
                  color: `${theme.palette.inputText.main}`,
                },
                '& .MuiInputLabel-root': { color: '#D3D6DA' },
                borderBottom: '1px solid #D3D6DA',
              }}
              InputProps={{ disableUnderline: true }}
              fullWidth
              value={operation.commission}
            />

            {/* LIQUIDADOR */}
            <FormControl
              variant="standard"
              fullWidth
              error={errors.liquidator_type}
            >
              <InputLabel
                sx={{ color: `${theme.palette.inputText.main}` }}
                id="liquidador"
              >
                Liquidador
              </InputLabel>
              <Select
                labelId="liquidador"
                name="liquidator_type"
                onChange={(event) => handleInputChange(event)}
                value={operation.liquidator_type}
                disabled={profile?.role === 'liquidador_caja' ? true : false}
              >
                <MenuItem value="CAJA A">CAJA A</MenuItem>
                <MenuItem value="CAJA C">CAJA C</MenuItem>
                <MenuItem value="DELIVERY">DELIVERY</MenuItem>
              </Select>
            </FormControl>
          </SubOperationFormContainer>

          <SubOperationFormContainer>
            {/* DELIVERY */}
            <Autocomplete
              fullWidth
              options={usersDelivery}
              getOptionLabel={(usersDelivery) => usersDelivery.name}
              value={selectedUser}
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField
                  error={errors.assignedDelivery}
                  {...params}
                  label="Deliverys"
                  variant="standard"
                  fullWidth={true}
                  InputLabelProps={{
                    style: { color: `${theme.palette.inputText.main}` },
                  }}
                  sx={{
                    input: {
                      color: `${theme.palette.inputText.main}`,
                    },
                    '& .MuiInputLabel-root': { color: '#D3D6DA' },
                  }}
                />
              )}
              onChange={(event, value) => {
                setSelectedUser(value);
                setOperation({ ...operation, assignedDelivery: value });
              }}
              style={{
                visibility: `${
                  operation.liquidator_type === 'DELIVERY'
                    ? 'visible'
                    : 'hidden'
                }`,
              }}
            />
            {/* DIRECCION */}
            <TextField
              error={errors.address}
              label="Dirección"
              variant="standard"
              type="text"
              name="address"
              fullWidth={true}
              onChange={(event) => handleInputChange(event)}
              style={{
                visibility: `${
                  operation.liquidator_type === 'DELIVERY'
                    ? 'visible'
                    : 'hidden'
                }`,
              }}
              InputLabelProps={{
                style: { color: `${theme.palette.inputText.main}` },
              }}
              sx={{
                input: {
                  color: `${theme.palette.inputText.main}`,
                },
                '& .MuiInputLabel-root': { color: '#D3D6DA' },
                borderBottom: '1px solid #D3D6DA',
              }}
              InputProps={{ disableUnderline: !errors.address }}
              value={operation.address}
            />
          </SubOperationFormContainer>

          <BottomOperationFormContainer>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
                gap: '30px',
              }}
            >
              {/* ANOMALIA ENTRADA */}
              <TextField
                variant="standard"
                type="number"
                name="input_anomaly"
                label="Anomalía de entrada"
                onChange={(event) => handleInputChange(event)}
                fullWidth={true}
                InputLabelProps={{
                  style: { color: `${theme.palette.inputText.main}` },
                }}
                sx={{
                  input: {
                    color: `${theme.palette.inputText.main}`,
                  },
                  '& .MuiInputLabel-root': { color: '#D3D6DA' },
                  borderBottom: '1px solid #D3D6DA',
                  visibility: `${currencyChecked ? 'hidden' : 'visible'}`,
                }}
                InputProps={{ disableUnderline: true }}
                defaultValue={inputAnomalie ? inputAnomalie[0]?.amount : ''}
              />

              {/* ANOMALIA SALIDA */}
              <TextField
                variant="standard"
                type="number"
                name="output_anomaly"
                label="Anomalía de salida"
                onChange={(event) => handleInputChange(event)}
                fullWidth={true}
                InputLabelProps={{
                  style: { color: `${theme.palette.inputText.main}` },
                }}
                sx={{
                  input: {
                    color: `${theme.palette.inputText.main}`,
                  },
                  '& .MuiInputLabel-root': { color: '#D3D6DA' },
                  borderBottom: '1px solid #D3D6DA',
                }}
                InputProps={{ disableUnderline: true }}
                defaultValue={outputAnomalie ? outputAnomalie[0]?.amount : ''}
              />
            </div>

            {/* COMENTARIOS */}
            <TextField
              InputLabelProps={{
                style: { color: `${theme.palette.inputText.main}` },
              }}
              sx={{
                input: {
                  color: `${theme.palette.inputText.main}`,
                },
                '& .MuiInputLabel-root': { color: '#D3D6DA' },
                borderBottom: '1px solid #D3D6DA',
              }}
              InputProps={{ disableUnderline: true }}
              variant="standard"
              type="text"
              name="comments"
              label="Comentarios"
              onChange={(event) => handleInputChange(event)}
              fullWidth={true}
              value={operation.comments}
            />
          </BottomOperationFormContainer>
        </>
      )}
    </>
  );
};

export default MainForm;
