import React from 'react';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';
import useStock from './useStock';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '../../components/Button/Button';
import {
  BoxMui,
  BoxTitle,
  BtnContainer,
  AddStockContainer,
} from './stockStyles';
import CancelButton from '../../components/Button/CancelButton';
import themeMui from '../../theme';

const EditCurrency = () => {
  const id = window.location.pathname.split('/')[2];
  const { getCurrency, updateCurrency, currency, errors } = useStock();
  const navigate = useNavigate();

  const [currencyEdited, setCurrencyEdited] = React.useState({
    currency: null,
    exchange_rate: null,
    initial_balance: null,
  });

  const handleGetCurrency = async () => {
    await getCurrency(id);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrencyEdited({ ...currency, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateCurrency(id, currencyEdited);
  };

  React.useEffect(() => {
    handleGetCurrency();
    setCurrencyEdited({
      currency: currency?.currency,
      exchange_rate: currency?.exchange_rate,
      initial_balance: currency?.initial_balance,
    });
  }, [currency?.currency, currency?.exchange_rate, currency?.initial_balance]);

  const theme = createTheme();

  return (
    <>
      <ThemeProvider>
        <AddStockContainer>
          <div style={{ marginRight: '78%' }}>
            <BreadcrumbsMui
              title="Editar divisa"
              prev="Stock"
              path={'/stock'}
            />
          </div>
          <BoxTitle>
            <h1>Editar divisa</h1>
          </BoxTitle>
          <BoxMui component="form" noValidate onSubmit={handleSubmit}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '100px',
                gap: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  gap: '10px',
                }}
              >
                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '50%',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  required
                  helperText="Divisa"
                  autoFocus
                  name="currency"
                  variant="standard"
                  onChange={handleChange}
                  error={errors?.currency}
                  value={currencyEdited.currency}
                />

                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '50%',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  required
                  variant="standard"
                  helperText="Tipo de cambio"
                  name="exchange_rate"
                  onChange={handleChange}
                  type="number"
                  step="0.01"
                  error={errors?.exchange_rate}
                  value={currencyEdited.exchange_rate}
                />
              </div>

              <TextField
                sx={{
                  input: {
                    color: `${themeMui.palette.inputText.main}`,
                  },
                  width: '50%',
                }}
                InputLabelProps={{
                  style: { color: `${themeMui.palette.inputText.main}` },
                }}
                helperText="Balance Inicial"
                variant="standard"
                name="initial_balance"
                value={currencyEdited.initial_balance}
                onChange={handleChange}
                error={errors?.initial_balance}
                type="number"
              />
            </div>

            <BtnContainer>
              <CancelButton
                title="CANCELAR"
                onClick={() => navigate('/stock')}
              />
              <Button type="submit" title="GUARDAR" />
            </BtnContainer>
          </BoxMui>
        </AddStockContainer>
      </ThemeProvider>
    </>
  );
};

export default EditCurrency;
