import styled from 'styled-components';
import theme from '../../theme';

export const BtnSaveDialog = styled.button`
  background: #d43436; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  border: none;
  border-radius: 10px;

  cursor: pointer;
  font-family: 'Roboto', sans-serif !important;
  box-shadow: 0px 3px 3px rgb(179, 171, 171, 0.65);
  transition: 0.3s;
  &:hover {
    box-shadow: none;
  }
  font-size: 11.8px;
  width: 8rem;
  height: 2.6rem;
  align-text: center;
`;

export const BtnCancelDialog = styled.button`
  background-color: #97a0af;
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  font-family: 'Roboto', sans-serif !important;
  box-shadow: 0px 3px 3px rgb(179, 171, 171, 0.65);
  transition: 0.3s;
  &:hover {
    box-shadow: none;
  }
  margin-left: 0.25rem;
  width: 8rem;
  height: 2.6rem;
  align-text: center;
  cursor: pointer;
  font-size: 11.8px;
`;
export const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: 600;
  color: #384764;
  padding: 0 30px;
  margin-top: 1.3rem;
  margin-bottom: 1.2rem;
`;
