import React from 'react';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '../../components/Button/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { roles } from '../../utils/constants';
import {
  BoxMui,
  BoxTitle,
  BtnContainer,
  AddStockContainer,
  SubFormContainer,
  TextFieldMui,
  FormContainer,
} from './userStyles';
import useUsers from './useUsers';
import CancelButton from '../../components/Button/CancelButton';
import themeMui from '../../theme';
import { validateUserForm } from './usersValidation';
import useNotistack from '../Notistack/useNotistack';

const EditUser = () => {
  const id = window.location.pathname.split('/')[2];
  const { getProfile, updateUser, profile, loadingButton } = useUsers();
  const navigate = useNavigate();
  const [userEdited, setUserEdited] = React.useState({
    name: null,
    email: null,
    cellphone: null,
    userRole: null,
    password: '',
    repeatPassword: '',
  });
  const { showNotification } = useNotistack();

  const [errors, setErrors] = React.useState([]);

  console.log('PROFILE: ', profile);

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleRedirect = () => {
    navigate('/equipo');
  };

  const handleUserInfo = async () => {
    await getProfile(id);
  };
  const handleShowErrors = (errors) => {
    errors[0]?.forEach((error) => {
      showNotification(error, 'error');
    });
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setUserEdited({ ...userEdited, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = validateUserForm(userEdited, 'EditUser');
    if (response.valid) {
      updateUser(userEdited, setErrors, id);
    } else {
      setErrors(response);
    }
  };

  React.useEffect(() => {
    handleUserInfo();
    setUserEdited({
      ...userEdited,
      name: profile?.name,
      cellphone: profile?.cellphone,
      email: profile?.email,
      userRole: profile?.role,
    });
  }, [profile?.name, profile?.cellphone, profile?.email, profile?.role]);
  React.useEffect(() => {
    handleShowErrors(errors);
  }, [errors]);

  const theme = createTheme();

  return (
    <>
      <ThemeProvider>
        <AddStockContainer>
          <div style={{ marginRight: '78%' }}>
            <BreadcrumbsMui
              title="Editar usuario"
              prev="Equipo"
              path={'/equipo'}
            />
          </div>
          <BoxTitle>
            <h1>Editar usuario</h1>
          </BoxTitle>
          <BoxMui
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              width: '80%',
              height: '450px',
            }}
          >
            <FormContainer>
              <SubFormContainer>
                <TextFieldMui
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                  }}
                  InputLabelProps={{
                    style: {
                      color: `${themeMui.palette.inputText.main}
                        `,
                    },
                  }}
                  id="firstName"
                  label={userEdited.name === '' ? 'Nombre' : false}
                  autoFocus
                  name="name"
                  step="0.01"
                  variant="standard"
                  onChange={handleChange}
                  error={errors[1]?.name}
                  value={userEdited.name}
                />
                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  fullWidth
                  id="phone"
                  variant="standard"
                  label={userEdited.cellhpone === '' ? 'Teléfono' : false}
                  autoComplete="telefono"
                  name="cellphone"
                  onChange={handleChange}
                  error={errors[1]?.cellphone}
                  value={userEdited.cellphone}
                />
                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  fullWidth
                  label={'Contraseña'}
                  type="password"
                  variant="standard"
                  id="password"
                  autoComplete="new-password"
                  name="password"
                  onChange={handleChange}
                  error={errors[1]?.password}
                  value={userEdited.password}
                />
              </SubFormContainer>

              <SubFormContainer>
                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  InputProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  fullWidth
                  id="email"
                  variant="standard"
                  label={userEdited.email === '' ? 'Email' : false}
                  autoComplete="email"
                  name="email"
                  onChange={handleChange}
                  error={errors[1]?.email}
                  value={userEdited.email}
                />

                <FormControl
                  sx={{
                    width: '100%',
                  }}
                >
                  <InputLabel id="rol-label"></InputLabel>
                  <Select
                    value={userEdited?.userRole}
                    sx={{
                      input: {
                        color: `${themeMui.palette.inputText.main}`,
                      },
                      width: '497px',
                    }}
                    SelectDisplayProps={{
                      style: { color: `${themeMui.palette.inputText.main}` },
                    }}
                    labelId="rol-label"
                    onChange={handleChange}
                    input={
                      <Input
                        sx={{
                          Input: {
                            color: `${themeMui.palette.inputText.main}`,
                          },
                        }}
                        label="Rol"
                      />
                    }
                    name="userRole"
                    error={errors[1]?.role}
                  >
                    {roles.map((r) => (
                      <MenuItem
                        key={r.value}
                        value={r.value}
                        style={getStyles(r.name, r.name, theme)}
                      >
                        {r.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  fullWidth
                  id="repeatPassword"
                  variant="standard"
                  label={'Repetir contraseña'}
                  autoComplete="password"
                  name="repeatPassword"
                  onChange={handleChange}
                  type="password"
                  error={errors[1]?.repeatPassword}
                  value={userEdited.repeatPassword}
                />
              </SubFormContainer>
            </FormContainer>
            <BtnContainer>
              <CancelButton
                title="CANCELAR"
                onClick={() => navigate('/equipo')}
                disable={loadingButton}
              />
              <Button type="submit" title="GUARDAR" disable={loadingButton} />
            </BtnContainer>
          </BoxMui>
        </AddStockContainer>
      </ThemeProvider>
    </>
  );
};

export default EditUser;
