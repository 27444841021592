import styled from 'styled-components';

export const InputSearch = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #d2d6d9;
  color: #d2d6d9;
  width: 200px;
  &:focus {
    outline-color: #ccc;
  }
  &::placeholder {
    color: #d2d6d9;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  margin-top: 8px;
  margin-bottom: 35px;
  padding-right: 30px;
`;
