import { createTheme, ThemeProvider } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#f25456',
    },
    secondary: {
      main: '#11cb5f',
    },
    mainGradient: {
      main: `-webkit-linear-gradient(
      to left,
      #f35658,
      #d43436
    )`,
    },
  },
});
