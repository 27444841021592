import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useStock from './useStock';
import useHistory from '../History/useHistory';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Button/Button';
import {
  LeftButtonsContainer,
  RightButtonsContainer,
  ButtonsContainer,
  StockContainer,
  StockTitle,
  StockTableContainer,
  TotalBalance,
  TotalContainer,
} from './stockStyles';

import Searcher from '../../components/Searcher/Searcher';
import ButtonOutline from '../../components/Button/ButtonOutline';
import StockTable from '../../components/Stock/StockTable';
import { CSVLink } from 'react-csv';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import theme from '../../theme';
import RefreshIcon from '../../img/refresh.png';
import SelectMui from '../../components/Select/SelectMui';
import formatNumber from '../../utils/formatNumber';
import CloseIcon from '@mui/icons-material/Close';

const Stock = () => {
  const navigate = useNavigate();
  const {
    getStock,
    stock,
    setStock,
    desativateCurrency,
    loading,
    setLoading,
    stockForSearch,
    setStockForSearch,
  } = useStock();
  const { getHistoryByDate } = useHistory();

  const checkToday = () => {
    if (date === new Date().toISOString().split('T')[0]) {
      return true;
    } else {
      return false;
    }
  };

  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [flag, setFlag] = useState('opening');
  const [data, setData] = useState('real');
  const [filterTrash, setFilterTrash] = useState(false);

  const addUpInitialBalances = () => {
    let total = 0;
    stock?.map((currency) => {
      total = total + currency.actual_balance / currency.exchange_rate;
      currency.anomalie.map((anomalie) => {
        total = total + anomalie.amount / currency.exchange_rate;
      });
    });

    return formatNumber(total);
  };

  const getTotalAnomalieValue = (currency) => {
    let anomalieValue = 0;
    currency.anomalie.map((a) => {
      if (a.status === 'active' && a.type === 'input')
        anomalieValue += a.amount;
      if (a.status === 'active' && a.type === 'output')
        anomalieValue -= a.amount;
    });

    return anomalieValue;
  };

  const pushData = (stock) => {
    const array = [];
    stock?.map((s) => {
      array.push([
        `${s.currency}`,
        `${s.exchange_rate}`,
        `${s.initial_balance}`,
        `${s.actual_balance}`,
        `${getTotalAnomalieValue(s)}`,
        `${s.actual_balance + getTotalAnomalieValue(s)}`,
      ]);
    });

    return array;
  };

  const handleFormatCsv = () => {
    const csvData = [
      [
        'Divisa',
        'Tipo de cambio',
        'Balance inicial',
        'Balance actual',
        'Anomalías',
        'Balance total',
      ],
      ...pushData(stock),
    ];

    return csvData;
  };

  const handleDateChange = (e) => {
    setDate(e);
  };

  const handleGetHistory = async () => {
    setLoading(true);
    const response = await getHistoryByDate(date);

    if (response) {
      if (flag === 'opening') {
        setStock(response[0].stock);
        setStockForSearch(response[0].stock);
      } else {
        setStock(response[1].stock);
        setStockForSearch(response[1].stock);
      }
    } else {
      setStock([]);
    }
    setLoading(false);
  };

  const dateTodayAt8 = new Date().setHours(8, 0, 0, 0);
  const dateTodayAt21 = new Date().setHours(21, 0, 0, 0);

  const check8am = () => {
    if (new Date() > dateTodayAt8) {
      if (new Date() > dateTodayAt21) return false;
      return true;
    } else {
      return false;
    }
  };

  const check9pm = () => {
    if (new Date() > dateTodayAt21) {
      return true;
    } else {
      return false;
    }
  };

  const handleSelectChange = (e) => {
    const value = e;
    if (value === 'actual') return getStock();
    if (value === 'openingToday') return handleGetHistory();
    setFlag(value);
    setFilterTrash(true);
  };

  useEffect(() => {
    if (date === new Date().toISOString().split('T')[0]) {
      getStock();
    } else {
      handleGetHistory();
    }
  }, [date, flag]);

  const popperSx = {
    '& .MuiPickersDay-today': {
      backgroundColor: '#F25456 !important',
      color: 'white',
      transition: 'none !important',
      border: '1px solid #F25456',
    },

    '& .Mui-selected': {
      backgroundColor: '#F25456 !important',
      transition: 'none',
      color: 'white',

      '&:hover': {
        backgroundColor: '#F25456 !important',
        color: 'white',
      },

      '&:focus': {
        backgroundColor: '#F25456 !important',
        color: 'white',
      },

      '&:active': {
        backgroundColor: '#F25456 !important',
        color: 'white',
      },
    },
  };

  const handleRefresh = () => {
    setDate(new Date().toISOString().split('T')[0]);
    if (checkToday()) {
      if (check8am()) {
        setFlag('actual');
        getStock('real');
      } else {
        setFlag('opening');
      }
    } else {
      setFlag('opening');
    }

    setData('real');
    setFilterTrash(false);
  };

  function getOptions() {
    if (checkToday()) {
      if (check8am()) {
        return [
          { name: 'ACTUAL', value: 'actual' },
          { name: 'APERTURA', value: 'openingToday' },
        ];
      } else if (check9pm()) {
        return [
          { name: 'APERTURA', value: 'opening' },
          { name: 'CIERRE', value: 'closing' },
        ];
      } else {
        return null;
      }
    } else {
      return [
        { name: 'APERTURA', value: 'opening' },
        { name: 'CIERRE', value: 'closing' },
      ];
    }
  }

  const options = getOptions();

  return (
    <StockContainer>
      {console.log(filterTrash)}
      <StockTitle>Stock</StockTitle>
      <ButtonsContainer>
        <LeftButtonsContainer>
          <Button
            onClick={() => navigate('/agregar-divisa')}
            title="NUEVA DIVISA"
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              value={date}
              OpenPickerButtonProps={{
                style: {
                  color: theme.palette.title.main,
                },
              }}
              disableMaskedInput={true}
              disableFuture={true}
              onChange={(newValue) => {
                setFilterTrash(true);
                handleDateChange(
                  new Date(newValue.$d).toISOString().split('T')[0],
                );
              }}
              PopperProps={{
                sx: popperSx,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    width: `${stock?.length === 0 ? '42%' : '30%'}`,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                      border: `1.5px solid ${theme.palette.title.main}`,
                      height: '40px',
                      margin: 0,
                      color: theme.palette.title.main,
                      fontWeight: '500',
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
          {stock?.length > 0 ? (
            <SelectMui
              date={date}
              context="stock-history"
              handleSelectChange={handleSelectChange}
              setFilterTrash={setFilterTrash}
              options={options}
            />
          ) : null}

          {filterTrash ? (
            <CloseIcon
              alt=""
              onClick={handleRefresh}
              sx={{ margin: '0.5rem', cursor: 'pointer', color: '#3f5071' }}
            />
          ) : null}
        </LeftButtonsContainer>
        <RightButtonsContainer>
          {date !== new Date().toISOString().split('T')[0] ? null : (
            <SelectMui
              context="stock-time"
              onChangeFunction={getStock}
              setFilterTrash={setFilterTrash}
              data={data}
              setData={setData}
              options={[
                { name: 'REAL', value: 'real' },
                { name: 'PROYECTADO', value: 'proyectada' },
              ]}
            />
          )}

          <CSVLink
            data={handleFormatCsv()}
            filename="stock.csv"
            target="_blank"
            separator={';'}
            style={{ textDecoration: 'none' }}
          >
            <ButtonOutline icon="csv" title="EXPORTAR CSV" />
          </CSVLink>
        </RightButtonsContainer>
      </ButtonsContainer>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '150px',
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <StockTableContainer>
            {checkToday() ? null : (
              <h2
                style={{
                  fontSize: '15px',
                  color: theme.palette.title.main,
                  display: 'flex',
                  justifyContent: 'center',
                  fontWeight: '400',
                }}
              >
                Historial de stock: {date.slice(8)}/{date.slice(5, 7)}/
                {date.slice(0, 4)}
              </h2>
            )}
            <Searcher
              context="stock"
              stockForSearch={stockForSearch}
              setStock={setStock}
              getStock={getStock}
              date={date}
            />
            <StockTable
              stock={stock}
              desativateCurrency={desativateCurrency}
              checkToday={checkToday}
              getTotalAnomalieValue={getTotalAnomalieValue}
            />
          </StockTableContainer>
          <TotalContainer>
            <TotalBalance>TOTAL EN USD: ${addUpInitialBalances()}</TotalBalance>
          </TotalContainer>
        </>
      )}
    </StockContainer>
  );
};

export default Stock;

// {stock?.length > 0 ? (
//   <Select onChange={(e) => handleSelectChange(e)}>
//     {checkToday() ? (
//       <>
//         {check8am() ? (
//           <>
//             <option value="actual">ACTUAL</option>
//             <option value="openingToday">APERTURA</option>
//           </>
//         ) : null}

//         {check9pm() ? (
//           <>
//             <option value="opening">APERTURA</option>
//             <option value="closing">CIERRE</option>
//           </>
//         ) : null}
//       </>
//     ) : (
//       <>
//         <option value="opening">APERTURA</option>
//         <option value="closing">CIERRE</option>
//       </>
//     )}
//   </Select>
// ) : null}
