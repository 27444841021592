import React from 'react';
import { Paper, Table, TableContainer } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Operation from './Operation';
import OperationHead from './OperationHead';
import { PaginationContainer } from '../../containers/Operations/operationsStyles';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaginationItem-root': {
      color: '#7C8198',
    },
    '& .Mui-selected': {
      background: `linear-gradient(
        to left,
        #f35658,
        #d43436
      )`,
      border: '0',
      color: '#FFFFFF',
    },
  },
}));

const OperationsTable = ({
  children,
  operations,
  deleteOperation,
  page,
  setPage,
  numberOfPages,
  handleSelectOperation,
  selected,
  loading,
  handleSelectAllOperations,
  checked,
}) => {
  const classes = useStyles();

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: '2px 4px 12px -2px rgba(0, 0, 0, 0.26)',
        borderRadius: '10px',
        height: '750px',

        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      {children}

      <Table aria-label="simple table">
        {!loading && !operations?.length ? (
          <h2 style={{ textAlign: 'center' }}>
            No hay registros de operaciones
          </h2>
        ) : (
          <div>
            <OperationHead
              handleSelectAllOperations={handleSelectAllOperations}
              checked={checked}
            />
            {operations?.map((operation) => (
              <Operation
                key={operation._id}
                operation={operation}
                deleteOperation={() => deleteOperation(operation._id)}
                handleSelectOperation={handleSelectOperation}
                selected={selected}
              />
            ))}
            <PaginationContainer>
              <Stack
                spacing={1000}
                style={{ color: 'red' }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'end',
                  marginTop: '1rem',
                }}
              >
                <Pagination
                  hideNextButton={page === numberOfPages ? true : false}
                  hidePrevButton={page === 1 ? true : false}
                  count={numberOfPages}
                  page={page}
                  size="large"
                  onChange={(e, value) => setPage(value)}
                  color="primary"
                  variant="outlined"
                  classes={{ ul: classes.root }}
                />
              </Stack>
            </PaginationContainer>
          </div>
        )}
      </Table>
    </TableContainer>
  );
};

export default OperationsTable;
