import * as React from 'react';
import PropTypes from 'prop-types';
import SelectUnstyled, {
  selectUnstyledClasses,
} from '@mui/base/SelectUnstyled';
import OptionUnstyled, {
  optionUnstyledClasses,
} from '@mui/base/OptionUnstyled';
import PopperUnstyled from '@mui/base/PopperUnstyled';
import { styled } from '@mui/system';
import RobotoRegular from '../../fonts/Roboto-Regular.ttf';
import RobotoMedium from '../../fonts/Roboto-Thin.ttf';

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const StyledButton = styled('button')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  min-width: 150px;
  padding: 12px;
  border-radius: 12px;
  text-align: left;
  line-height: 1.5;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    cursor: pointer;
  }

  &.${selectUnstyledClasses.focusVisible} {
    border-color: ${blue[400]};
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  background-color: #384764;
  color: white
  }

  &::after {
    content: '▾';
    float: right;
  }
  `,
);

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 250px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0px 4px 30px ${
    theme.palette.mode === 'dark' ? grey[900] : grey[200]
  };
  `,
);

export const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  text-transform: lowercase;
  :first-letter {
    text-transform: uppercase
    }
  list-style: none;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  margin: 10px 3px;
  font-size:14px;
  font-family: ${RobotoMedium};

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color:#edeff2  ;
    color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }


  `,
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const Paragraph = styled('p')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  margin: 10px 0;
  color: ${theme.palette.mode === 'dark' ? grey[400] : grey[700]};
  `,
);

export function CustomSelect(props) {
  const slots = {
    root: StyledButton,
    listbox: StyledListbox,
    popper: StyledPopper,
    ...props.slots,
  };

  return <SelectUnstyled {...props} slots={slots} />;
}

CustomSelect.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  slots: PropTypes.shape({
    listbox: PropTypes.elementType,
    popper: PropTypes.func,
    root: PropTypes.elementType,
  }),
};

export default function SelectMui({
  context,
  options,
  handleSelectChange,
  date,
  onChangeFunction,
  data,
  setData,
  setFilterTrash,
}) {
  const [value, setValue] = React.useState(options[0].value);

  React.useEffect(() => {
    setValue(options[0].value);
  }, [date]);

  if (context === 'stock-history') {
    return (
      <CustomSelect
        sx={{
          border: '1.5px solid #384764',
          color: '#384764',
          borderRadius: '10px',
          padding: '10px 20px',
          marginLeft: '10px',
          background: 'none',
          fontFamily: 'Roboto',
          fontWeight: '500',
        }}
        value={value}
        onChange={(e, newValue) => {
          handleSelectChange(newValue);
          setValue(newValue);
          setFilterTrash(true);
        }}
      >
        {options.map((o) => (
          <StyledOption value={o.value}>{o.name}</StyledOption>
        ))}
      </CustomSelect>
    );
  }
  if (context === 'stock-time') {
    return (
      <CustomSelect
        sx={{
          border: '1.5px solid #384764',
          color: '#384764',
          borderRadius: '10px',
          padding: '10px 20px',
          marginLeft: '10px',
          background: 'none',
          fontFamily: 'Roboto',
          fontWeight: '500',
        }}
        value={data}
        onChange={(e, newValue) => {
          onChangeFunction(newValue);
          setData(newValue);

          setFilterTrash(true);
        }}
      >
        {options.map((o) => (
          <StyledOption value={o.value}>{o.name}</StyledOption>
        ))}
      </CustomSelect>
    );
  }
  return (
    <CustomSelect
      sx={{
        border: '1.5px solid #384764',
        color: '#384764',
        borderRadius: '10px',
        padding: '10px 20px',
        marginLeft: '10px',
        background: 'none',
        fontFamily: 'Roboto',
        fontWeight: '500',
        display: 'grid',
        gridTemplateColumns: '97% 3%',
      }}
      defaultValue={options[0].value}
      onChange={onChangeFunction}
    >
      {options.map((o) => (
        <StyledOption
          sx={
            context === 'logistic' || context === 'operation'
              ? {
                  textTransform: 'none',
                  display: `${o.name === 'ESTADO' ? 'none' : ''}`,
                  ':hover': { backgroundColor: '#eaeef2' },
                }
              : null
          }
          value={o.value}
        >
          <div style={{}}>{o.name}</div>
        </StyledOption>
      ))}
    </CustomSelect>
  );
}
