import { useState } from 'react';
import fetchFromApi from '../../utils/fetchFromApi';
import useNotistack from '../Notistack/useNotistack';
import { useNavigate } from 'react-router-dom';

const useClients = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingButtons, setLoadingButtons] = useState(false);

  const { showNotification } = useNotistack();
  const [clientInfo, setClientInfo] = useState([]);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [search, setSearch] = useState();
  const [clientsCsv, setClientsCsv] = useState([]);

  const getClients = async () => {
    try {
      let result = await fetchFromApi('GET', `client/`);
      setClients(result);
    } catch (error) {
      showNotification('Error al obtener los clientes.', 'error');
    }
  };

  const getClientsPaginate = async (search, page) => {
    setLoading(true);
    try {
      let result = await fetchFromApi(
        'GET',
        `client/paginate/${page}?search=${search}`,
      );

      if (!result.docs.length && page !== 1) {
        result = await fetchFromApi(
          'GET',
          `client/paginate/${page - 1}?search=${search}`,
        );
      }

      setClients(result.docs);
      setTotalPages(result.totalPages);
      setPage(result.page);
    } catch (error) {
      showNotification('Error al obtener los clientes.', 'error');
    }
    setLoading(false);
  };

  const deleteClient = async (id, search, page) => {
    setLoading(true);
    try {
      const result = await fetchFromApi('PUT', `client/disable/${id}`);
      if (result) {
        showNotification(result[1], 'success');
      }

      getClientsPaginate(search, page);
    } catch (error) {
      showNotification('Cliente no eliminado.', 'error');
    }
  };

  const getClientInfo = async (id) => {
    setLoading(true);
    try {
      const response = await fetchFromApi('GET', `client/info/${id}`);
      if (response) {
        setClientInfo(response);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleRedirect = () => {
    navigate('/clientes');
  };

  const AddNewClient = async (clientData, setErrors) => {
    setLoadingButtons(true);
    try {
      const response = await fetchFromApi('POST', 'client', clientData);
      showNotification(response[1], 'success');
      setErrors([]);
      handleRedirect();
    } catch (error) {
      setErrors(error.response.data);
      setLoadingButtons(false);
    }
  };

  const updateClient = async (clientData, setErrors, id) => {
    setLoadingButtons(true);
    try {
      const response = await fetchFromApi('PUT', `client/${id}`, clientData);
      showNotification(response[1], 'success');
      setErrors([]);
      showNotification('Redirigiendo a clientes...', '');
      handleRedirect();
    } catch (error) {
      setErrors(error.response.data);
      setLoadingButtons(false);
    }
  };

  const getClientsForCsv = async () => {
    try {
      const response = await fetchFromApi('GET', `client/all/csv`);
      if (response) {
        setClientsCsv(response);
      }
    } catch (error) {
      showNotification('Error al obtener los clientes.', 'error');
    }
  };

  return {
    getClients,
    getClientsPaginate,
    clients,
    deleteClient,
    updateClient,
    loading,
    getClientInfo,
    clientInfo,

    setClients,
    AddNewClient,
    loadingButtons,
    setLoadingButtons,
    open,
    setOpen,
    totalPages,
    setPage,
    page,
    setSearch,
    search,

    getClientsForCsv,
    clientsCsv,
  };
};

export default useClients;
