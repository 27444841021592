import { Box } from '@mui/material';
import styled from 'styled-components';
import theme from '../../theme';

export const OperationsContainer = styled.div`
  margin-bottom: 10px;
  width: 100%;
  padding-right: 20px;
  padding-left: 40px;
`;

export const Operation = styled.div`
  background-color: aliceblue;
  text-align: left;
  padding: 30px;
  border-radius: 5px;
`;

export const FilterBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OperationsTitle = styled.h1`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${theme.palette.title.main};
  margin: 0;
  margin-bottom: 20px;
  position: relative;
  bottom: 20px;
  margin-left: 1rem;
`;

export const OperationsButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 40px;
`;

export const OperationsSelectAndDeleteContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  gap: 10px;
  height: 40px;
`;

export const PopopverButtonsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 20px;
  align-items: center;
  justify-content: center;
`;

export const AddOperationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;
`;

export const OperationFormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopOperationFormContainer = styled.div`
  display: flex;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
`;

export const BottomOperationFormContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const SubOperationFormContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 30px;
  margin: 15px 0px;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

export const BoxMui = styled(Box)`
  background-color: #fff;
  border-radius: 10px;
  padding: 70px;
  width: 80%;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
`;

export const BoxTitle = styled.div`
  background: #d43436; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 77%;
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  position: relative;
  top: 50px;
`;

export const BreadcumsContainer = styled.div`
  margin-right: 77%;
  margin-bottom: 20px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 30px 0px;
  margin-right: 30px;
`;

export const CurrenciesInputContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
  padding: 40px 0px;
`;

export const CurrenciesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
`;

export const CurrencyContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid #d3d6da;
  border-radius: 14px;
  height: 50px;
  align-items: center;
`;

export const ExchangeRateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ClientBalanceContainer = styled.div`
  margin: 20px 0px;
`;

export const AllOperationsButton = styled.button`
  border: none;
  cursor: pointer;
  height: 50%;
  font-size: 15px;
  margin-left: 20px;
  color: #f76060;
  background-color: #ffffff;
  padding: 10px;
  &:hover {
    background-color: #f0b4b1;
    border-radius: 5px;
    color: white;
  }
`;

export const CotizationContainer = styled.div`
  display: flex;
  gap: 20px;
  border: 1px solid black;
  padding: 15px;
  margin: 10px 0px;
`;
