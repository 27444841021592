import React, { useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import theme from '../../theme';
import { useNavigate } from 'react-router-dom';
import useAnomalies from '../../containers/Anomalies/useAnomalies';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect } from 'react';
import {
  BtnPayOff,
  BtnSave,
  BtnSaveActive,
} from '../../containers/Anomalies/anomaliesStyles';
import useNotistack from '../../containers/Notistack/useNotistack';
import AlertDialog from '../Dialog/AlertDialog';
import CopyToClipboardButton from '../Button/CopyToClipboard';

const AnomaliesDetails = ({
  anomaly,
  setChangeAmount,
  changeAmount,
  save,
  setSave,
  updateMountAnomaly,
  payOffAnomaly,
  disableAnomaly,
  page,
  search,
}) => {
  const navigate = useNavigate();
  const { showNotification } = useNotistack();

  const [openAlert, setOpenAlert] = useState(false);

  const handlerChangeAmount = (e, id, amount) => {
    const { value } = e.target;
    if (/^[0-9]+$/.test(value) || value === '') {
      setChangeAmount({ ...changeAmount, [id]: value });
    }
  };

  const handlerCancelChangeAmount = () => {
    setSave({ ...save, [anomaly._id]: true });
    setChangeAmount({ ...changeAmount, [anomaly._id]: anomaly.amount });
  };

  const handlerSetSave = () => {
    if (
      Number(changeAmount[anomaly._id]) !== anomaly.amount &&
      changeAmount[anomaly._id] !== ''
    ) {
      setSave({ ...save, [anomaly._id]: false });
    }
    if (changeAmount[anomaly._id] === '') {
      setSave({ ...save, [anomaly._id]: true });
    } else if (Number(changeAmount[anomaly._id]) === anomaly.amount) {
      setSave({ ...save, [anomaly._id]: true });
    }
  };

  const handlerPayOff = () => {
    payOffAnomaly(
      page,
      anomaly._id,
      anomaly.type,
      anomaly.stock[0],
      anomaly.amount,
      search,
    );
  };

  const handlerDisable = () => {
    disableAnomaly(page, anomaly._id, search);
  };

  const handlerSaveChanges = (amount, page, id) => {
    if (
      /^[0-9]+$/.test(changeAmount[anomaly._id]) &&
      anomaly.amount > changeAmount[anomaly._id]
    ) {
      setSave({ ...save, [id]: true });
      updateMountAnomaly(
        amount,
        page,
        id,
        anomaly.type,
        anomaly.stock[0],
        changeAmount[anomaly._id],
        search,
      );
    } else {
      showNotification('Monto ingresado inválido.', 'error');
    }
  };

  useEffect(() => {
    handlerSetSave();
  }, [changeAmount[anomaly._id]]);

  return (
    <TableRow key={anomaly._id}>
      <TableCell
        component="th"
        scope="row"
        align="left"
        width={'300px'}
        sx={{
          color: `${theme.palette.tableText.main}`,
        }}
      >
        {anomaly.client[0]?.name}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        align="left"
        width={'300px'}
        sx={{
          color: `${theme.palette.tableText.main}`,
        }}
      >
        {anomaly.stock[0].currency}
      </TableCell>
      <TableCell align="left" width={'300px'}>
        <TextField
          required
          id="outlined-start-adornment"
          sx={{
            width: '165px',

            '& .MuiOutlinedInput-root': {
              borderRadius: '10px',
              border: `1.5px solid ${theme.palette.tableTitle.main}`,
              height: '45px',
              margin: 0,
              color: theme.palette.title.main,
              fontWeight: '400',
              fontSize: '14px',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{
                  '.MuiInputAdornment-sizeSmall': {
                    fontSize: '2px',
                  },
                }}
                position="start"
              >
                $
              </InputAdornment>
            ),
          }}
          value={changeAmount[anomaly._id]}
          onChange={(e) => handlerChangeAmount(e, anomaly._id, anomaly.amount)}
        />
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        align="left"
        width={'300px'}
        sx={{
          color: `${theme.palette.tableText.main}`,
        }}
      >
        {anomaly.type === 'output' ? 'SALIDA' : 'ENTRADA'}
      </TableCell>

      <TableCell
        component="th"
        scope="row"
        sx={{
          color: `${theme.palette.tableText.main}`,
        }}
        align="left"
        width={'300px'}
      >
        <CopyToClipboardButton id={anomaly.operation[0]?.index} />
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        sx={{
          color: `${theme.palette.tableText.main}`,
        }}
      >
        <AlertDialog
          handlerAction={handlerDisable}
          page={page}
          objectId={anomaly._id}
          open={openAlert}
          onClose={() => setOpenAlert(false)}
          context={'anomalies'}
        />
        <DeleteIcon
          onClick={() => setOpenAlert(true)}
          style={{ cursor: 'pointer' }}
        >
          Eliminar
        </DeleteIcon>
      </TableCell>

      <TableCell
        component="th"
        scope="row"
        sx={{
          color: `${theme.palette.tableText.main}`,
        }}
      >
        <div style={{ display: 'flex' }}>
          {save[anomaly._id] ? (
            <BtnSave>GUARDAR</BtnSave>
          ) : (
            <BtnSaveActive
              onClick={() =>
                handlerSaveChanges(anomaly.amount, page, anomaly._id)
              }
            >
              GUARDAR
            </BtnSaveActive>
          )}

          <BtnPayOff onClick={() => handlerPayOff()}>SALDAR</BtnPayOff>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default AnomaliesDetails;
