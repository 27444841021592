import React, { useState } from 'react';
import {
  LogisticContainer,
  OperationAndMapContainer,
  LogisticTitle,
  LogisticSubTitle,
} from './logisticStyles';
import Map from './Map/Map';
import LogisticOperations from './LogisticOperations';
import Select from '../../components/Select/SelectMui';

const Logistic = () => {
  const [filter, setFilter] = useState({
    liquidatorType: 'DELIVERY',
    status: 'Todos',
  });

  return (
    <LogisticContainer>
      <div style={{ marginBottom: '20px' }}>
        <LogisticTitle>Logistica</LogisticTitle>
        <Select
          options={[
            { name: 'TODOS', value: 'Todos' },
            { name: 'Pendiente', value: 'Pendiente' },
            { name: 'Confirmado LYM', value: 'Confirmado LYM' },
            { name: 'Confirmado Delivery', value: 'Confirmado Delivery' },
            { name: 'Confirmado Cliente', value: 'Confirmado Cliente' },
            { name: 'Liquidado', value: 'Liquidado' },
            { name: 'Finalizado', value: 'Finalizado' },
          ]}
          onChangeFunction={(e, newValue) => {
            setFilter({ ...filter, status: newValue });
          }}
          context="logistic"
        />
      </div>
      <OperationAndMapContainer>
        <LogisticOperations filter={filter} />
        <Map />
      </OperationAndMapContainer>
    </LogisticContainer>
  );
};

export default Logistic;
