import React, { useState, useEffect } from 'react';
import {
  BreadcumsContainerClient,
  ClientInfo,
  ContainerClientDetail,
  ContainerClientDetailInfo,
  BoxTitle,
  ClientCC,
  BoxMui,
  TotalInfo,
  ClientTableCell,
  ClientTableCellBody,
  RightButtonsContainer,
  TotalClientTableCell,
  TotalInfoValuesContainer,
  TotalInfoValues,
} from './clientStyles';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import OperationsTable from '../../components/Operation/OperationsTable';
import FilterPopover from '../Operations/FilterPopover';
import Searcher from '../../components/Searcher/Searcher';
import useOperations from '../Operations/useOperations';
import useClients from './useClients';
import useStock from '../Stock/useStock';
import { CSVLink } from 'react-csv';
import ButtonOutline from '../../components/Button/ButtonOutline';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';

const ClientDetail = () => {
  const { clients, getClientInfo, clientInfo, loading } = useClients();
  const { stock } = useStock();

  const [filters, setFilters] = useState({
    status: [],
    operationType: [],
    liquidatorType: [],
    clientName: null,
    inputCurrency: 'Todas',
    outputCurrency: 'Todas',
    dateRange: 'null',
  });

  const { id } = useParams();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const { getOperations, operations, numberOfPages, deleteOperation } =
    useOperations();

  const pushData = (operations) => {
    const array = [];
    operations?.map((s) => {
      array.push([
        `${s._id}`,
        `${s.input_currency?.currency}`,
        `${s.input_amount}`,
        `${s.output_currency?.currency}`,
        `${s.output_amount}`,
        `${s.operation_type}`,
        `${s.liquidator_type}`,
        `${s.status}`,
        `${s.client.name}`,
      ]);
    });

    return array;
  };

  const handleFormatCsv = () => {
    const csvData = [
      [
        'Id',
        'Divisa de entrada',
        'Monto de entrada',
        'Divisa de salida',
        'Monto de salida',
        'Tipo de op.',
        'Liquidador',
        'Estado',
        'Cliente',
      ],
      ...pushData(operations),
    ];

    return csvData;
  };

  const cleanFormatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formatCreatedAt = `${day}/${month}/${year}`;
    return formatCreatedAt;
  };

  useEffect(() => {
    getClientInfo(id);
  }, []);

  useEffect(() => {
    if (clientInfo.name) {
      getOperations(
        {
          clientName: clientInfo?.name,
        },
        page,
      );
    }
  }, [clientInfo, page]);

  return (
    <ContainerClientDetail>
      {loading ? (
        <Loader />
      ) : (
        <>
          <BreadcumsContainerClient>
            <BreadcrumbsMui
              path="/clientes"
              title={`${clientInfo?.name}`}
              prev="Clientes"
            />
          </BreadcumsContainerClient>
          <ContainerClientDetailInfo>
            <ClientInfo>
              <div
                style={{
                  width: '95%',

                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  height: '90%',
                  position: 'relative',
                  bottom: '50px',
                }}
              >
                <BoxTitle
                  style={{
                    zIndex: 1,
                  }}
                >
                  <h1>{`${clientInfo?.name}`}</h1>
                </BoxTitle>
                <BoxMui
                  component="form"
                  noValidate
                  sx={{
                    zIndex: 0,
                  }}
                >
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <ClientTableCell align="left">
                            FECHA DE CREACIÓN
                          </ClientTableCell>
                          <ClientTableCell align="left">
                            NOMBRE COMPLETO
                          </ClientTableCell>
                          <ClientTableCell align="left">EMAIL</ClientTableCell>
                          <ClientTableCell align="left">
                            TELÉFONO
                          </ClientTableCell>
                          <ClientTableCell align="left">
                            DIRECCIÓN
                          </ClientTableCell>
                          <ClientTableCell align="left">ID</ClientTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <ClientTableCellBody
                            align="left"
                            sx={{
                              color: `#7C8098`,
                            }}
                          >
                            {`${cleanFormatCreatedAt(clientInfo?.createdAt)}`}
                          </ClientTableCellBody>
                          <ClientTableCellBody align="left">
                            {`${clientInfo?.name}`}
                          </ClientTableCellBody>
                          <ClientTableCellBody align="left">
                            {`${clientInfo?.email}`}
                          </ClientTableCellBody>
                          <ClientTableCellBody align="left">
                            {`${clientInfo?.cellphone}`}
                          </ClientTableCellBody>
                          <ClientTableCellBody align="left">
                            {`${clientInfo?.address}`}
                          </ClientTableCellBody>
                          <ClientTableCellBody align="left">
                            {`${clientInfo?._id}`}
                          </ClientTableCellBody>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </BoxMui>
              </div>
            </ClientInfo>
            <ClientCC style={{}}>
              <TotalInfo>
                <TotalClientTableCell>SALDO:</TotalClientTableCell>

                <TotalInfoValuesContainer>
                  {clientInfo.currentAccount?.map((e) => {
                    return (
                      <TotalInfoValues>
                        {e.currency.name}: {e.amount}
                      </TotalInfoValues>
                    );
                  })}
                </TotalInfoValuesContainer>
              </TotalInfo>
            </ClientCC>
          </ContainerClientDetailInfo>
          <div
            style={{
              position: 'relative',
              left: '3%',
              width: '100%',
            }}
          >
            <div
              style={{
                marginBottom: '25px',
                position: 'relative',
                left: '2%',
              }}
            >
              <RightButtonsContainer>
                <CSVLink
                  data={handleFormatCsv()}
                  filename={`${clientInfo?.name}_operations `}
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                  separator=";"
                >
                  <ButtonOutline icon="csv" title="EXPORTAR CSV" />
                </CSVLink>
              </RightButtonsContainer>
            </div>

            <OperationsTable
              deleteOperation={deleteOperation}
              setPage={setPage}
              operations={operations}
              page={page}
              numberOfPages={numberOfPages}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  justifyItems: 'center',
                  gap: '15px',
                  margin: '25px 0px',
                }}
              >
                <FilterPopover
                  setFilters={setFilters}
                  setAnchorEl={setAnchorEl}
                  setPage={setPage}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  getOperations={getOperations}
                  filters={filters}
                  anchorEl={anchorEl}
                  stock={stock}
                  clients={clients}
                  startDate={startDate}
                  endDate={endDate}
                  context="clientDetail"
                />
                <Searcher
                  context={'operations'}
                  setFilters={setFilters}
                  getOperations={getOperations}
                  setPage={setPage}
                  page={page}
                  filters={filters}
                />
              </div>
            </OperationsTable>
          </div>
        </>
      )}
    </ContainerClientDetail>
  );
};

export default ClientDetail;
