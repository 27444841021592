import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, Grid, TextField, Typography } from '@mui/material';
import { ThemeProvider } from 'styled-components';
import ButtonWidth from '../../components/Button/ButtonWidth';
import Logo from '../../components/Logo/Logo';
import {
  BackgroundImage,
  ContainerLogoAndRights,
  LoginSubtitleText,
  LoginTitle,
  LoginTitleText,
  RightsReserved,
  StyledContainer,
} from '../Login/loginStyles';
import useRecovery from './useRecovery';
import getCurrentYear from '../../utils/currentYear';
import theme from '../../theme';
import Success from '../../components/Success/Success';

const Recovery = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(true);

  const { changePassword, showModal, setShowModal, errorRecovery } =
    useRecovery();

  const getTokenFromUrl = () => {
    const url = window.location.href;
    const urlArray = url.split('/');
    const token = urlArray[urlArray.length - 1];
    return token;
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    const newValue = e.target.value.replace(/\s/g, '');
    console.log(newValue);
    setPassword(newValue);
  };

  const handleChangeConfirmPassword = (e) => {
    e.preventDefault();
    const newValue = e.target.value.replace(/\s/g, '');
    setConfirmPassword(newValue);
  };

  const handleDisabled = () => {
    if (password.length > 0 && confirmPassword.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('click');
    const token = getTokenFromUrl();
    if (password === confirmPassword) {
      console.log('Las contraseñas coinciden');
      changePassword(password, token);
    } else {
      console.log('Las contraseñas no coinciden');
      setError('Las contraseñas no coinciden');
    }
  };

  useEffect(() => {
    handleDisabled();
    console.log(disabled);
  }, [password, confirmPassword]);

  return (
    <BackgroundImage>
      <ThemeProvider theme={theme}>
        <ContainerLogoAndRights>
          <Logo />
          <RightsReserved>
            © {getCurrentYear()} LYM, made by{' '}
            <a
              style={{
                color: '#fff',
                textDecoration: 'none',
              }}
              href="https://www.jrc.dev/"
              target={'_blank'}
            >
              JRC Studio
            </a>
          </RightsReserved>
        </ContainerLogoAndRights>
        {showModal ? (
          <Success title="¡Recuperaste tu contraseña exitosamente!" />
        ) : null}
        <LoginTitle>
          <LoginTitleText>Recuperar contraseña</LoginTitleText>
          <div
            style={{
              width: '63%',
            }}
          >
            <LoginSubtitleText
              style={{
                fontFamily: `${theme.typography.fontWeightRegular}`,
              }}
            >
              Ingrese su nueva contraseña
            </LoginSubtitleText>
          </div>
        </LoginTitle>
        <StyledContainer
          component="main"
          maxWidth="xs"
          sx={{ maxHeight: '60vh' }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 20 }}
            >
              <TextField
                onChange={(e) => handleChangePassword(e)}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                sx={{
                  '& label.Mui-focused': {
                    color: '#D43436',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#D2D6D9',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#D3D6DA',
                    },
                    '&:hover fieldset': {
                      borderColor: '#D43436',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#D43436',
                    },
                  },
                }}
              />
              <TextField
                onChange={(e) => handleChangeConfirmPassword(e)}
                margin="normal"
                required
                fullWidth
                name="password2"
                label="Repetir contraseña"
                type="password"
                id="password2"
                sx={{
                  '& label.Mui-focused': {
                    color: '#D43436',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#D2D6D9',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#D3D6DA',
                    },
                    '&:hover fieldset': {
                      borderColor: '#D43436',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#D43436',
                    },
                  },
                }}
              />
              <Typography sx={{ color: 'red', fontWeight: '600' }}>
                {error?.length > 0 ? error : null}
              </Typography>
              {errorRecovery?.length > 0 ? (
                <Typography sx={{ color: 'red', fontWeight: '600' }}>
                  {errorRecovery}
                </Typography>
              ) : null}
              <div
                style={{
                  width: '100%',
                  marginTop: '30px',
                }}
              >
                <ButtonWidth
                  type="submit"
                  title="GUARDAR"
                  disabled={disabled}
                />
              </div>
            </Box>
          </Box>
        </StyledContainer>
      </ThemeProvider>
    </BackgroundImage>
  );
};

export default Recovery;
