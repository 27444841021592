import React from 'react';
import LogoSvg from './logo.png';

const Logo = () => {
  return (
    <img src={LogoSvg} alt="Logo" width="120px" style={{ marginTop: '30px' }} />
  );
};

export default Logo;
