import React, { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import {
  ClientsContainer,
  Title,
  ClientTableContainer,
  ButtonsContainer,
  LeftButtonsContainer,
  RightButtonsContainer,
  Btn,
} from './clientStyles';
import useClients from './useClients';
import ButtonOutline from '../../components/Button/ButtonOutline';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';

import ClientsTable from '../../components/Clients/ClientsTable';

const Clients = () => {
  const navigate = useNavigate();
  const {
    getClientsPaginate,
    clients,
    deleteClient,
    loading,
    page,
    setPage,
    totalPages,
    search,
    setSearch,
    clientsCsv,
    getClientsForCsv,
  } = useClients();

  useEffect(() => {
    getClientsPaginate(search, page);
    getClientsForCsv();
  }, []);

  return (
    <ClientsContainer>
      <Title>Clientes</Title>
      <ButtonsContainer>
        <LeftButtonsContainer>
          <div style={{ marginTop: '0px' }}>
            <Btn onClick={() => navigate('/agregar-clientes')}>
              NUEVO CLIENTE
            </Btn>
          </div>
        </LeftButtonsContainer>
        <RightButtonsContainer>
          <CSVLink
            data={clientsCsv}
            filename="Clientes.csv"
            target="_blank"
            separator={';'}
            style={{ textDecoration: 'none' }}
          >
            <ButtonOutline icon="csv" title="EXPORTAR CSV" />
          </CSVLink>
        </RightButtonsContainer>
      </ButtonsContainer>

      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '150px',
          }}
        >
          <Loader />
        </div>
      ) : (
        <ClientTableContainer>
          <ClientsTable
            clients={clients}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            search={search}
            setSearch={setSearch}
            loading={loading}
            getClientsPaginate={getClientsPaginate}
            deleteClient={deleteClient}
          ></ClientsTable>
        </ClientTableContainer>
      )}
    </ClientsContainer>
  );
};

export default Clients;
