import React, { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { CSVLink } from 'react-csv';
import {
  ClientsContainer,
  Title,
  ClientTableContainer,
  ClientTableCell,
  ClientTableCellBody,
  ButtonsContainer,
  LeftButtonsContainer,
  RightButtonsContainer,
  Btn,
} from '../../containers/Clients/clientStyles';
import CopyToClipboardButton from '../Button/CopyToClipboard';

import { useNavigate } from 'react-router-dom';

import AlertDialog from '../../components/Dialog/AlertDialog.jsx';
import DeleteIcon from '@mui/icons-material/Delete';

const ClientsIndividual = ({ client, deleteClient, search, page }) => {
  const navigate = useNavigate();

  const [openAlert, setOpenAlert] = useState(false);

  const handlerDeleteClient = (id) => {
    deleteClient(id, search, page);
  };

  return (
    <TableRow>
      <ClientTableCellBody
        align="left"
        width={'300px'}
        onClick={() => navigate(`/clientes/${client._id}`)}
      >
        {client.name}
      </ClientTableCellBody>

      <ClientTableCellBody align="left" width={'300px'}>
        {client.email}
      </ClientTableCellBody>

      {client.cellphone ? (
        <ClientTableCellBody align="left" width={'300px'}>
          {client.cellphone}
        </ClientTableCellBody>
      ) : (
        <ClientTableCellBody width={'300px'} align="left">
          -
        </ClientTableCellBody>
      )}

      <ClientTableCellBody align="left" width={'300px'}>
        {client.address}
      </ClientTableCellBody>

      <ClientTableCellBody align="left" width={'300px'}>
        <CopyToClipboardButton msg={client._id.slice(20)} id={client._id} />
      </ClientTableCellBody>

      <ClientTableCellBody align="left" width={'300px'}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '34px',
          }}
        >
          <EditIcon
            onClick={() => navigate(`/editar-cliente/${client._id}`)}
            style={{ cursor: 'pointer' }}
          >
            Editar
          </EditIcon>
          <AlertDialog
            handlerAction={handlerDeleteClient}
            page={page}
            objectId={client._id}
            open={openAlert}
            onClose={() => setOpenAlert(false)}
            context={'clients'}
          />
          <DeleteIcon
            onClick={() => setOpenAlert(true)}
            style={{ cursor: 'pointer' }}
          >
            Eliminar
          </DeleteIcon>
        </div>
      </ClientTableCellBody>
    </TableRow>
  );
};

export default ClientsIndividual;
