/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { HomeContainer } from '../Layout/homeStyles';
import {
  AddOperationContainer,
  BtnContainer,
  BoxMui,
  BoxTitle,
  BreadcumsContainer,
  OperationFormContainer,
} from './operationsStyles';
import useOperations from './useOperations';
import useClients from '../Clients/useClients';
import useUsers from '../Team/useUsers';
import { useNavigate } from 'react-router-dom';
import useStock from '../Stock/useStock';
import Button from '../../components/Button/Button';
import CancelButton from '../../components/Button/CancelButton';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';
import PayServProvForm from './Forms/PayServProvForm';
import PaySalariesForm from './Forms/PaySalariesForm';
import DeliveryPositionForm from './Forms/DeliveryPositionForm';
import MainForm from './Forms/MainForm';
import LiquidationCommissionForm from './Forms/LiquidationCommissionForm';
import CurrentAccountForm from './Forms/CurrentAccountForm';
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@mui/material';
import theme from '../../theme';
import { operationTypes } from '../../utils/constants';

const AddOperation = () => {
  const navigate = useNavigate();

  const { getClients, clients } = useClients();
  const { getDeliverys, getUsers, deliverys, users } = useUsers();
  const { getStock, stock } = useStock();
  const {
    createOperation,
    getOperations,
    registerPosition,
    serviceProviders,
    getServiceProivders,
    createNewServiceProvider,
    deleteServiceProvider,
    errors,
    setErrors,
  } = useOperations();

  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currencyChecked, setCurrencyChecked] = useState(null);
  const [position, setPosition] = useState({
    currency: '',
    balance: '',
    userId: selectedUser?._id,
  });
  const [operation, setOperation] = useState({
    client: '',
    operation_type: '',
    input_currency: '',
    input_amount: '',
    output_currency: '',
    output_amount: '',
    liquidator_type: '',
    commission: '',
    input_anomaly: '',
    output_anomaly: '',
    comments: '',
    profile: '',
    address: '',
    exchange_rate: '',
    useCurrentAccount: false,
  });

  const handlePositionChange = (event, value) => {
    setPosition({
      ...position,
      [event.target.name]: event.target.value,
    });
  };

  const handleAddPosition = (event) => {
    event.preventDefault();
    //return console.log(position);
    registerPosition(position, operation.operation_type);
  };

  const handleInputChange = (event) => {
    if (errors[event.target.name]) {
      setErrors({ ...errors, [event.target.name]: false });
    }
    setOperation({
      ...operation,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (selectedClient) {
      const op = operation;
      op.client = selectedClient._id;
      setOperation(op);
    }

    if (selectedUser) {
      const op = operation;
      op.profile = selectedUser._id;
      setOperation(op);
    }

    if (currencyChecked === null) {
      const op = operation;
      op.useCurrentAccount = false;
      setOperation(op);
    } else {
      const op = operation;
      op.currencyChecked = currencyChecked;
      if (op.input_amount > currencyChecked.amount) {
        if (currencyChecked.amount < 0) {
          op.input_anomaly = parseInt(op.input_amount);
        } else {
          op.input_anomaly =
            parseInt(op.input_amount) - parseInt(currencyChecked.amount);
        }
      } else {
        op.input_anomaly = 0;
      }
      setOperation(op);
    }
    //return console.log(operation);
    createOperation(operation, operation.profile);
  };

  useEffect(() => {
    getOperations();
    getClients();
    getDeliverys();
    getUsers();
    getStock();
    getServiceProivders();
  }, []);

  useEffect(() => {
    setErrors({
      client: false,
      operation_type: false,
      input_currency: false,
      input_amount: false,
      output_currency: false,
      output_amount: false,
      liquidator_type: false,
      input_anomaly: false,
      output_anomaly: false,
      assignedDelivery: false,
      address: false,
      exchange_rate: false,
      paidTeamMember: false,
      broker: false,
      balance: false,
      currency: false,
      userId: false,
    });
  }, [operation.operation_type]);

  return (
    <HomeContainer style={{ marginTop: '50px' }}>
      <AddOperationContainer>
        <BreadcumsContainer>
          <BreadcrumbsMui
            title="Cargar operacion"
            prev="Operaciones"
            path={'/operaciones'}
          />
        </BreadcumsContainer>
        <BoxTitle>
          <h1>Cargar operacion</h1>
        </BoxTitle>
        <BoxMui
          component="form"
          noValidate
          onSubmit={
            operation.operation_type === 'RETIRO POS.DELIVERY' ||
            operation.operation_type === 'INGRESO POS.DELIVERY'
              ? handleAddPosition
              : handleSubmit
          }
        >
          <OperationFormContainer>
            {operation.operation_type === 'PAGO SERV/PROV' ? (
              <PayServProvForm
                key={'payserv'}
                operation={operation}
                handleInputChange={handleInputChange}
                stock={stock}
                serviceProviders={serviceProviders}
                createNewServiceProvider={createNewServiceProvider}
                getServiceProivders={getServiceProivders}
                setOperation={setOperation}
                deleteServiceProvider={deleteServiceProvider}
                errors={errors}
              />
            ) : operation.operation_type === 'PAGO SUELDOS' ? (
              <PaySalariesForm
                key={'paysalaries'}
                handleInputChange={handleInputChange}
                setOperation={setOperation}
                operation={operation}
                stock={stock}
                users={users}
                errors={errors}
              />
            ) : operation.operation_type === 'RETIRO POS.DELIVERY' ||
              operation.operation_type === 'INGRESO POS.DELIVERY' ? (
              <DeliveryPositionForm
                key={'del'}
                handlePositionChange={handlePositionChange}
                usersDelivery={deliverys}
                selectedUser={selectedUser}
                setPosition={setPosition}
                position={position}
                stock={stock}
                opType={operation.operation_type}
                operation={operation}
                handleInputChange={handleInputChange}
                errors={errors}
              />
            ) : operation.operation_type === 'LIQ.COMISIONES' ? (
              <LiquidationCommissionForm
                key={'liq'}
                handleInputChange={handleInputChange}
                stock={stock}
                operation={operation}
                errors={errors}
              />
            ) : operation.operation_type === 'INGRESO CTA.CORRIENTE' ||
              operation.operation_type === 'EGRESO CTA.CORRIENTE' ? (
              <CurrentAccountForm
                key={'curre'}
                handleInputChange={handleInputChange}
                stock={stock}
                setSelectedClient={setSelectedClient}
                clients={clients}
                selectedClient={selectedClient}
                operation={operation}
                errors={errors}
              />
            ) : operation.operation_type === '' ? (
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  sx={{ color: `${theme.palette.inputText.main}` }}
                  id="input"
                >
                  Tipo de Operacion
                </InputLabel>
                <Select
                  labelId="input"
                  name="operation_type"
                  onChange={(event) => handleInputChange(event)}
                  value={operation.operation_type}
                >
                  <ListSubheader>OPERACIONES FINANCIERAS</ListSubheader>
                  {operationTypes().map((o, i) => {
                    if (i > 7) return null;
                    return <MenuItem value={o}>{o}</MenuItem>;
                  })}
                  <ListSubheader>OPERACIONES INSTITUCIONALES</ListSubheader>
                  {operationTypes().map((o, i) => {
                    if (i < 8) return null;
                    return <MenuItem value={o}>{o}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            ) : (
              <MainForm
                key={'main'}
                handleInputChange={handleInputChange}
                operation={operation}
                setOperation={setOperation}
                stock={stock}
                usersDelivery={deliverys}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                clients={clients}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
                currencyChecked={currencyChecked}
                setCurrencyChecked={setCurrencyChecked}
                errors={errors}
              />
            )}
          </OperationFormContainer>
          {operation.operation_type === '' ? null : (
            <BtnContainer>
              <CancelButton
                title="CANCELAR"
                onClick={() => navigate('/operaciones')}
              />
              <Button type="submit" title="GUARDAR" />
            </BtnContainer>
          )}
        </BoxMui>
      </AddOperationContainer>
    </HomeContainer>
  );
};

export default AddOperation;
