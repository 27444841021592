import React, { useEffect } from 'react';
import { HomeContainer } from '../Layout/homeStyles';
import { CotizationContainer } from './operationsStyles';
import useOperations from './useOperations';

const PrechargedOperations = () => {
  const { cotizations, getCotizations, chargeCotization } = useOperations();

  useEffect(() => {
    getCotizations();
  }, []);

  const handleSubmitCotization = async (e, id) => {
    e.preventDefault();
    //console.log(e.target[0].value);
    await chargeCotization(id, { value: e.target[0].value });
    getCotizations();
  };

  return (
    <HomeContainer>
      {cotizations.length === 0 ? (
        <h1>No hay cotizaciones solicitadas</h1>
      ) : (
        <>
          <h1>Cotizaciones solicitadas</h1>
          {cotizations.map((c) => (
            <CotizationContainer key={c._id}>
              <span>{c.client.name}</span>
              <span>
                Venta de {c.output_currency.currency} por{' '}
                {c.input_currency.currency}
              </span>
              <form
                onSubmit={(e) => {
                  handleSubmitCotization(e, c._id);
                }}
              >
                <input name="cotization" placeholder="Cotizacion" />
                <button type="submit">Cargar cotizacion</button>
              </form>
              <span>Cotizacion solicitada dia {c.created}</span>
              <span>Telefono: {c.client.cellphone}</span>
            </CotizationContainer>
          ))}
        </>
      )}
    </HomeContainer>
  );
};

export default PrechargedOperations;
