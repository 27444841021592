import { Table, TableBody, TableContainer } from '@mui/material';
import React from 'react';
import AnomaliesDetails from './AnomaliesDetails';
import AnomaliesHead from './AnomaliesHead';
import { InputSearch, SearchContainer } from '../Searcher/searcherStyles';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '../../img/refresh.png';
import theme from '../../theme';
import Paginate from '../../components/Paginate/Paginate';
import Searcher from '../Searcher/Searcher';

const AnomaliesTable = ({
  anomalies,
  setChangeAmount,
  changeAmount,
  save,
  setSave,
  setAnomalies,
  updateMountAnomaly,
  payOffAnomaly,
  disableAnomaly,
  page,
  SearchAnomalies,
  getAnomalies,
  setSearch,
  setPage,
  search,
  loading,
  date,
  setDate,

  totalPages,
}) => {
  return (
    <TableContainer>
      <Searcher
        setSearch={setSearch}
        search={search}
        date={date}
        context="anomalies"
        getAnomalies={getAnomalies}
        setPage={setPage}
      ></Searcher>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingLeft: '20px',
        }}
      >
        {anomalies?.length === 0 && !loading ? (
          <h2 style={{ textAlign: 'center', marginBottom: '7rem' }}>
            No hay registros de anomalías
          </h2>
        ) : (
          <TableContainer>
            <Table
              sx={{
                width: '100%',
              }}
            >
              <AnomaliesHead />

              <TableBody>
                {anomalies?.map((s) => (
                  <AnomaliesDetails
                    key={s._id}
                    anomaly={s}
                    setChangeAmount={setChangeAmount}
                    changeAmount={changeAmount}
                    save={save}
                    setSave={setSave}
                    anomalies={anomalies}
                    setAnomalies={setAnomalies}
                    updateMountAnomaly={updateMountAnomaly}
                    payOffAnomaly={payOffAnomaly}
                    disableAnomaly={disableAnomaly}
                    page={page}
                  />
                ))}
              </TableBody>
            </Table>
            <Paginate
              page={page}
              setPage={setPage}
              totalPages={totalPages}
              search={search}
              getFunction={getAnomalies}
              date={date}
            />
          </TableContainer>
        )}
      </div>
    </TableContainer>
  );
};

export default AnomaliesTable;
