import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { Select } from '../../containers/Logistic/logisticStyles';
import useOperations from '../../containers/Operations/useOperations';
import theme from '../../theme';
import formatNumber from '../../utils/formatNumber';
import CopyToClipboardButton from '../Button/CopyToClipboard';

export const LogisticOperation = ({
  id,
  address,
  input,
  output,
  deliverys,
  assignedDelivery,
  index,
}) => {
  const { changeDeliveryOperation } = useOperations();

  const handleChangeDelivery = async (e) => {
    const delId = e.target.value;
    await changeDeliveryOperation(id, delId);
  };

  return (
    <TableRow>
      <TableCell
        component="th"
        scope="row"
        sx={{
          color: `${theme.palette.tableText.main}`,
        }}
      >
        <CopyToClipboardButton id={index} />
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        sx={{
          color: `${theme.palette.tableText.main}`,
        }}
      >
        {address}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        sx={{
          color: `${theme.palette.tableText.main}`,
        }}
      >
        <span style={{ color: '#21c432' }}>+</span> {formatNumber(input)}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        sx={{
          color: `${theme.palette.tableText.main}`,
        }}
      >
        <span style={{ color: 'red' }}>-</span> {formatNumber(output)}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        sx={{
          color: `${theme.palette.tableText.main}`,
        }}
      >
        <Select onChange={handleChangeDelivery}>
          <option value="null">Selecionar Liq.</option>
          {assignedDelivery !== null ? (
            <option selected value={assignedDelivery?._id}>
              {assignedDelivery?.name}
            </option>
          ) : null}
          {deliverys.map((d) =>
            assignedDelivery?._id === d._id ? null : (
              <option key={d._id} value={d._id}>
                {d.name}
              </option>
            ),
          )}
        </Select>
      </TableCell>
    </TableRow>
  );
};
