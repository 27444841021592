import PropTypes from 'prop-types';

import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import theme from '../../../theme';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../Login/useAuth';
import usePanic from './usePanic';

import AlertDialog from '../../../components/Dialog/AlertDialog';

const Notification = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px ',
        margin: '10px 30px',
        cursor: 'pointer',
      }}
    >
      <NotificationsIcon />
      <div>
        <h4 style={{ margin: '0' }}>Nueva operacion</h4>
        <span>Nueva OP COMP VTA.BILLETE</span>
      </div>
    </div>
  );
};

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { handlePanic } = usePanic();

  const panicLocalStorage = localStorage.getItem('panic');

  const [showPanicAlert, setShowPanicAlert] = useState(false);
  const [menuContext, setMenuContext] = useState('');

  const handlePanicAlert = () => {
    handlePanic();
    setShowPanicAlert(false);
  };

  const user = useAuth();

  const navigate = useNavigate();

  const handleMenu = (event, ctx) => {
    setMenuContext(ctx);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    handleClose();
    localStorage.removeItem('user');
    navigate('/login');
  };

  return (
    <>
      {/* header search */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* <NotificationSection /> */}

      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={(e) => handleMenu(e, 'menu')}
        sx={{
          color: theme.palette.icons.main,
        }}
      >
        <AccountCircleIcon />
      </IconButton>
      <IconButton
        size="large"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        sx={{
          color: theme.palette.icons.main,
        }}
        onClick={(e) => handleMenu(e, 'notification')}
      >
        <NotificationsIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleClose}
      >
        {menuContext === 'menu' ? (
          <>
            <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
            {user?.role === 'superadmin' && (
              <>
                <AlertDialog
                  handlerAction={handlePanicAlert}
                  open={showPanicAlert}
                  context={panicLocalStorage === 'true' ? 'disabled' : 'panic'}
                  onClose={() => setShowPanicAlert(false)}
                />
                <MenuItem onClick={() => setShowPanicAlert(true)}>
                  Botón de pánico
                </MenuItem>
              </>
            )}
          </>
        ) : (
          <Notification />
        )}
      </Menu>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
