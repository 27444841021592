import styled from 'styled-components';
import theme from '../../theme';

export const LogisticContainer = styled.div`
  margin-left: 300px;
  margin-top: 40px;
  width: 100%;
`;

export const OperationAndMapContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const MapContainer = styled.div`
  width: 55%;
  padding-right: 30px;
`;

export const OperationContainer = styled.div`
  width: 45%;
`;

export const Select = styled.select`
  border: 1px solid ${theme.palette.title.main};
  color: ${theme.palette.title.main};
  padding: 10px 10px;
  border-radius: 5px;
  margin-left: 10px;
  background: none;
  &:hover {
    cursor: pointer;
  }
`;

export const LogisticTitle = styled.h1`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${theme.palette.title.main};
`;

export const LogisticSubTitle = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  color: ${theme.palette.title.main};
`;
