import { Box, CssBaseline, Toolbar, AppBar } from '@mui/material';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header/index.js';
import Sidebar from './Sidebar/index.js';

const Layout = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleLeftDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        sx={{
          bgcolor: 'transparent',
          boxShadow: 'none',
          top: '30px',
          right: '30px',
          width: '70%',
        }}
      >
        <Toolbar>
          <Header />
        </Toolbar>
      </AppBar>

      <Sidebar drawerOpen={drawerOpen} drawerToggle={handleLeftDrawerToggle} />

      <Outlet />
    </Box>
  );
};

export default Layout;
