import { Table, TableBody, TableContainer } from '@mui/material';
import React from 'react';
import Stock from './Stock';
import StockHead from './StockHead';

const StockTable = ({
  stock,
  desativateCurrency,
  checkToday,
  getTotalAnomalieValue,
}) => {
  return (
    <TableContainer>
      {stock?.length === 0 ? (
        <h2 style={{ textAlign: 'center' }}>
          No hay registros de stock en este dia
        </h2>
      ) : (
        <Table
          sx={{
            width: '100%',
          }}
        >
          <StockHead />
          <TableBody>
            {stock?.map((s) => (
              <Stock
                key={s._id}
                id={s._id}
                stock={s}
                currency={s.currency}
                exchange_rate={s.exchange_rate}
                initial_balance={s.initial_balance}
                actual_balance={s.actual_balance}
                getTotalAnomalieValue={getTotalAnomalieValue}
                desativateCurrency={desativateCurrency}
                checkToday={checkToday}
              />
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default StockTable;
