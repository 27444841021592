import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import fetchFromApi from '../../utils/fetchFromApi';
import useNotistack from '../Notistack/useNotistack';
import jsDownload from 'js-file-download';

const useStock = () => {
  const { showNotification } = useNotistack();
  const [stock, setStock] = React.useState();
  const [stockForSearch, setStockForSearch] = React.useState([]);
  const [currency, setCurrency] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const getStock = async (positionType) => {
    setLoading(true);
    try {
      const stock = await fetchFromApi(
        'GET',
        `stock/?positionType=${positionType}`,
      );
      if (stock) {
        setStock(stock);
        setStockForSearch(stock);
        setLoading(false);
      }
      return stock;
    } catch (error) {
      console.log(error);
    }
  };

  const registerCurrency = async (currency, exchange_rate, initial_balance) => {
    try {
      setErrors({});
      const response = await fetchFromApi('POST', 'stock', {
        currency,
        exchange_rate,
        initial_balance,
      });
      showNotification(response[1], 'success');
      getStock();
      navigate('/stock');
    } catch (error) {
      error.response.data[0].map((e) => showNotification(e, 'error'));
      setErrors(error.response.data[1]);
    }
  };

  const deleteCurrency = async (id) => {
    try {
      const result = await fetchFromApi('PUT', 'stock', id);
      if (result) {
        console.log(result);
        const newCurrencies = stock.filter((currency) => currency._id !== id);
        setStock(newCurrencies);
        showNotification(result[1], 'success');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrency = async (id) => {
    try {
      const response = await fetchFromApi('GET', `stock/${id}`);
      if (response) {
        setCurrency(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateCurrency = async (id, currencyEdited) => {
    try {
      setErrors({});
      const confirm = window.confirm('¿Estás seguro de editar esta divisa?');
      if (confirm) {
        const result = await fetchFromApi('PUT', `stock/${id}`, currencyEdited);
        console.log(result);
        if (result) {
          showNotification(result[1], 'success');
        }
        navigate('/stock');
      }
    } catch (error) {
      error.response.data[0].map((e) => showNotification(e, 'error'));
      setErrors(error.response.data[1]);
    }
  };

  const desativateCurrency = async (id) => {
    try {
      const result = await fetchFromApi('PUT', `stock/desactivate/${id}`);
      if (result) {
        console.log(result);
        const newCurrencies = stock.filter((currency) => currency._id !== id);
        setStock(newCurrencies);
        showNotification(result[1], 'success');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadCsv = async () => {
    const res = await fetchFromApi('GET', 'stock/csv', {
      responseType: 'blob',
    });
    console.log('CSV', res);
    if (res) {
      jsDownload(res, 'stock.csv');
    }
  };

  return {
    registerCurrency,
    getStock,
    deleteCurrency,
    getCurrency,
    updateCurrency,
    currency,
    stock,
    setStock,
    desativateCurrency,
    loading,
    stockForSearch,
    errors,
    setStockForSearch,
    downloadCsv,
    setLoading,
  };
};

export default useStock;
