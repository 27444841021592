import React from 'react';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';
import useStock from './useStock';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '../../components/Button/Button';
import {
  BoxMui,
  BoxTitle,
  BtnContainer,
  AddStockContainer,
  BreadcumsContainer,
} from './stockStyles';
import { useNavigate } from 'react-router-dom';
import themeMui from '../../theme';
import CancelButton from '../../components/Button/CancelButton';

const AddCurrency = () => {
  const { registerCurrency, errors } = useStock();
  const navigate = useNavigate();

  const [currencyState, setCurrencyState] = React.useState({
    currency: '',
    exchange_rate: '',
    initial_balance: 0,
  });

  const handleChange = (event) => {
    setCurrencyState({
      ...currencyState,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (currencyState) {
      registerCurrency(
        currencyState.currency,
        currencyState.exchange_rate,
        currencyState.initial_balance.replace(/,/g, ''),
      );
    } else {
      console.log('No se pudo agregar la divisa.');
    }
  };

  const themeMUI = createTheme();

  return (
    <>
      <ThemeProvider>
        <AddStockContainer>
          <BreadcumsContainer>
            <BreadcrumbsMui
              title="Agregar divisa"
              prev="Stock"
              path={'/stock'}
            />
          </BreadcumsContainer>
          <BoxTitle>
            <h1>Agregar divisa</h1>
          </BoxTitle>
          <BoxMui component="form" noValidate onSubmit={handleSubmit}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '100px',
                gap: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  gap: '10px',
                }}
              >
                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '50%',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  required
                  label="Divisa"
                  autoFocus
                  name="currency"
                  variant="standard"
                  onChange={handleChange}
                  error={errors?.currency}
                />

                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '50%',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  required
                  variant="standard"
                  label="Tipo de cambio"
                  name="exchange_rate"
                  onChange={handleChange}
                  type="number"
                  step="0.01"
                  error={errors?.exchange_rate}
                />
              </div>

              <TextField
                sx={{
                  input: {
                    color: `${themeMui.palette.inputText.main}`,
                  },
                  width: '50%',
                }}
                InputLabelProps={{
                  style: { color: `${themeMui.palette.inputText.main}` },
                }}
                label="Balance Inicial"
                variant="standard"
                name="initial_balance"
                value={currencyState.initial_balance}
                onChange={handleChange}
                error={errors?.initial_balance}
                type="number"
              />
            </div>

            <BtnContainer>
              <CancelButton
                title="CANCELAR"
                onClick={() => navigate('/stock')}
              />
              <Button type="submit" title="GUARDAR" />
            </BtnContainer>
          </BoxMui>
        </AddStockContainer>
      </ThemeProvider>
    </>
  );
};

export default AddCurrency;
