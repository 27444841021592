import { useState } from 'react';
import axios from 'axios';
import env from '../../env/index';
import { useNavigate } from 'react-router-dom';
import useNotistack from '../Notistack/useNotistack';

const useRecovery = () => {
  const [showModal, setShowModal] = useState(false);
  const [errorRecovery, setErrorRecovery] = useState('');
  const { showNotification } = useNotistack();
  const navigation = useNavigate();

  const changePassword = async (password, token) => {
    const handleRedirectToLogin = () => {
      showNotification('Redirigiendo a login...', 'success');
      setTimeout(() => {
        navigation('/login');
      }, 3000);
    };

    try {
      const passwordCleaned = password.trim();
      const response = await axios.put(`${env.API_URL}recovery/${token}`, {
        password: passwordCleaned,
      });

      if (response) {
        console.log(response);
        setShowModal(!showModal);
        handleRedirectToLogin();
      }
    } catch (error) {
      console.log(error.response.data.error);
      setErrorRecovery(error.response.data.error);
    }
  };

  const recoveryPassword = async (email) => {
    try {
      const emailCleaned = email.trim();
      const response = await axios.put(`${env.API_URL}recovery`, {
        email: emailCleaned,
      });

      if (response) {
        console.log(response);
        setShowModal(!showModal);
      }
    } catch (err) {
      console.log('err', err);
      setErrorRecovery(err.response.data.error);
    }
  };

  return {
    changePassword,
    recoveryPassword,
    showModal,
    setShowModal,
    errorRecovery,
  };
};

export default useRecovery;
