import React from 'react';
import { TableHead, TableRow } from '@mui/material';
import { ClientTableCell } from '../../containers/Clients/clientStyles';
import useClients from '../../containers/Clients/useClients';
import { useNavigate } from 'react-router-dom';

const ClientsHead = () => {
  return (
    <TableHead>
      <TableRow>
        <ClientTableCell align="left">NOMBRE</ClientTableCell>
        <ClientTableCell align="left">EMAIL</ClientTableCell>
        <ClientTableCell align="left">TELÉFONO</ClientTableCell>
        <ClientTableCell align="left">DIRECCIÓN</ClientTableCell>
        <ClientTableCell align="left">ID</ClientTableCell>
        <ClientTableCell align="left"></ClientTableCell>
      </TableRow>
    </TableHead>
  );
};

export default ClientsHead;
