import { useState } from 'react';
import fetchFromApi from '../../utils/fetchFromApi';
import useNotistack from '../Notistack/useNotistack';
import { useNavigate } from 'react-router-dom';

const useUsers = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [deliverys, setDeliverys] = useState([]);
  const [profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [userForSearch, setUserForSearch] = useState([]);
  const [panic, setPanic] = useState(false);
  const [usersCsv, setUsersCsv] = useState([]);
  const { showNotification } = useNotistack();

  const handleRedirect = () => {
    navigate('/equipo');
  };

  const getUsers = async () => {
    setLoading(true);
    try {
      const result = await fetchFromApi('GET', 'profile');
      setUsers(result);
      setUserForSearch(result);
      setLoading(false);
    } catch (error) {
      showNotification('Error al obtener los usuarios', 'error');
    }
  };

  const deleteUser = async (id) => {
    setLoading(true);
    try {
      const result = await fetchFromApi('PUT', `profile/disable/${id}`);
      if (result) {
        getUsers();
        showNotification(result[1], 'success');
      }
      setLoading(false);
      return users;
    } catch (error) {
      showNotification('Usuario no eliminado', 'error');
    }
  };

  const updateUser = async (userData, setErrors, id) => {
    setLoadingButton(true);
    try {
      const response = await fetchFromApi('PUT', `profile/${id}`, userData);
      showNotification(response[1], 'success');
      setErrors([]);
      handleRedirect();
    } catch (error) {
      setErrors(error.response.data);
      setLoadingButton(false);
    }
  };

  const updatePanic = async () => {
    try {
      const response = await fetchFromApi('PUT', 'profile/panic', {
        panic: !panic,
      });
      if (response) {
        console.log(response);
        setPanic(!panic);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const AddNewUser = async (userData, setErrors) => {
    setLoadingButton(true);
    try {
      const response = await fetchFromApi('POST', 'profile', userData);
      showNotification(response[1], 'success');
      setErrors([]);

      handleRedirect();
    } catch (error) {
      setErrors(error.response.data);
      setLoadingButton(false);
    }
  };

  const getProfile = async (id) => {
    try {
      const result = await fetchFromApi('GET', `profile/${id}`);

      setProfile(result);

      return result;
    } catch (error) {
      showNotification('Error al obtener el usuario', 'error');
    }
  };

  const getDeliverys = async () => {
    setLoading(true);
    try {
      const result = await fetchFromApi('GET', 'profile');
      setDeliverys(result.filter((u) => u.role === 'liquidador_delivery'));
      return users;
    } catch (error) {
      showNotification('Error al obtener los deliverys', 'error');
    }
    setLoading(false);
  };

  const getUsersForCsv = async () => {
    try {
      const response = await fetchFromApi('GET', 'profile/all/csv');
      if (response) {
        setUsersCsv(response);
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    getUsers,
    users,
    deleteUser,
    updateUser,
    getProfile,
    profile,
    loading,
    setUsers,
    userForSearch,
    AddNewUser,
    loadingButton,
    getDeliverys,
    deliverys,
    updatePanic,
    getUsersForCsv,
    usersCsv,
  };
};

export default useUsers;
