/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';
import Button from '../../components/Button/Button';
import CancelButton from '../../components/Button/CancelButton';

import { HomeContainer } from '../Layout/homeStyles';
import {
  AddOperationContainer,
  BoxMui,
  BoxTitle,
  BreadcumsContainer,
  BtnContainer,
  OperationFormContainer,
} from './operationsStyles';
import useOperations from './useOperations';
import useStock from '../Stock/useStock';
import useUsers from '../Team/useUsers';
import useClients from '../Clients/useClients';
import MainForm from './Forms/MainForm';

const EditOperation = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { getOperationById, operation, setOperation, updateOperation, errors } =
    useOperations();
  const { stock, getStock } = useStock();
  const { getDeliverys, deliverys } = useUsers();
  const { clients, getClients } = useClients();
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    getOperationById(id);
    getDeliverys();
    getStock();
    getClients();
  }, []);

  useEffect(() => {
    setSelectedClient(operation.client);
    setSelectedUser(operation.assignedDelivery);
  }, [operation.client, operation.assignedDelivery]);

  const handleInputChange = (e) => {
    setOperation({ ...operation, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateOperation(id, operation);
  };

  let inputAnomalie = operation?.anomalie?.filter((a) => a.type === 'input');
  let outputAnomalie = operation?.anomalie?.filter((a) => a.type === 'output');

  return (
    <HomeContainer>
      <AddOperationContainer>
        <BreadcumsContainer>
          <BreadcrumbsMui
            title="Editar operacion"
            prev="Operaciones"
            path={'/operaciones'}
          />
        </BreadcumsContainer>
        <BoxTitle>
          <h1>Editar operacion</h1>
        </BoxTitle>
        <BoxMui component="form" noValidate onSubmit={handleSubmit}>
          <OperationFormContainer>
            <MainForm
              errors={errors}
              handleInputChange={handleInputChange}
              setSelectedClient={setSelectedClient}
              setSelectedUser={setSelectedUser}
              setOperation={setOperation}
              usersDelivery={deliverys}
              operation={operation}
              stock={stock}
              clients={clients}
              selectedClient={selectedClient}
              selectedUser={selectedUser}
              inputAnomalie={inputAnomalie}
              outputAnomalie={outputAnomalie}
              context={'edit'}
            />
          </OperationFormContainer>
          <BtnContainer>
            <CancelButton
              title="CANCELAR"
              onClick={() => navigate('/operaciones')}
            />
            <Button type="submit" title="GUARDAR" />
          </BtnContainer>
        </BoxMui>
      </AddOperationContainer>
    </HomeContainer>
  );
};

export default EditOperation;
