import React, { useState, useEffect } from 'react';
import BreadcrumbsMui from '../../components/Breadcrumbs/Breadcrumbs';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '../../components/Button/Button';
import {
  BoxMui,
  BoxTitleAddClient,
  BtnContainer,
  AddStockContainer,
  FormContainer,
  SubFormContainer,
  BreadcumsContainer,
} from './clientStyles';
import useClients from './useClients';
import CancelButton from '../../components/Button/CancelButton';
import themeMui from '../../theme';
import useNotistack from '../Notistack/useNotistack';
import { validateClientForm } from './clientValidation';
import PlaceAutoComplete from './PlaceAutoComplete';

const EditClient = () => {
  const id = window.location.pathname.split('/')[2];

  const { getClientInfo, updateClient, clientInfo, loadingButtons } =
    useClients();
  const navigate = useNavigate();
  const theme = createTheme();
  const [errors, setErrors] = useState([]);
  const { showNotification } = useNotistack();

  const [clientEdited, setClientEdited] = useState({
    name: '',
    email: '',
    cellphone: '',
    address: '',
  });

  const handleShowErrors = (errors) => {
    errors[0]?.forEach((error) => {
      showNotification(error, 'error');
    });
  };

  const handleClientInfo = async () => {
    await getClientInfo(id);
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setClientEdited({ ...clientEdited, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = validateClientForm(clientEdited);
    if (response.valid) {
      updateClient(clientEdited, setErrors, id);
    } else {
      setErrors(response);
    }
  };

  useEffect(() => {
    handleClientInfo();
    setClientEdited({
      name: clientInfo?.name,
      cellphone: clientInfo?.cellphone,
      email: clientInfo?.email,
      address: clientInfo?.address,
    });
  }, [
    clientInfo?.name,
    clientInfo?.cellphone,
    clientInfo?.email,
    clientInfo?.address,
  ]);

  useEffect(() => {
    handleShowErrors(errors);
  }, [errors]);

  const setAddress = (value) => {
    setClientEdited({ ...clientEdited, address: value });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <AddStockContainer>
          <BreadcumsContainer>
            <BreadcrumbsMui
              title="Editar cliente"
              prev="Clientes"
              path={'/clientes'}
            />
          </BreadcumsContainer>

          <BoxTitleAddClient>
            <h1>Editar cliente</h1>
          </BoxTitleAddClient>
          <BoxMui
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              width: '80%',
              height: '450px',
            }}
          >
            <FormContainer>
              <SubFormContainer>
                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  label={clientEdited.name === '' ? 'Nombre' : false}
                  autoFocus
                  name="name"
                  variant="standard"
                  onChange={handleChange}
                  error={errors[1]?.name}
                  value={clientEdited.name}
                />

                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  label={clientEdited.cellphone === '' ? 'Teléfono' : false}
                  variant="standard"
                  name="cellphone"
                  value={clientEdited.cellphone}
                  onChange={handleChange}
                  error={errors[1]?.cellphone}
                  type="cellphone"
                />
              </SubFormContainer>
              <SubFormContainer>
                <TextField
                  sx={{
                    input: {
                      color: `${themeMui.palette.inputText.main}`,
                    },
                    width: '497px',
                  }}
                  InputLabelProps={{
                    style: { color: `${themeMui.palette.inputText.main}` },
                  }}
                  variant="standard"
                  label={clientEdited.email === '' ? 'Email' : false}
                  name="email"
                  onChange={handleChange}
                  type="email"
                  step="0.01"
                  value={clientEdited.email}
                  error={errors[1]?.email}
                />

                <PlaceAutoComplete
                  setValueToSend={setAddress}
                  prevAddress={clientInfo.address}
                />
              </SubFormContainer>
            </FormContainer>
            <BtnContainer>
              <CancelButton
                title="CANCELAR"
                onClick={() => navigate('/clientes')}
                disable={loadingButtons}
              />
              <Button type="submit" title="GUARDAR" disable={loadingButtons} />
            </BtnContainer>
          </BoxMui>
        </AddStockContainer>
      </ThemeProvider>
    </>
  );
};

export default EditClient;
