import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import AuthVerify from './AuthVerify.js';

import '../../App.css';

import Layout from '../Layout/index.jsx';
import theme from '../../theme';
import Login from '../Login/Login';
import { PrivateRoute } from './PrivateRoute.jsx';
import Users from '../Team/Users';
import AddUser from '../Team/AddUser';
import EditUser from '../Team/EditUser';
import Operations from '../Operations/Operations';
import Clients from '../Clients/Clients';
import AddClient from '../Clients/AddClient';
import AddOperation from '../Operations/AddOperation';
import AddCurrency from '../Stock/AddCurrency';
import Stock from '../Stock/Stock';
import EditCurrency from '../Stock/EditCurrency';
import Logistic from '../Logistic/Logistic';
import Anomalies from '../Anomalies/Anomalies';
import Recovery from '../Recovery/Recovery';
import SendEmail from '../Recovery/SendEmail';
import EditClient from '../Clients/EditClient';
import EditOperation from '../Operations/EditOperation';
import ClientDetail from '../Clients/ClientDetail';
import PrechargedOperations from '../Operations/PrechargedOperations.jsx';

function App() {
  const logOut = () => {
    localStorage.removeItem('user');
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider autoHideDuration={3000}>
        <BrowserRouter>
          <Routes>
            {/* Public Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/recovery/:token" element={<Recovery />} />
            <Route path="/unauthorized" element={<h1>Unauthorized</h1>} />
            <Route path="/send-email" element={<SendEmail />} />

            <Route element={<Layout />}>
              {/* Private Routes - Role Discrimination */}
              <Route path="/">
                {/* <Route path="/" element={<Navigate to="/stock" />} /> */}
              </Route>

              <Route element={<PrivateRoute />}>
                <Route path="/equipo" element={<Users />} />
                <Route path="/agregar-usuario" element={<AddUser />} />
                <Route path="/profile/:id" element={<EditUser />} />
                <Route path="/clientes" element={<Clients />} />
                <Route path="/clientes/:id" element={<ClientDetail />} />
                <Route path="/editar-cliente/:id" element={<EditClient />} />
                <Route path="/agregar-clientes" element={<AddClient />} />
                <Route path="/agregar-operacion" element={<AddOperation />} />
                <Route
                  path="/editar-operacion/:id"
                  element={<EditOperation />}
                />
                <Route path="/agregar-divisa" element={<AddCurrency />} />
                <Route path="/stock" element={<Stock />} />
                <Route path="/editar-divisa/:id" element={<EditCurrency />} />
              </Route>

              <Route element={<PrivateRoute />}>
                <Route path="/logistica" element={<Logistic />} />
                <Route path="/anomalias" element={<Anomalies />} />
              </Route>

              <Route element={<PrivateRoute />}>
                <Route path="/operaciones" element={<Operations />} />
                <Route
                  path="/operaciones-precarga"
                  element={<PrechargedOperations />}
                />
              </Route>
            </Route>
          </Routes>
          <AuthVerify logOut={logOut} />
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
