import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  StyledContainer,
  BackgroundImage,
  LoginTitle,
  LoginTitleText,
  LoginSubtitleText,
  ForgotPassword,
  Recover,
  ButtonsContainer,
  RightsReserved,
  ContainerLogoAndRights,
} from './loginStyles';
import ButtonWidth from '../../components/Button/ButtonWidth';
import axios from 'axios';

import useAuth from './useAuth';
import Logo from '../../components/Logo/Logo';

import env from '../../env';

const theme = createTheme();

export default function Login() {
  const [error, setError] = React.useState('');
  const navigate = useNavigate();

  const getCurrentYear = () => {
    const date = new Date();
    return date.getFullYear();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData(e.currentTarget);
    const email = form.get('email');
    const password = form.get('password');

    const emailCleaned = email.trim();
    const passwordCleaned = password.trim();

    try {
      const { data } = await axios.post(
        env.API_URL
          ? `${env.API_URL}auth/login/cms`
          : 'http://localhost:5000/api/v1/auth/login/cms',
        { email: emailCleaned, password: passwordCleaned },
      );
      console.log(data);
      setError('');
      localStorage.setItem('user', JSON.stringify(data));
      navigate(data.role === 'superadmin' ? '/stock' : '/');
      if (
        data.role === 'superadmin' ||
        data.role === 'operador_usdt' ||
        data.role === 'tesoreria' ||
        data.role === 'operador_pesos' ||
        data.role === 'operador_cable' ||
        data.role === 'operador_cambio'
      ) {
        navigate('/stock');
      } else if (data.role === 'operador_logistica') {
        navigate('/logistica');
      } else if (data.role === 'liquidador_caja') {
        navigate('/operaciones');
      } else {
        navigate('/');
      }
    } catch (error) {
      setError(error.response.data.error);
    }
  };

  const user = useAuth();

  return (
    <BackgroundImage>
      <ThemeProvider theme={theme}>
        <ContainerLogoAndRights>
          <Logo />
          <RightsReserved>
            © {getCurrentYear()} LYM, made by{' '}
            <a
              style={{
                color: '#fff',
                textDecoration: 'none',
              }}
              href="https://www.jrc.dev/"
              target={'_blank'}
            >
              JRC Studio
            </a>
          </RightsReserved>
        </ContainerLogoAndRights>
        <LoginTitle>
          <LoginTitleText>Iniciar sesión</LoginTitleText>
          <div
            style={{
              width: '63%',
            }}
          >
            <LoginSubtitleText
              style={{
                fontFamily: `${theme.typography.fontWeightRegular}`,
              }}
            >
              Ingrese su correo y contraseña para iniciar sesión
            </LoginSubtitleText>
          </div>
        </LoginTitle>
        <StyledContainer
          component="main"
          maxWidth="xs"
          sx={{ maxHeight: '60vh' }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 20 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                sx={{
                  '& label.Mui-focused': {
                    color: '#D43436',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#D2D6D9',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#D3D6DA',
                    },
                    '&:hover fieldset': {
                      borderColor: '#D43436',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#D43436',
                    },
                  },
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                sx={{
                  '& label.Mui-focused': {
                    color: '#D43436',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#D2D6D9',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#D3D6DA',
                    },
                    '&:hover fieldset': {
                      borderColor: '#D43436',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#D43436',
                    },
                  },
                }}
              />
              <Typography sx={{ color: 'red', fontWeight: '600' }}>
                {error?.length > 0 ? error : null}
              </Typography>
              <div
                style={{
                  width: '100%',
                  marginTop: '30px',
                }}
              >
                <ButtonWidth type="submit" title="INICIAR SESIÓN" />
              </div>
              <Grid
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ButtonsContainer>
                  <ForgotPassword
                    style={{
                      fontFamily: `${theme.typography.fontWeightRegular}`,
                    }}
                  >
                    ¿Olvidaste tu contraseña?
                  </ForgotPassword>
                  <Recover onClick={() => navigate('/send-email')}>
                    Recuperar
                  </Recover>
                </ButtonsContainer>
              </Grid>
            </Box>
          </Box>
        </StyledContainer>
      </ThemeProvider>
    </BackgroundImage>
  );
}
