import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import React, { useState } from 'react';

const DateRangePicker = ({
  filters,
  setFilters,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setFilters({
      ...filters,
      dateRange: [
        dates[0]?.toISOString().slice(0, 10),
        dates[1]?.toISOString().slice(0, 10),
      ],
    });
  };
  return (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      inline
      maxDate={new Date()}
    />
  );
};

export default DateRangePicker;
