/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HomeContainer } from '../Layout/homeStyles';
import OperationsTable from '../../components/Operation/OperationsTable';
import useOperations from './useOperations';
import useClients from '../Clients/useClients';
import useStock from '../Stock/useStock';
import {
  AllOperationsButton,
  OperationsButtonsContainer,
  OperationsContainer,
  OperationsSelectAndDeleteContainer,
  OperationsTitle,
} from './operationsStyles';
import Button from '../../components/Button/Button';
import { CSVLink } from 'react-csv';
import ButtonOutline from '../../components/Button/ButtonOutline';
import Searcher from '../../components/Searcher/Searcher';
import Loader from '../../components/Loader/Loader';
import SelectMUI from '../../components/Select/SelectMui';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterPopover from './FilterPopover';
import useNotistack from '../Notistack/useNotistack';
import { selectOperationsOptions } from '../../utils/constants';
import theme from '../../theme';
import ClearIcon from '@mui/icons-material/Clear';
import useUsers from '../Team/useUsers';
import useAuth from '../Login/useAuth';
import operationFinacialOrinstitutional from '../../utils/operationFinancialOrInstitutional';

const Operations = () => {
  const navigate = useNavigate();
  const user = useAuth();
  const { getProfile, profile } = useUsers();

  const {
    getOperations,
    deleteOperation,
    operations,
    numberOfPages,
    loading,
    changeOperationStatus,
    getOperationsForCSV,
    operationsCSV,
    getAllOperationsWithoutPaginate,
    allOperations,
  } = useOperations();
  const { getStock, stock } = useStock();
  const { clients, getClients } = useClients();
  const { showNotification } = useNotistack();

  const [filters, setFilters] = useState({
    status: [],
    operationType: [],
    liquidatorType: [],
    clientName: null,
    inputCurrency: 'Todas',
    outputCurrency: 'Todas',
    dateRange: null,
    changed: false,
  });
  const [postSave, setPostSave] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [selected, setSelected] = useState([]);
  const [checked, setChecked] = useState(false);
  const [filtersScopy, setFiltersCopy] = useState(filters);

  useEffect(() => {
    getOperations({}, page);
    getStock();
    getClients();
    getOperationsForCSV();
    getAllOperationsWithoutPaginate();
  }, []);

  useEffect(() => {
    getOperations(filters, page);
  }, [page]);

  const handleSelectOperation = (e) => {
    const value = JSON.parse(e.target.value);
    if (e.target.checked) {
      setSelected([...selected, value]);
    } else {
      const remove = selected.filter((o) => o.id !== value.id);
      setSelected(remove);
    }
  };

  const handleSelectAllOperations = async (e) => {
    if (e.target.name === 'all-operations') {
      const op = [];
      allOperations.map((o) => {
        op.push({ id: o._id, type: o.operation_type });
      });
      return setSelected(op);
    }

    if (e.target.checked) {
      setChecked(true);
      const op = [];
      operations.map((o) => {
        op.push({ id: o._id, type: o.operation_type });
      });
      return setSelected(op);
    } else {
      setChecked(false);
      setSelected([]);
    }
  };

  const handleChangeSelect = async (e) => {
    try {
      let noChange = false;
      selected.map(async (s) => {
        if (
          operationFinacialOrinstitutional(s.type) &&
          (e === 'Confirmado LYM' ||
            e === 'Confirmado Cliente' ||
            e === 'Pendiente')
        ) {
          noChange = true;
        } else {
          await changeOperationStatus(s.id, { statusValue: e }, 'multi');
        }
      });
      showNotification(
        '¡El estado de la operación se modificó con éxito! El stock ha sido actualizado.',
        'success',
      );
      if (noChange) {
        showNotification(
          `Algunas operaciones no han cambiado su estado ya que no son compatibles con el estado ${e}`,
          'error',
        );
      }
      setChecked(false);
    } catch (error) {
      showNotification(
        'Ha ocurrido un error al cambiar el estado de las operaciones.',
        'error',
      );
    }
    setSelected([]);
    getOperations({}, page);
  };

  const handleMultipleDelete = async () => {
    try {
      selected.map(async (s) => await deleteOperation(s.id, 'multi'));
      showNotification(
        'Las operaciones se han eliminado con éxito.',
        'success',
      );
    } catch (error) {
      showNotification(
        'Ha ocurrido un error al eliminar las operaciones.',
        'error',
      );
    }
    setSelected([]);
    getOperations({}, page);
  };

  useEffect(() => {
    if (user) {
      getProfile(user?.id);
    }
  }, []);

  return (
    <HomeContainer style={{ paddingLeft: '240px' }}>
      {loading ? (
        <Loader style={{ marginTop: '250px' }} />
      ) : (
        <OperationsContainer>
          <OperationsTitle>Operaciones</OperationsTitle>
          {selected.length === 0 ? (
            <OperationsButtonsContainer>
              {profile?.role !== 'liquidador_caja' && (
                <>
                  <div style={{ display: 'flex' }}>
                    <Button
                      onClick={() => {
                        navigate('/agregar-operacion');
                      }}
                      title="CARGAR OPERACION"
                    />
                    <Button
                      onClick={() => {
                        navigate('/operaciones-precarga');
                      }}
                      title="SOLICITUD COTIZACIONES"
                    />
                  </div>
                </>
              )}
              <CSVLink
                separator={';'}
                data={operationsCSV}
                filename="operations.csv"
                target="_blank"
                style={{ textDecoration: 'none' }}
              >
                <ButtonOutline icon="csv" title="EXPORTAR CSV" />
              </CSVLink>
            </OperationsButtonsContainer>
          ) : (
            <OperationsSelectAndDeleteContainer>
              <div
                style={{
                  backgroundColor: '#f7f7f5',
                  borderRadius: '50px',
                  padding: '12px 13px 6px 13px',
                  cursor: 'pointer',
                }}
                onClick={handleMultipleDelete}
              >
                <DeleteIcon
                  sx={{
                    color: `${theme.palette.icons.main}`,
                    ':hover': { cursor: 'pointer' },
                  }}
                />
              </div>
              <SelectMUI
                options={selectOperationsOptions}
                onChangeFunction={(e, newValue) => {
                  handleChangeSelect(newValue);
                }}
                context="operation"
              />
              <span>{selected.length} operaciones seleccionadas</span>
              <ClearIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelected([]);
                  setChecked(false);
                }}
              />
            </OperationsSelectAndDeleteContainer>
          )}

          <OperationsTable
            deleteOperation={deleteOperation}
            setPage={setPage}
            operations={operations}
            page={page}
            numberOfPages={numberOfPages}
            handleSelectOperation={handleSelectOperation}
            handleSelectAllOperations={handleSelectAllOperations}
            selected={selected}
            loading={loading}
            checked={checked}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <AllOperationsButton
                style={{
                  visibility: `${selected.length === 0 ? 'hidden' : 'visible'}`,
                }}
                name="all-operations"
                onClick={handleSelectAllOperations}
              >
                Seleccionar las operaciones de todas las paginas
              </AllOperationsButton>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  justifyItems: 'center',
                  gap: '15px',
                  margin: '25px 0px',
                }}
              >
                <FilterPopover
                  setFilters={setFilters}
                  setAnchorEl={setAnchorEl}
                  setPage={setPage}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  getOperations={getOperations}
                  filters={filters}
                  anchorEl={anchorEl}
                  stock={stock}
                  clients={clients}
                  startDate={startDate}
                  endDate={endDate}
                  page={page}
                  filtersScopy={filtersScopy}
                  postSave={postSave}
                  setPostSave={setPostSave}
                />
                <Searcher
                  context={'operations'}
                  setFilters={setFilters}
                  getOperations={getOperations}
                  setPage={setPage}
                  page={page}
                  filters={filters}
                />
              </div>
            </div>
          </OperationsTable>
        </OperationsContainer>
      )}
    </HomeContainer>
  );
};

export default Operations;
