import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import useAuth from '../Login/useAuth.js';
import useUsers from '../Team/useUsers';

const PrivateRoute = () => {
  const { id } = useParams();
  const { profile, getProfile } = useUsers();

  const newCanAccess = {
    superadmin: [
      '/stock',
      '/operaciones',
      '/operaciones-precarga',
      '/logistica',
      '/anomalias',
      '/clientes',
      `/clientes/${id}`,
      '/equipo',
      '/agregar-usuario',
      `/editar-usuario/`,
      '/agregar-divisa',
      `/profile/${id}`,
      `/editar-cliente/${id}`,
      '/agregar-clientes',
      '/agregar-operacion',
      `/editar-operacion/${id}`,
      `/editar-divisa/${id}`,
    ],
    operador_usdt: [],
    operador_pesos: [],
    operador_cable: [],
    operador_cambio: [],
    operador_logistica: [],
    liquidador_caja: [],
    tesoreria: [],
  };

  const canAccess = {
    superadmin: [
      '/stock',
      '/operaciones',
      '/operaciones-precarga',
      '/logistica',
      '/anomalias',
      '/clientes',
      `/clientes/${id}`,
      '/equipo',
      '/agregar-usuario',
      `/editar-usuario/`,
      '/agregar-divisa',
      `/profile/${id}`,
      `/editar-cliente/${id}`,
      '/agregar-clientes',
      '/agregar-operacion',
      `/editar-operacion/${id}`,
      `/editar-divisa/${id}`,
    ],
    operador_usdt: [
      '/stock',
      '/clientes',
      `/clientes/${id}`,
      '/operaciones',
      '/agregar-operacion',
    ],
    operador_pesos: [
      '/clientes',
      `/clientes/${id}`,
      '/operaciones',
      '/agregar-operacion',
      '/stock',
    ],
    operador_cable: [
      '/clientes',
      `/clientes/${id}`,
      '/stock',
      '/operaciones',
      '/agregar-operacion',
    ],
    operador_cambio: [
      '/clientes',
      `/clientes/${id}`,
      '/stock',
      `/editar-divisa/${id}`,
      '/operaciones',
      '/agregar-operacion',
    ],
    operador_logistica: ['/logistica', '/operaciones', '/agregar-operacion'],
    liquidador_caja: ['/operaciones', `/editar-operacion/${id}`],
    tesoreria: [
      '/operaciones',
      '/anomalias',
      '/stock',
      '/clientes',
      `/clientes/${id}`,
    ],
  };
  const user = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      getProfile(user.id);
    }
  }, []);

  if (!user) {
    return <Navigate to="/login" replace />;
  }
  if (
    profile.panic
      ? newCanAccess[user.role].some((route) => route === location.pathname)
      : canAccess[user.role].some((route) => route === location.pathname)
  ) {
    return <Outlet />;
  } else {
    return <Navigate to="/unauthorized" replace />;
  }
};

export { PrivateRoute };
