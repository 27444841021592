import React from 'react';
import { CancelBtn } from './buttonStyles';

const CancelButton = ({ onClick, title, disable }) => {
  const style = disable ? { background: '#c3c9d1', boxShadow: 'none' } : null;
  return (
    <div>
      <CancelBtn style={style} onClick={onClick} disabled={disable}>
        {title}
      </CancelBtn>
    </div>
  );
};

export default CancelButton;
