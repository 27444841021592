import Container from '@mui/material/Container';
import styled from 'styled-components';
import theme from '../../theme';
import Fondo from '../../img/fondo.png';

export const StyledContainer = styled(Container)`
  background-color: #ffffff;
  height: ${(props) => (props.height ? props.height : '70vh')};
  border-radius: 10px;
  box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 2px 4px 12px -2px rgba(0, 0, 0, 0.26);
  position: relative;
  bottom: 100px;
  z-index: 0;
`;

export const BackgroundImage = styled.div`
  background-image: url(${Fondo});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
`;

export const LoginTitle = styled.div`
  background: #d43436; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f35658,
    #d43436
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  width: 23%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: white;
  height: 150px;
  margin: 0 auto;
  position: relative;
  padding: 10px;
  flex-direction: column;
  z-index: 1;
`;

export const LoginTitleText = styled.h1`
  font-size: 20px;
`;

export const LoginSubtitleText = styled.h2`
  font-size: 14px;
  text-align: center;
  color: #d3d6da;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 300;
`;

export const ForgotPassword = styled.h1`
  font-size: 14px;
  color: #7c8098;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 300;
`;

export const Recover = styled.h1`
  color: #dc281e;
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const RightsReserved = styled.h1`
  font-size: 14px;
  color: #ffff;
  position: absolute;
  bottom: 0;
  margin-bottom: 30px;
`;

export const ContainerLogoAndRights = styled.div`
  padding-left: 150px;
`;
