import { TableCell, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import theme from '../../theme';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import formatNumber from '../../utils/formatNumber';
import AlertDialog from '../Dialog/AlertDialog';

const Stock = ({
  id,
  currency,
  exchange_rate,
  initial_balance,
  actual_balance,
  checkToday,
  getTotalAnomalieValue,
  desativateCurrency,
  stock,
}) => {
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = React.useState(false);

  return (
    <TableRow key={id}>
      <TableCell
        sx={{
          color: `${theme.palette.tableText.main}`,
          paddingLeft: '25px',
          fontWeight: '500',
          width: '250px',
        }}
      >
        {formatNumber(currency)}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          color: `${theme.palette.tableText.main}`,
          fontWeight: '500',
          width: '250px',
        }}
      >
        ${formatNumber(exchange_rate)}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          color: `${theme.palette.tableText.main}`,
          fontWeight: '500',
          width: '250px',
        }}
      >
        ${formatNumber(initial_balance)}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          color: `${theme.palette.tableText.main}`,
          fontWeight: '500',
          width: '250px',
        }}
      >
        ${formatNumber(actual_balance)}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          color: `${theme.palette.tableText.main}`,
          fontWeight: '500',
          width: '250px',
        }}
      >
        ${formatNumber(getTotalAnomalieValue(stock))}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          color: `${theme.palette.tableText.main}`,
          fontWeight: '500',
          width: '250px',
        }}
      >
        ${formatNumber(actual_balance + getTotalAnomalieValue(stock))}
      </TableCell>
      {checkToday() ? (
        <TableCell align="center">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginRight: '50px',
              gap: '25px',
            }}
          >
            <EditIcon
              sx={{
                color: `${theme.palette.icons.main}`,
                ':hover': { cursor: 'pointer' },
              }}
              onClick={() => navigate(`/editar-divisa/${id}`)}
            >
              Editar
            </EditIcon>

            <AlertDialog
              onClose={() => setOpenAlert(false)}
              open={openAlert}
              handlerAction={() => desativateCurrency(id)}
              context="stock"
            />

            <DeleteIcon
              sx={{
                color: `${theme.palette.icons.main}`,
                ':hover': { cursor: 'pointer' },
              }}
              onClick={() => setOpenAlert(true)}
            >
              Eliminar
            </DeleteIcon>
          </div>
        </TableCell>
      ) : (
        <TableCell align="center">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <EditIcon
              sx={{
                display: 'none',
                color: `${theme.palette.icons.main}`,
                ':hover': { cursor: 'pointer' },
              }}
              onClick={() => navigate(`/editar-divisa/${id}`)}
            >
              Editar
            </EditIcon>

            <DeleteIcon
              sx={{
                display: 'none',
                color: `${theme.palette.icons.main}`,
                ':hover': { cursor: 'pointer' },
              }}
              onClick={() => desativateCurrency(id)}
            >
              Eliminar
            </DeleteIcon>
          </div>
        </TableCell>
      )}
    </TableRow>
  );
};

export default Stock;
