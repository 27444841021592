import { useEffect, useState } from 'react';
import useNotistack from '../../Notistack/useNotistack';
import useUsers from '../../Team/useUsers';
import useAuth from '../../Login/useAuth';

const usePanic = () => {
  const [panic, setPanic] = useState(false);
  const { showNotification } = useNotistack();
  const { updatePanic, getProfile } = useUsers();
  const user = useAuth();

  useEffect(() => {
    if (user) {
      getProfile(user.id);
    }
  }, [panic]);

  const handlePanic = () => {
    try {
      setPanic(!panic);
      localStorage.setItem('panic', !panic);
      updatePanic();
      if (panic) {
        showNotification('Modo de pánico desactivado', 'warning');
      } else {
        showNotification('Modo de pánico activado', 'warning');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    panic,
    handlePanic,
  };
};

export default usePanic;
