import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Status = ({ checkboxStyle, filters, setFilters }) => {
  const [checked, setChecked] = useState({
    Pendiente: filters.status.includes('Pendiente'),
    'Confirmado LYM': filters.status.includes('Confirmado LYM'),
    'Confirmado Cliente': filters.status.includes('Confirmado Cliente'),
    'Confirmado Delivery': filters.status.includes('Confirmado Delivery'),
    Liquidado: filters.status.includes('Liquidado'),
    Finalizado: filters.status.includes('Finalizado'),
  });

  useEffect(() => {
    setChecked({
      Pendiente: filters.status.includes('Pendiente'),
      'Confirmado LYM': filters.status.includes('Confirmado LYM'),
      'Confirmado Cliente': filters.status.includes('Confirmado Cliente'),
      'Confirmado Delivery': filters.status.includes('Confirmado Delivery'),
      Liquidado: filters.status.includes('Liquidado'),
      Finalizado: filters.status.includes('Finalizado'),
    });
  }, [filters.status.length]);

  const handleChange = (e) => {
    if (e.target.checked) {
      setFilters({ ...filters }, filters.status.push(e.target.value));
      setChecked({ ...checked, [e.target.value]: true });
    } else {
      setChecked({ ...checked, [e.target.value]: false });

      const newFilter = filters;
      const removeFilter = filters.status.filter((s) => s !== e.target.value);

      newFilter.status = removeFilter;
      setFilters(newFilter);
    }
  };

  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="Pendiente"
          value="Pendiente"
          onChange={handleChange}
          checked={checked.Pendiente}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="Confirmado LYM"
          value="Confirmado LYM"
          onChange={handleChange}
          checked={checked['Confirmado LYM']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="Confirmado Cliente"
          value="Confirmado Cliente"
          onChange={handleChange}
          checked={checked['Confirmado Cliente']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="Confirmado Delivery"
          value="Confirmado Delivery"
          onChange={handleChange}
          checked={checked['Confirmado Delivery']}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="Liquidado"
          value="Liquidado"
          onChange={handleChange}
          checked={checked.Liquidado}
        />
        <FormControlLabel
          control={<Checkbox sx={checkboxStyle} />}
          label="Finalizado"
          value="Finalizado"
          onChange={handleChange}
          checked={checked.Finalizado}
        />
      </FormGroup>
    </div>
  );
};

export default Status;
